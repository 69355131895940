import { ListViewBody } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  displayName: String;
};

export const PayeeName: FC<Props> = memo(({ displayName }) => {
  return <ListViewBody key='right'>{displayName}</ListViewBody>;
});
PayeeName.displayName = 'PayeeName';
