import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

type InactiveAlertProps = {
  isActive: boolean;
};

export const InactiveAlert: FC<InactiveAlertProps> = memo(({ isActive }) => {
  const { t } = useTranslation();
  if (isActive) {
    return null;
  }

  return (
    <div className={cx(styles['inactive-alert'])}>
      {t('ap_payee_web_receive_register_office_name_is_not_active')}
    </div>
  );
});
InactiveAlert.displayName = 'InactiveAlert';

export type Props = {
  tenantName: string | undefined;
  isActive: boolean;
};

export const TenantName: FC<Props> = memo(({ tenantName, isActive }) => {
  if (!tenantName) {
    return null;
  }

  return (
    <ListViewBody key='right'>
      <div>{tenantName}</div>
      <InactiveAlert isActive={isActive} />
    </ListViewBody>
  );
});
TenantName.displayName = 'TenantName';
