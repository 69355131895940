import { FC, PropsWithChildren, createContext, memo, useContext } from 'react';

const context = createContext<string>('/api/v3/graphql');

type GraphQLProviderProps = {
  url: string;
};

export const GraphQLProvider: FC<PropsWithChildren<GraphQLProviderProps>> =
  memo(({ children, url }) => {
    return <context.Provider value={url}>{children}</context.Provider>;
  });
GraphQLProvider.displayName = 'GraphQLProvider';

export const useGraphQLUrl = () => useContext(context);
