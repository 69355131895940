import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

faker.seed(1);

export const getGetGeneralReportsMock = () => {
  return {
    next: faker.helpers.arrayElement([
      faker.helpers.arrayElement([faker.word.sample(), null]),
      undefined,
    ]),
    prev: faker.helpers.arrayElement([
      faker.helpers.arrayElement([faker.word.sample(), null]),
      undefined,
    ]),
    general_reports: Array.from({ length: 50 }, () => {
      return {
        number: 'AP-' + faker.string.uuid(),
        title: 'AP-' + faker.string.uuid(),
      };
    }),
  };
};

export const handlers = [
  http.get('*/api/js/general_reports', async ({ request }) => {
    await delay(1000);
    const url = new URL(request.url);
    const status = url.searchParams.get('status');
    if (status !== 'approved') {
      return new HttpResponse(
        JSON.stringify({
          code: 'not_found',
          messages: ['not found'],
        }),
        {
          status: 400,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }

    return new HttpResponse(JSON.stringify(getGetGeneralReportsMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
