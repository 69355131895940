import { AddNewPayeeDialog } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { useTranslation } from '@/i18n';
import { ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';

export const ApPayees: FC = memo(({}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ButtonV2 onClick={() => setOpen(true)}>
        {t('ap_payee_add_new_button')}
      </ButtonV2>
      <AddNewPayeeDialog
        title={t('payee_add_new_dialog_title')}
        open={open}
        onClose={onClose}
      />
    </>
  );
});

ApPayees.displayName = 'ApPayees';
