import EmptyPdf from '@/assets/empty.pdf?base64';
import { SelectedInvoice } from '@/components/RelationActionMenu/Invoice/type';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { PdfViewer } from '@/components/pdfViewer';
import { useGetMfFileAndObjectURL } from '@service/mf_file/MfFile.service';
import classnames from 'classnames/bind';
import { useCreateObjectURLWithMemo, useToBlob } from 'packages-hooks';
import { FC, memo, useEffect } from 'react';
import styles from './Dialog.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  invoiceId: string;
  mfFileId: string;
  fileName: string;
  setSelectedFile: (value: File | SelectedInvoice | null) => void;
};

export const MainPreview: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary>
      <InnerMainPreview {...props} />
    </SuspenseErrorBoundary>
  );
});
MainPreview.displayName = 'MainPreview';

export const InnerMainPreview: FC<Props> = memo(
  ({ invoiceId, mfFileId, fileName, setSelectedFile }) => {
    const { originalBlob, url } = useGetMfFileAndObjectURL(mfFileId);
    useEffect(() => {
      const file = new File([originalBlob], fileName ?? 'unknown', {
        lastModified: Date.now(),
        type: originalBlob.type,
      });
      setSelectedFile({
        file: file,
        mfFileId: mfFileId,
        invoiceId: invoiceId,
      });
    }, [fileName, invoiceId, mfFileId, originalBlob, setSelectedFile]);
    return <PdfViewer className={cx(styles['previewContainer'])} src={url} />;
  }
);
InnerMainPreview.displayName = 'MainPreview';

export const EmptyPreview: FC = memo(() => {
  const originalBlob = useToBlob(EmptyPdf);
  const url = useCreateObjectURLWithMemo(originalBlob);
  return <PdfViewer className={cx(styles['previewContainer'])} src={url} />;
});
EmptyPreview.displayName = 'EmptyPreview';
