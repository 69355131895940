import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { PayeeInformation } from '@/features/ap_payees/components/Contents/PayeeInformation';
import { PaymentRule } from '@/features/ap_payees/components/Contents/PaymentRule';
import { RelateCounterparty } from '@/features/ap_payees/components/Contents/RelateCounterparty';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';

export type Props = {};
export type TApPayeeContentProps = {
  control: Control<ApPayeeForm>;
  onCounterpartyModalOpen: () => void;
  selectedOptions: { label: string; value: string }[];
  counterpartyOpen: boolean;
};

export const AddNewPayeeContent: FC<TApPayeeContentProps> = memo(
  ({ control, onCounterpartyModalOpen, selectedOptions, counterpartyOpen }) => {
    return (
      <>
        <RelateCounterparty
          control={control}
          onCounterpartyModalOpen={onCounterpartyModalOpen}
          defaultOptions={selectedOptions}
          counterpartyOpen={counterpartyOpen}
        />
        <PayeeInformation control={control} />
        <PaymentRule control={control} />
      </>
    );
  }
);
AddNewPayeeContent.displayName = 'AddNewPayeeContent';
