import { useLoginInfo } from '@/components/LoginInfo/provider';
import { useMemo } from 'react';

export const useFFContext = (
  targetingKey?: string,
  tenant?: boolean,
  tenant_user?: boolean
) => {
  const loginUserInfo = useLoginInfo();
  const defaultKey = useMemo(() => {
    if (tenant) {
      return loginUserInfo.currentOffice?.id;
    }
    if (tenant_user) {
      return `${loginUserInfo.currentOffice?.id}-${loginUserInfo.currentUser?.id}`;
    }
    return undefined;
  }, [
    loginUserInfo.currentOffice?.id,
    loginUserInfo.currentUser?.id,
    tenant,
    tenant_user,
  ]);
  return useMemo(
    () => ({
      targetingKey:
        targetingKey ||
        defaultKey ||
        `${loginUserInfo.currentOffice?.id}-${loginUserInfo.currentUser?.id}`,
      office_created_at: parseInt(
        loginUserInfo.currentOffice?.createdAt ?? '0'
      ),
      office_identification_code:
        loginUserInfo.currentOffice?.identificationCode ?? '',
      office_member_identification_code:
        loginUserInfo.currentOfficeMember?.identificationCode ?? '',
      app_env: process.env.APP_ENV ?? 'local',
    }),
    [
      defaultKey,
      loginUserInfo.currentOffice?.createdAt,
      loginUserInfo.currentOffice?.id,
      loginUserInfo.currentOffice?.identificationCode,
      loginUserInfo.currentOfficeMember?.identificationCode,
      loginUserInfo.currentUser?.id,
      targetingKey,
    ]
  );
};
