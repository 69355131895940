import { useExistBusinessDocument } from '@/context/services/business_document/BusinessDocument.service';
import { BusinessDocumentColumnWithMfFile } from '@/features/business_documents/List/type';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  FormFields,
  FormFieldsProps,
  IconApproval,
  Input,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import styles from './InputStyle.module.scss';

const cx = classnames.bind(styles);

type Status = 'none' | 'success' | 'alert' | 'error';

type Props = {
  onInputSearchClick: (id: BusinessDocumentColumnWithMfFile) => void;
};

export const InputBusinessDocumentSearch: FC<Props> = memo(
  ({ onInputSearchClick }) => {
    const { t } = useTranslation();
    const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        e.stopPropagation();
      },
      []
    );
    const [caption, setCaption] =
      useState<FormFieldsProps['captions']>(undefined);
    const [status, setStatus] = useState<Status>('none');
    const [searchableId, setSearchableId] = useState('');

    const isSuccess = useMemo(() => status === 'success', [status]);
    const fetchResult = useExistBusinessDocument(searchableId);
    const _onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setStatus('none');
        setCaption(undefined);
        setSearchableId(e.target.value);
      },
      []
    );
    const onInputChange = useDebouncedCallback(_onInputChange, 600);
    const [selectedId, setSelectedId] = useState<
      BusinessDocumentColumnWithMfFile | undefined
    >(undefined);

    const onClick = useCallback(() => {
      if (selectedId) onInputSearchClick(selectedId);
    }, [onInputSearchClick, selectedId]);

    useEffect(() => {
      if (fetchResult) {
        const { data, error } = fetchResult;
        if (data) {
          setCaption(undefined);
          setStatus('success');
          setSelectedId(data);
          return;
        } else {
          setCaption({
            error: {
              children: error,
            },
          });
          setStatus('error');
        }
      }
      setSelectedId(undefined);
    }, [fetchResult]);

    return (
      <div className={cx(styles['container'])}>
        <FormFields label={undefined} captions={caption}>
          <div className={cx(styles['inputContainer'])}>
            {isSuccess && (
              <div className={cx(styles['icon'])}>
                <IconApproval size={20} />
              </div>
            )}
            <Input
              status={status === 'error' ? 'error' : undefined}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
            />
          </div>
        </FormFields>
        <ButtonV2
          type='button'
          size='xs'
          isTransparent
          className={cx(styles['button-container'])}
          onClick={onClick}
          isDisabled={!selectedId}
        >
          {t('relation_action.input_invoice')}
        </ButtonV2>
      </div>
    );
  }
);

InputBusinessDocumentSearch.displayName = 'InputBusinessDocumentSearch';
