import { FeatureFlagLayer as CompFeatureFlagLayer } from '@/components/OpenFeature';
import { r2wc } from '@/wc/helper/render';

export const FeatureFlagLayer = r2wc(CompFeatureFlagLayer, {
  shadow: 'open',
  props: {
    baseUrl: 'string',
    authorization: 'string',
    targetingKey: 'string',
    pollingInterval: 'number',
    flagName: 'string',
    defaultFlagValue: 'boolean',
    container: 'string',
    tenant: 'boolean',
    tenantUser: 'boolean',
    domain: 'string',
  },
});
