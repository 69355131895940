import { BusinessPersonDataProvider } from '@/context/BusinessPersonData';
import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { CompanyType } from '@/features/ap_counterparties/components/Forms/CompanyType';
import { CounterpartyCode } from '@/features/ap_counterparties/components/Forms/CounterpartyCode';
import { CounterpartyIndividualNumber } from '@/features/ap_counterparties/components/Forms/CounterpartyIndividualNumber';
import { CounterpartyName } from '@/features/ap_counterparties/components/Forms/CounterpartyName';
import { CounterpartyNameKana } from '@/features/ap_counterparties/components/Forms/CounterpartyNameKana';
import { InvoiceRegistrationNumber } from '@/features/ap_counterparties/components/Forms/InvoiceRegistrationNumber';
import { useTranslation } from '@/i18n';
import { Bottom, ListView, Top } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';
import { Control } from 'react-hook-form';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  control: Control<CounterpartyForm>;
  message?: ReactNode;
};

export const BaseInformation: FC<Props> = memo(({ control, message }) => {
  const { t } = useTranslation();

  return (
    <>
      {message}
      <div className={cx(styles['tab-container-title'])}>
        {t('counterparty_baseinfo_container_title')}
      </div>
      <ListView testId='baseinfo'>
        <Top>
          <CounterpartyCode control={control} />
        </Top>
        <Top>
          <CounterpartyName control={control} />
        </Top>
        <Top>
          <CounterpartyNameKana control={control} />
        </Top>
        <BusinessPersonDataProvider>
          <Top>
            <InvoiceRegistrationNumber control={control} />
          </Top>
          <Top>
            <CompanyType control={control} />
          </Top>
        </BusinessPersonDataProvider>
        <Bottom>
          <CounterpartyIndividualNumber control={control} />
        </Bottom>
      </ListView>
    </>
  );
});
BaseInformation.displayName = 'BaseInformation';
