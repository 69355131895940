import { RegistrationBusinessPersonDataPopoverProps } from '@moneyforward/ap-frontend-components';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

type BusinessPersonDataContextType = {
  businessPersonData: RegistrationBusinessPersonDataPopoverProps;
  setBusinessPersonData: React.Dispatch<
    React.SetStateAction<RegistrationBusinessPersonDataPopoverProps>
  >;
};

const defaultContextValue: BusinessPersonDataContextType = {
  businessPersonData: { disabled: true },
  setBusinessPersonData: () => {},
};

const BusinessPersonDataContext =
  createContext<BusinessPersonDataContextType>(defaultContextValue);

export const BusinessPersonDataProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [businessPersonData, setBusinessPersonData] =
    useState<RegistrationBusinessPersonDataPopoverProps>({ disabled: true });

  const BusinessPersonDataContextValue = useMemo(
    () => ({
      businessPersonData,
      setBusinessPersonData,
    }),
    [businessPersonData, setBusinessPersonData]
  );

  return (
    <BusinessPersonDataContext.Provider value={BusinessPersonDataContextValue}>
      {children}
    </BusinessPersonDataContext.Provider>
  );
};

export const useBusinessPersonData = (): BusinessPersonDataContextType => {
  const context = useContext(BusinessPersonDataContext);
  if (context === undefined) {
    throw new Error(
      'useBusinessPersonData must be used within a BusinessPersonDataProvider'
    );
  }
  return context;
};
