import { PayeeDetail } from '@/context/services/ap_payee/type';
import { AgentReceive } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/AgentReceive';
import { MailReceive } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/MailReceive';
import { WebReceive } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import { Heading } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  apPayee: PayeeDetail;
};

export const ReceiveInvoice: FC<Props> = memo(({ apPayee }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles['container'])}>
      <Heading as='h2' className={cx(styles['heading-title'])}>
        {t('ap_payee_receive_method')}
      </Heading>
      <MailReceive apPayee={apPayee} />
      <WebReceive
        supplierUploadSetting={apPayee.supplierUploadSetting}
        isActive={apPayee.isActive}
      />
      <AgentReceive agentReceiveSetting={apPayee.agentReceiveSetting} />
    </div>
  );
});
ReceiveInvoice.displayName = 'ReceiveInvoice';
