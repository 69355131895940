import { PayeeName } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo/components/Payee/PayeeName';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import {
  Heading,
  ListView,
  ListViewLabel,
  Single,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  displayName: string;
};

export const Payee: FC<Props> = memo(({ displayName }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Heading as='h3' className={cx(styles['section-title'])}>
        {t('ap_payee_info')}
      </Heading>
      <ListView>
        <Single>
          <ListViewLabel key='left'>
            {t('ap_payee_name_and_code')}
          </ListViewLabel>
          <PayeeName displayName={displayName} />
        </Single>
      </ListView>
    </section>
  );
});
Payee.displayName = 'Payee';
