import { removeHiddenElement } from '@/components/OpenFeature/Layer/utils';
import { useFFContext } from '@/components/OpenFeature/hooks/useFFContext';
import { usePollingInterval } from '@/components/OpenFeature/hooks/usePollingInterval';
import { ErrorBoundary } from '@/components/Rollbar';
import { OpenFeatureProvider } from '@moneyforward/user-flex-feature-hooks';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { FC, memo, useEffect } from 'react';
import { Wrapper } from '../Wrapper';
import {
  FeatureFlagProps,
  FeatureFlagProviderProps,
  WrapperProps,
} from '../types';

type InnerProps = FeatureFlagProps & {
  container: HTMLElement;
};

type InnerLayerProps = {
  flag: boolean;
  container: HTMLElement;
};
const InnerLayer: FC<InnerLayerProps> = memo(({ flag, container }) => {
  useEffect(() => {
    const slotChange = () => {
      const slot = container.querySelector('slot');
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const ele = slot?.assignedElements()?.[0] as HTMLElement | undefined;
      removeHiddenElement(ele);
    };
    container.addEventListener('slotchange', slotChange);
    return () => {
      container.removeEventListener('slotchange', slotChange);
    };
  }, [container]);
  return flag ? <slot name='on' /> : <slot name='off' />;
});
InnerLayer.displayName = 'InnerLayer';

const InnerFeatureFlagLayer: FC<InnerProps> = memo(
  ({ flagName, defaultFlagValue, container }) => {
    const flag = useBooleanFlagValue(flagName, defaultFlagValue);
    return <InnerLayer flag={flag} container={container} />;
  }
);
InnerFeatureFlagLayer.displayName = 'InnerFeatureFlagLayer';

export type Props = FeatureFlagProviderProps &
  InnerProps &
  Omit<WrapperProps, 'isProto'>;

export const FeatureFlagLayer: FC<Props> = memo(
  ({
    baseUrl: baseURL,
    authorization,
    targetingKey,
    tenant,
    tenantUser,
    pollingInterval = 5,
    domain = 'ap-frontend-layer',
    ...rest
  }) => {
    const context = useFFContext(targetingKey, tenant, tenantUser);
    const internal = usePollingInterval(pollingInterval);
    return (
      <ErrorBoundary
        fallback={() => (
          <InnerLayer flag={rest.defaultFlagValue} container={rest.container} />
        )}
      >
        <Wrapper
          baseUrl={baseURL}
          authorization={authorization}
          isProto={false}
        >
          <OpenFeatureProvider
            pollInterval={internal}
            context={context}
            domain={domain}
          >
            <InnerFeatureFlagLayer
              {...rest}
              targetingKey={context.targetingKey}
            />
          </OpenFeatureProvider>
        </Wrapper>
      </ErrorBoundary>
    );
  }
);
FeatureFlagLayer.displayName = 'FeatureFlagLayer';
