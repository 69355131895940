import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC } from 'react';

type Props = {
  onCancel: VoidFunction;
  onSelected: VoidFunction;
  disabled: boolean;
};

export const Footer: FC<Props> = ({ onCancel, onSelected, disabled }) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup>
      <ButtonV2 size='md' color='primary' isSecondary onClick={onCancel}>
        {t('cancel')}
      </ButtonV2>
      <ButtonV2
        isDisabled={disabled}
        size='md'
        color='primary'
        onClick={onSelected}
      >
        {t('selected')}
      </ButtonV2>
    </ButtonGroup>
  );
};
