import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { useTranslation } from '@/i18n';
import {
  FormRadioButton,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';

export type TPaymentHolidayRuleProps = {
  control: Control<ApPayeeForm>;
};

export const PaymentHolidayRule: FC<TPaymentHolidayRuleProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();

    const radioButtonOptions = useMemo(
      () => [
        {
          id: 'ahead',
          label: t('payment_holiday_rule_ahead'),
          value: 'ahead',
          disabled: false,
        },
        {
          id: 'behind',
          label: t('payment_holiday_rule_behind'),
          value: 'behind',
          disabled: false,
        },
        {
          id: 'nothing',
          label: t('payment_holiday_rule_nothing'),
          value: 'nothing',
          disabled: false,
        },
      ],
      [t]
    );

    return (
      <>
        <Left>
          <Label
            text={t('payment_holiday_rule_label')}
            htmlFor='payment_holiday_rule'
          />
        </Left>
        <Right>
          <FormRadioButton<ApPayeeForm>
            key='right'
            options={radioButtonOptions}
            control={control}
            name='payment_holiday_rule'
            inputId='payment_holiday_rule'
          />
        </Right>
      </>
    );
  }
);
PaymentHolidayRule.displayName = 'PaymentHolidayRule';
