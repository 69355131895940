import { FeatureFlagButton as CompFeatureFlagButton } from '@/components/OpenFeature';
import { r2wc } from '@/wc/helper/render';

export const FeatureFlagButton = r2wc(CompFeatureFlagButton, {
  shadow: 'open',
  props: {
    baseUrl: 'string',
    authorization: 'string',
    targetingKey: 'string',
    pollingInterval: 'number',
    flagName: 'string',
    defaultFlagValue: 'boolean',
    isDisabled: 'boolean',
    isSecondary: 'boolean',
    isTertiary: 'boolean',
    isTransparent: 'boolean',
    color: 'string',
    ruleName: 'string',
    onLabel: 'string',
    offLabel: 'string',
    isReload: 'boolean',
    isProto: 'boolean',
    disabledBeforeUnload: 'boolean',
    tenant: 'boolean',
    tenantUser: 'boolean',
    domain: 'string',
  },
});
