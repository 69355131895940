import { BusinessDocumentActionMenu as CompBusinessDocumentActionMenu } from '@/components/RelationActionMenu';
import { r2wc } from '@/wc/helper/render';

export const BusinessDocumentActionMenu = r2wc(CompBusinessDocumentActionMenu, {
  shadow: 'open',
  props: {
    onSelectedItems: 'function',
    selectedItems: 'json',
    // NOTE: The container is set automatically by r2wc and should be of the appropriate type.
    container: 'string',
    agentApplicantOfficeMember: 'string',
  },
});
