import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormCheckbox,
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TAntisocialCheckProps = {
  control: Control<CounterpartyForm>;
};

export const AntisocialCheck: FC<TAntisocialCheckProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();

    const checkMemoProps = useMemo(
      () => ({
        placeholder: t('counterparty_antisocial_check_memo_placeholder'),
      }),
      [t]
    );

    return (
      <>
        <>
          <Left>
            <Label
              text={t('counterparty_antisocial')}
              htmlFor='is_antisocial_checked'
            />
          </Left>
          <Right>
            <div className={cx(styles['right-content'])}>
              <div>
                <FormCheckbox<CounterpartyForm>
                  control={control}
                  name='is_antisocial_checked'
                  inputId='is_antisocial_checked'
                  testId='is_antisocial_checked'
                  label={t('counterparty_antisocial_checked')}
                />
              </div>
              <FormInput<CounterpartyForm>
                control={control}
                name='antisocial_check_memo'
                inputId='antisocial_check_memo'
                inputProps={checkMemoProps}
              />
            </div>
          </Right>
        </>
      </>
    );
  }
);
AntisocialCheck.displayName = 'AntisocialCheck';
