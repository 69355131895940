import { SupplierUploadSetting } from '@/context/services/ap_payee/type';
import { ExpiredAt } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount/ExpiredAt';
import { InvitationStatus } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount/InvitationStatus';
import { InvitationUrl } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount/InvitationUrl';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ListView,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export type Props = {
  supplierUploadSetting: SupplierUploadSetting;
};

export const UnregisteredAccount: FC<Props> = memo(
  ({ supplierUploadSetting }) => {
    const { t } = useTranslation();

    const expiredAtComponent = useMemo(() => {
      if (
        supplierUploadSetting.isPublishedInvitationUrl &&
        !supplierUploadSetting.isExpiredInvitationUrl
      ) {
        return (
          <Top>
            <ListViewLabel key='left'>
              {t('ap_payee_web_receive_account_url_expiration_date')}
            </ListViewLabel>
            <ExpiredAt expiredAt={supplierUploadSetting.expiredAt} />
          </Top>
        );
      }
    }, [supplierUploadSetting, t]);

    return (
      <ListView>
        <Top>
          <ListViewLabel key='left'>
            {t('ap_payee_web_receive_account_url')}
          </ListViewLabel>
          <InvitationUrl supplierUploadSetting={supplierUploadSetting} />
        </Top>
        {expiredAtComponent}
        <Bottom>
          <ListViewLabel key='left'>
            {t('ap_payee_web_receive_invitation_status')}
          </ListViewLabel>
          <InvitationStatus
            invitationStatus={supplierUploadSetting.invitationStatus}
          />
        </Bottom>
      </ListView>
    );
  }
);
UnregisteredAccount.displayName = 'UnregisteredAccount';
