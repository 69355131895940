import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { useCreateApPayee } from '@/context/services/ap_payee/ApPayee.service';
import { AddNewCounterpartyDialog } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { AddNewPayeeContent } from '@/features/ap_payees/components/Contents';
import { useTranslation } from '@/i18n';
import { SendErrorTracking } from '@/utils/errors';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Form,
  IconApproval,
  InlineNotification,
  Loading,
  Modal,
} from '@moneyforward/ap-frontend-components';
import { ApCounterpartyItem } from 'ap-openapi/openapi/model/apCounterpartyItem';
import classnames from 'classnames/bind';
import { FC, memo, useCallback, useState } from 'react';
import { Control, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
};

export type ApPayeeForm = {
  ap_counterparty_id: string;
  code: string;
  name: string;
  priority: number;
  payment_closing_date: number;
  payment_month: PaymentMonthType;
  payment_due_date: number;
  payment_holiday_rule: PaymentHolidayRuleType;
};

export const PaymentHolidayRule = {
  nothing: 'nothing',
  ahead: 'ahead',
  behind: 'behind',
} as const;

type PaymentHolidayRuleType = keyof typeof PaymentHolidayRule;

export const PaymentMonth = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
} as const;

type PaymentMonthType = valueOf<typeof PaymentMonth>;

export const AddNewPayeeDialog: FC<Props> = memo(({ title, open, onClose }) => {
  const containerRef = useGlobalContainerRef();
  const { t } = useTranslation();
  const methods = useForm<ApPayeeForm>({
    defaultValues: {
      priority: 100,
      payment_closing_date: 31,
      payment_month: PaymentMonth.NUMBER_1,
      payment_due_date: 31,
      payment_holiday_rule: PaymentHolidayRule.ahead,
    },
  });
  const { control, reset, clearErrors, setValue } = methods;
  const { isSubmitting } = useFormState({ control });
  const [visible, setVisible] = useState(false);

  const onHiddenInlineNotification = useCallback(() => {
    setVisible(false);
  }, []);

  const [
    { setError },
    ErrorContextHolder,
    ErrorInlineNotificationProvider,
    onClearMessage,
  ] = useErrorInlineNotification();

  const handleResetForm = useCallback(() => {
    reset();
    clearErrors();
    onClearMessage();
    onHiddenInlineNotification();
  }, [reset, clearErrors, onClearMessage, onHiddenInlineNotification]);

  const onCloseClick = useCallback(() => {
    handleResetForm();
    onClose();
  }, [handleResetForm, onClose]);

  const { mutateAsync } = useCreateApPayee();
  const onSubmit: SubmitHandler<ApPayeeForm> = useCallback(
    async (form) => {
      try {
        const { status, redirectPath, errors } = await mutateAsync({
          data: { ap_payee: form },
        });
        if (status === 'success') {
          location.href = redirectPath;
        } else {
          if (errors?.messages) {
            setError(new Error(errors.messages.join('')));
          }
        }
      } catch (error) {
        let message;
        if (error instanceof Error) {
          message = error.message;
        } else {
          message = t('system_error');
        }
        setError(new SendErrorTracking(message));
      }
    },
    [t, mutateAsync, setError]
  );

  const [counterpartyOpen, setCounterpartyOpen] = useState(false);

  const onCounterpartyModalOpen = useCallback(() => {
    setCounterpartyOpen(true);
  }, []);
  const onCounterpartyModalClose = useCallback(() => {
    setCounterpartyOpen(false);
  }, []);

  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const onCallback = useCallback(
    (data: ApCounterpartyItem) => {
      setValue('ap_counterparty_id', data.id);
      setSelectedOptions([
        {
          label: data.name,
          value: data.id,
        },
      ]);
      setCounterpartyOpen(false);
      setVisible(true);
    },
    [setValue]
  );

  return (
    <Modal
      open={open}
      onClose={onCloseClick}
      getContainer={containerRef}
      title={title}
      size='lg'
      footer={
        <ButtonGroup>
          <ButtonV2
            size='sm'
            onClick={onCloseClick}
            isSecondary
            isDisabled={isSubmitting}
          >
            {t('cancel')}
          </ButtonV2>
          <SubmitButton control={control} />
        </ButtonGroup>
      }
    >
      <ErrorInlineNotificationProvider value={setError}>
        <InlineNotification
          message={t('counterparty_add_success_message')}
          onClick={onHiddenInlineNotification}
          type='success'
          visible={visible}
          icon={<IconApproval size={18} />}
          className={cx(styles['inline-notification-success-message'])}
        />
        <Form<ApPayeeForm>
          id='formAddApPayee'
          formMethod={methods}
          onSubmit={onSubmit}
        >
          {ErrorContextHolder ? (
            <div className={cx(styles['section-title-wrap'])}>
              {ErrorContextHolder}
            </div>
          ) : null}
          <Loading
            isDialogMode
            open={isSubmitting}
            getContainer={containerRef}
            size='xlarge'
            color='white'
          />
          <AddNewPayeeContent
            control={control}
            key={`payee_${open}`}
            onCounterpartyModalOpen={onCounterpartyModalOpen}
            selectedOptions={selectedOptions}
            counterpartyOpen={counterpartyOpen}
          />
        </Form>
        <AddNewCounterpartyDialog
          title={t('counterparty_add_new_dialog_title')}
          open={counterpartyOpen}
          onClose={onCounterpartyModalClose}
          onCallback={onCallback}
        />
      </ErrorInlineNotificationProvider>
    </Modal>
  );
});
AddNewPayeeDialog.displayName = 'AddNewPayeeDialog';

type SubmitButtonProps = {
  control: Control<ApPayeeForm>;
};

const SubmitButton: FC<SubmitButtonProps> = ({ control }) => {
  const { t } = useTranslation();

  const {
    isDirty,
    isValid: isStateValid,
    isSubmitting,
    errors,
  } = useFormState({ control });

  const isFormValid = Object.values(errors)
    .map((err) => {
      const inValid = Array.isArray(err) ? err.length > 0 : !!err.message;
      return inValid;
    })
    .some(Boolean);

  return (
    <ButtonV2
      isDisabled={!isDirty || isFormValid || !isStateValid || isSubmitting}
      type='submit'
      color='primary'
      testId='submit-btn'
      size='sm'
      form='formAddApPayee'
    >
      {t('add')}
    </ButtonV2>
  );
};
