import EmptyPdf from '@/assets/empty.pdf?base64';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { PdfViewer } from '@/components/pdfViewer';
import { useGetMfFile } from '@service/mf_file/MfFile.service';
import classnames from 'classnames/bind';
import {
  useCreateObjectURL,
  useCreateObjectURLWithMemo,
  useToBlob,
} from 'packages-hooks';
import { FC, memo } from 'react';
import styles from './Dialog.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  mfFileId: string;
};

export const MainPreview: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary>
      <InnerMainPreview {...props} />
    </SuspenseErrorBoundary>
  );
});
MainPreview.displayName = 'MainPreview';

export const InnerMainPreview: FC<Props> = memo(({ mfFileId }) => {
  const originalBlob = useGetMfFile(mfFileId);
  const url = useCreateObjectURL(originalBlob);
  return <PdfViewer className={cx(styles['previewContainer'])} src={url} />;
});
InnerMainPreview.displayName = 'InnerMainPreview';

export const EmptyPreview: FC = memo(() => {
  const originalBlob = useToBlob(EmptyPdf);
  const url = useCreateObjectURLWithMemo(originalBlob);
  return <PdfViewer className={cx(styles['previewContainer'])} src={url} />;
});
EmptyPreview.displayName = 'EmptyPreview';
