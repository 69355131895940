import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  contactOfficeMemberNames: string | undefined;
};

export const ContactPersonMemberNames: FC<Props> = memo(
  ({ contactOfficeMemberNames }) => {
    return (
      <ListViewBody key='right' className={cx(styles['optional-field'])}>
        <div>{contactOfficeMemberNames}</div>
      </ListViewBody>
    );
  }
);
ContactPersonMemberNames.displayName = 'ContactPersonMemberNames';
