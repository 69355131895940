import {
  FileNameSeparatorWithExtension,
  Props as FileNameSeparatorWithExtensionProps,
} from '@/components/FileNameSeparatorWithExtension';
import { TextLink as BaseTextLink } from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export type Props = {
  fileName: string;
  href: string;
  newWindow?: boolean;
  containerMaxWidth?: string;
  allowedFileTypes?: string;
} & Omit<FileNameSeparatorWithExtensionProps, 'children'>;

export const TextLink: FC<Props> = memo(
  ({
    fileName,
    href,
    newWindow,
    containerClassName,
    textClassName,
    extensionClassName,
    containerMaxWidth = '330px',
    allowedFileTypes,
  }) => {
    const containerStyle = useMemo(() => {
      return containerMaxWidth ? { width: containerMaxWidth } : {};
    }, [containerMaxWidth]);

    return (
      <div style={containerStyle}>
        <BaseTextLink
          href={href}
          target={newWindow ? '_blank' : ''}
          // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
          style={{ width: '100%' }}
        >
          <FileNameSeparatorWithExtension
            containerClassName={containerClassName}
            textClassName={textClassName}
            extensionClassName={extensionClassName}
            allowedFileTypes={allowedFileTypes}
          >
            {fileName}
          </FileNameSeparatorWithExtension>
        </BaseTextLink>
      </div>
    );
  }
);
TextLink.displayName = 'TextLink';
