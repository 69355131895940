import { Language, OfficeMemberIdTypeEnum, mockLoginQuery } from 'ap-graphql';
import dayjs from 'dayjs';
import { HttpResponse } from 'msw';

export const handlers = [
  mockLoginQuery(({}) => {
    return HttpResponse.json({
      data: {
        __typename: 'Query',
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        activeLanguage: 'ja' as Language,
        currentOffice: {
          __typename: 'Office',
          id: '001',
          identificationCode: '1234-567',
          isActive: true,
          officeName: 'Mock inc.',
          isMoreThanTeamPlan: true,
          createdAt: dayjs().toISOString(),
        },
        currentOfficeMember: {
          __typename: 'OfficeMember',
          id: '0001',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          idType: 'email' as OfficeMemberIdTypeEnum,
          identificationCode: '1234-567',
          name: 'Mock',
          number: '1',
        },
        currentUser: {
          __typename: 'User',
          id: '1',
          identificationCode: '1234-567',
        },
      },
    });
  }),
];
