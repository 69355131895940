import { InputBusinessDocumentSearch } from '@/components/RelationActionMenu/BusinessDocument/ActionMenu/InputBusinessDocumentSearch';
import { IconSubmitApplication } from '@/components/RelationActionMenu/Icons';
import { BusinessDocumentColumnWithMfFile } from '@/features/business_documents/List/type';
import { useTranslation } from '@/i18n';
import { ActionMenuItems } from '@moneyforward/ap-frontend-components';
import { useMemo } from 'react';

type ItemArgs = {
  onItemsClick: VoidFunction;
  onInputSearchClick: (id: BusinessDocumentColumnWithMfFile) => void;
};

export const useItems = ({
  onItemsClick,
  onInputSearchClick,
}: ItemArgs): ActionMenuItems => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        label: t(
          'relation_action.business_document.select_from_business_documents'
        ),
        onClick: onItemsClick,
        key: '1',
        icon: <IconSubmitApplication size={16} />,
      },
      {
        label: (
          <InputBusinessDocumentSearch
            onInputSearchClick={onInputSearchClick}
          />
        ),
        key: '2',
        isCustom: true,
        divider: true,
      },
    ],
    [t, onItemsClick, onInputSearchClick]
  );
};
