import { CopyButton } from '@/components/CopyButton';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  address: string | undefined;
  uniqueCode: string | undefined;
  destination: string | undefined;
};

export const Destination: FC<Props> = memo(
  ({ address, uniqueCode, destination }) => {
    const { t } = useTranslation();

    return (
      <ListViewBody key='right' className={cx(styles['copy-wrap'])}>
        <div>
          <div>{address}</div>
          <div>{uniqueCode}</div>
          <div>{destination}</div>
        </div>
        <CopyButton
          show
          copyTooltip={t('ap_payee_agent_receive_destination_copy_text')}
          copiedTooltip={t('ap_payee_web_receive_copied_text')}
          text={`${address}${uniqueCode} ${destination}`}
        />
      </ListViewBody>
    );
  }
);
Destination.displayName = 'Destination';
