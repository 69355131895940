import { useBusinessPersonData } from '@/context/BusinessPersonData';
import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormRadioButton,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type TCounterpartyCompanyTypeProps = {
  control: Control<CounterpartyForm>;
};
export const CompanyType: FC<TCounterpartyCompanyTypeProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();
    const invoiceRegisterType = useWatch({
      control,
      name: 'register_type',
    });
    const { businessPersonData } = useBusinessPersonData();

    const radioButtonOptions = useMemo(() => {
      const disabled = invoiceRegisterType === 'registered';
      return [
        {
          id: 'unknown',
          label: t('company_type_unknown'),
          value: 'unknown',
          disabled: disabled,
        },
        {
          id: 'corporate',
          label: t('company_type_corporate'),
          value: 'corporate',
          disabled: disabled,
        },
        {
          id: 'individual',
          label: t('company_type_individual'),
          value: 'individual',
          disabled: disabled,
        },
      ];
    }, [invoiceRegisterType, t]);

    return (
      <>
        <Left>
          <Label text={t('counterparty_company_type')} htmlFor='company_type' />
        </Left>
        <Right>
          <div>
            {invoiceRegisterType === 'registered' &&
            businessPersonData?.companyType ? (
              <p>{businessPersonData.companyType}</p>
            ) : (
              <>
                <FormRadioButton
                  key='right'
                  options={radioButtonOptions}
                  control={control}
                  name='company_type'
                  inputId='company_type'
                  testId='company_type'
                />
                <p className={cx(styles['company-type-text'])}>
                  {t('counterparty_company_type_description')}
                </p>
              </>
            )}
          </div>
        </Right>
      </>
    );
  }
);
CompanyType.displayName = 'CompanyType';
