import { faker } from '@faker-js/faker/locale/ja';
import {
  ApPayeeDetailDisplay,
  ApPayeeDetailDisplayInvoiceReceiveEmailProcessType,
} from 'ap-openapi';
import { HttpResponse, delay, http } from 'msw';
faker.seed(1);
const _getOfficeMembers = Array.from({ length: 11 }, (_, key) => ({
  [key]: Array.from({ length: 25 }, () => {
    const name = faker.person.fullName();
    const id = faker.string.uuid();
    const display_name = `${name}(${id})`;
    return {
      display_name: display_name,
      id: id,
      name: name,
      number: faker.helpers.arrayElement([
        faker.number.int().toString(),
        undefined,
      ]),
    };
  }),
})).reduce((obj, item) => ({ ...obj, ...item }), {});
export const getOfficeMembers = (page: number) => _getOfficeMembers[page] ?? [];

const _getGetApPayees = Array.from({ length: 11 }, (_, key) => ({
  [key]: Array.from({ length: 25 }, () => {
    const name = faker.company.name();
    const code = faker.string.uuid();
    return {
      book_date: faker.date.past().toISOString().split('T')[0],
      code: code,
      default_cr_item_id: faker.word.sample(),
      default_cr_sub_item_id: faker.word.sample(),
      default_dept_id: faker.word.sample(),
      default_ex_item_id: faker.word.sample(),
      default_name: name,
      default_project_code_id: faker.word.sample(),
      display_name: `${name}(${code})`,
      id: code,
      invoice_registration_number: faker.number
        .int({ min: 1000000000000, max: 9999999999999 })
        .toString(),
      is_tax_included: faker.helpers.arrayElement([
        faker.datatype.boolean(),
        undefined,
      ]),
      is_withholding_tax: faker.helpers.arrayElement([
        faker.datatype.boolean(),
        undefined,
      ]),
      name: name,
      payment_date: faker.helpers.arrayElement([
        faker.date.past().toISOString().split('T')[0],
        undefined,
      ]),
      priority: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
      ]),
    };
  }),
})).reduce((obj, item) => ({ ...obj, ...item }), {});

export const getGetApPayees = (page: number) => _getGetApPayees[page] ?? [];

const getGetApPayeesDetail = (id: string): ApPayeeDetailDisplay => {
  const name = faker.company.name();
  const code = faker.string.uuid();
  const res = {
    id: code,
    display_name: `${name}(${code})`,
    contact_office_member_name: faker.person.fullName(),
    contact_office_member_names: Array.from({ length: 3 }, () =>
      faker.person.fullName()
    ).join(', '),
    is_active: true,
    contact_person_email: faker.internet.email(),
    contact_person_name: faker.person.fullName(),
    invoice_receive_email_address: faker.internet.email(),
    is_invoice_receive_email_setting: true,
    invoice_receive_email_process_type: faker.helpers.arrayElement(
      Object.values(ApPayeeDetailDisplayInvoiceReceiveEmailProcessType)
    ),
    is_agent_receive_setting: true,
    is_supplier_upload_setting: true,
    agent_receive_setting: {
      address: faker.location.streetAddress(),
      destination: faker.location.streetAddress(),
      unique_code: faker.string.uuid(),
    },
    supplier_upload_setting: {
      expired_at: '2025年3月13日 23:49まで',
      invitation_status: '未登録',
      invitation_url: faker.internet.url(),
      is_accepted_invitation: true,
      is_expired_invitation_url: true,
      is_published_invitation_url: true,
      tenant_name: faker.company.name(),
    },
  };

  switch (id) {
    case 'no_receive_setting': {
      return {
        ...res,
        is_agent_receive_setting: false,
        is_supplier_upload_setting: false,
      };
    }
    case 'no_receive_email_setting': {
      return {
        ...res,
        is_invoice_receive_email_setting: false,
      };
    }
    case 'receive_email_setting_receive_invoice': {
      return {
        ...res,
        invoice_receive_email_process_type: 'received_invoice',
      };
    }
    case 'receive_email_setting_payment_request_with_pic': {
      return {
        ...res,
        invoice_receive_email_process_type: 'payment_request',
      };
    }
    case 'receive_email_setting_payment_request': {
      return {
        ...res,
        contact_office_member_name: undefined,
        invoice_receive_email_process_type: 'payment_request',
      };
    }
    case 'registered_account': {
      return {
        ...res,
        supplier_upload_setting: {
          ...res.supplier_upload_setting,
          invitation_status: '登録済み',
          is_accepted_invitation: true,
        },
      };
    }
    case 'expired_invitation': {
      return {
        ...res,
        supplier_upload_setting: {
          ...res.supplier_upload_setting,
          is_accepted_invitation: false,
          is_expired_invitation_url: true,
        },
      };
    }
    case 'not_published_account_url': {
      return {
        ...res,
        supplier_upload_setting: {
          ...res.supplier_upload_setting,
          is_published_invitation_url: false,
          is_accepted_invitation: false,
        },
      };
    }
    case 'published_account_url': {
      return {
        ...res,
        supplier_upload_setting: {
          ...res.supplier_upload_setting,
          is_accepted_invitation: false,
          is_expired_invitation_url: false,
          is_published_invitation_url: true,
        },
      };
    }
    default: {
      return res;
    }
  }
};

export const getGetApPayeesMock = (page: number, query: string) => ({
  next: page <= 2 ? `/api/js/ap_payees?page=${page + 1}` : undefined,
  ap_payees: getGetApPayees(page - 1).filter((q) =>
    !query ? true : q.display_name.indexOf(query) !== -1
  ),
  prev: page > 1 ? `/api/js/ap_payees?page=${page - 1}` : undefined,
});
const getGetOfficeMembersMock = (page: number, query: string) => ({
  next: page <= 2 ? `/api/js/office_members?page=${page + 1}` : undefined,
  office_members: getOfficeMembers(page - 1).filter((q) =>
    !query ? true : q.display_name.indexOf(query) !== -1
  ),
  prev: page > 1 ? `/api/js/office_members?page=${page - 1}` : undefined,
});
export const getGetApPayeesDetailMock = (id: string) =>
  getGetApPayeesDetail(id);

export const handlers = [
  http.get('*/api/js/ap_payees', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    const query = url.searchParams.get('query') ?? '';
    return new HttpResponse(JSON.stringify(getGetApPayeesMock(page, query)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.get('*/api/js/office_members', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    const query = url.searchParams.get('query') ?? '';
    return new HttpResponse(
      JSON.stringify(getGetOfficeMembersMock(page, query)),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
  http.get('*/api/js/ap_payees/:id/detail', async (req) => {
    await delay(1000);
    const id = req.params.id;
    if (id instanceof Array) return;

    return new HttpResponse(JSON.stringify(getGetApPayeesDetailMock(id!)), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
