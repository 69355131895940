import { PayeeDetailModal } from '@/features/ap_payees/components/PayeeDetailModal/index';
import { r2wc } from '@/wc/helper/render';

export const DetailApPayeeModal = r2wc(PayeeDetailModal, {
  shadow: 'open',
  props: {
    apPayeeId: 'string',
    apPayeeName: 'string',
  },
});
