import { TFlagStatus } from '@/components/OpenFeature/Dialog/type';
import { Wrapper } from '@/components/OpenFeature/Wrapper';
import { useFFContext } from '@/components/OpenFeature/hooks/useFFContext';
import { usePollingInterval } from '@/components/OpenFeature/hooks/usePollingInterval';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  ButtonV2Props,
  isIframe,
  useNotification,
} from '@moneyforward/ap-frontend-components';
import {
  OpenFeatureProvider,
  useAddValueInQuery,
  useFinishedProviderSettings,
  useRemoveValueInQuery,
} from '@moneyforward/user-flex-feature-hooks';
import { useBooleanFlagDetails } from '@openfeature/react-sdk';
import {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Dialog } from '../Dialog';
import {
  FeatureFlagProps,
  FeatureFlagProviderProps,
  WrapperProps,
} from '../types';

type InnerProps = FeatureFlagProps & {
  ruleName: string;
  onLabel: string;
  offLabel: string;
  isReload?: boolean;
  disabledBeforeUnload?: boolean;
} & Pick<
    ButtonV2Props,
    'color' | 'isDisabled' | 'isSecondary' | 'isTertiary' | 'isTransparent'
  >;

const InnerFeatureFlagButton: FC<InnerProps> = memo(
  ({
    targetingKey,
    flagName,
    ruleName,
    onLabel,
    offLabel,
    disabledBeforeUnload,
    defaultFlagValue = false,
    isReload = true,
    isSecondary = true,
    ...rest
  }) => {
    const { t } = useTranslation();
    const notify = useNotification();
    const args = useMemo(() => ({ flagName, ruleName }), [flagName, ruleName]);
    const addInQuery = useAddValueInQuery(args);
    const removeQuery = useRemoveValueInQuery(args);
    const { value: flag, reason } = useBooleanFlagDetails(
      flagName,
      defaultFlagValue
    );
    const [isLoading, setIsLoading] = useState(false);
    const flagStatus: TFlagStatus = useMemo(
      () => (flag ? 'new' : 'old'),
      [flag]
    );
    const [open, setOpen] = useState(false);
    const onClose = useCallback(() => {
      setOpen(false);
    }, []);
    const onReloadWindow = useCallback(
      (w: Window) => {
        if (disabledBeforeUnload) {
          w.onbeforeunload = null;
          w.dispatchEvent(
            new CustomEvent('disableBeforeUnload', { bubbles: true })
          );
        }
        w.location.reload();
      },
      [disabledBeforeUnload]
    );
    const onPrimaryClick = useCallback(async () => {
      setIsLoading(true);
      let result: boolean = false;
      try {
        if (reason !== 'DEFAULT') {
          result = await removeQuery({ value: targetingKey });
        } else {
          result = await addInQuery({ value: targetingKey });
        }
      } finally {
        if (result && isReload) {
          if (isIframe()) {
            onReloadWindow(window.parent);
          } else {
            onReloadWindow(window);
          }
        } else {
          setIsLoading(false);
        }
      }

      if (!result) {
        const msg = t('error_feature_flag_update');
        notify.error({
          title: msg,
          opts: {
            duration: 3000,
            style: {
              position: 'fixed',
            },
          },
          bodyProps: {
            disabledSuffix: true,
          },
        });
        throw new Error(msg);
      }
    }, [
      addInQuery,
      isReload,
      notify,
      onReloadWindow,
      reason,
      removeQuery,
      t,
      targetingKey,
    ]);
    const onClick = useCallback(() => {
      setOpen(true);
    }, []);
    const label = useMemo(
      () => (flag ? onLabel : offLabel),
      [flag, offLabel, onLabel]
    );
    return (
      <>
        <ButtonV2
          isLoading={isLoading}
          onClick={onClick}
          isSecondary={isSecondary}
          {...rest}
        >
          {label}
        </ButtonV2>
        <Dialog
          status={flagStatus}
          open={open}
          onClose={onClose}
          onPrimaryClick={onPrimaryClick}
          isLoading={isLoading}
        />
      </>
    );
  }
);
InnerFeatureFlagButton.displayName = 'InnerFeatureFlagButton';

const OpenFeatureFinishedWrapper: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const finished = useFinishedProviderSettings();
    return finished ? children : <></>;
  }
);
OpenFeatureFinishedWrapper.displayName = 'OpenFeatureFinishedWrapper';

export type Props = FeatureFlagProviderProps & InnerProps & WrapperProps;

export const FeatureFlagButton: FC<Props> = memo(
  ({
    baseUrl: baseURL,
    authorization,
    targetingKey,
    isProto,
    tenant,
    tenantUser,
    pollingInterval = 5,
    domain = 'ap-frontend-btn',
    ...rest
  }) => {
    const context = useFFContext(targetingKey, tenant, tenantUser);
    const internal = usePollingInterval(pollingInterval);
    return (
      <Wrapper
        baseUrl={baseURL}
        authorization={authorization}
        isProto={isProto}
      >
        <OpenFeatureProvider
          pollInterval={internal}
          context={context}
          domain={domain}
        >
          <OpenFeatureFinishedWrapper>
            <InnerFeatureFlagButton
              {...rest}
              targetingKey={context.targetingKey}
            />
          </OpenFeatureFinishedWrapper>
        </OpenFeatureProvider>
      </Wrapper>
    );
  }
);
FeatureFlagButton.displayName = 'FeatureFlagButton';
