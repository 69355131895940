import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';

export type TCounterpartyNameKanaProps = {
  control: Control<CounterpartyForm>;
};
export const CounterpartyNameKana: FC<TCounterpartyNameKanaProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();
    const nameKanaInputProps = useMemo(
      () => ({
        placeholder: t('full_half_katakana'),
      }),
      [t]
    );
    return (
      <>
        <Left>
          <Label text={t('counterparty_name_kana')} htmlFor='name_kana' />
        </Left>
        <Right>
          <FormInput<CounterpartyForm>
            control={control}
            name='name_kana'
            inputId='name_kana'
            inputProps={nameKanaInputProps}
          />
        </Right>
      </>
    );
  }
);
CounterpartyNameKana.displayName = 'CounterpartyNameKana';
