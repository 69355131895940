import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { AxiosCustomOptions, useCustomClient } from 'ap-openapi';
import { useCallback } from 'react';
import type { TypedDocumentString } from './graphql/generated/graphql';
import { useGraphQLUrl } from './provider';

function extractOperationName<TResult, TVariables>(
  document: TypedDocumentString<TResult, TVariables>
): string | undefined {
  // ドキュメントオブジェクトの内部を文字列化し、正規表現でクエリ名を抽出
  const match = document
    .toString()
    .match(/(?:query|mutation|subscription)\s+(\w+)/);
  return match ? match[1] : undefined;
}

type Execute<TResult, TVariables> = (
  query: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables extends Record<string, never> ? {} : TVariables,
  options?: AxiosCustomOptions & { signal?: AbortSignal }
) => Promise<TResult>;

export const useGraphQLHook = <TResult, TVariables>(): Execute<
  TResult,
  TVariables
> => {
  const url = useGraphQLUrl();
  const client = useCustomClient<{ data: TResult }>();
  return useCallback(
    async (
      query: TypedDocumentString<TResult, TVariables>,
      variables?: TVariables extends Record<string, never> ? {} : TVariables,
      options?: AxiosCustomOptions & { signal?: AbortSignal }
    ) => {
      const { signal, ...opts } = options ?? {};
      const resp = await client(
        {
          url: url,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/graphql-response+json',
          },
          data: {
            operationName: extractOperationName(query),
            query: query,
            variables,
          },
          signal,
        },
        opts
      );
      if (resp.status !== 200) {
        throw resp;
      }
      return resp.data.data;
    },
    [client, url]
  );
};

export const useGraphQLOptions = <TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables extends Record<string, never> ? {} : TVariables,
  options?: {
    query?: Partial<
      UseQueryOptions<
        ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>,
        Error,
        TResult
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? [query, variables];
  const execute = useGraphQLHook<TResult, TVariables>();
  const queryFn: QueryFunction<
    ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>
  > = ({ signal }) => execute(query, variables, { signal });
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseQueryOptions<
    ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>,
    Error,
    TResult
  > & { queryKey: QueryKey };
};

export const useGraphQL = <TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables extends Record<string, never> ? {} : TVariables,
  options?: {
    query?: Partial<
      UseQueryOptions<
        ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>,
        Error,
        TResult
      >
    >;
  }
) => {
  const queryOptions = useGraphQLOptions(query, variables, options);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const q = useQuery(queryOptions) as UseQueryResult<TResult> & {
    queryKey: QueryKey;
  };
  q.queryKey = queryOptions.queryKey;
  return q;
};

export const useGraphQLSuspenseOptions = <TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables extends Record<string, never> ? {} : TVariables,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>,
        Error,
        TResult
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? [query, variables];
  const execute = useGraphQLHook<TResult, TVariables>();
  const queryFn: QueryFunction<
    ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>
  > = ({ signal }) => execute(query, variables, { signal });
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    queryKey,
    queryFn,
    enabled: true,
    ...queryOptions,
  } as UseQueryOptions<
    ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>,
    Error,
    TResult
  > & { queryKey: QueryKey };
};

export const useGraphQLSuspense = <TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables extends Record<string, never> ? {} : TVariables,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        ReturnType<ReturnType<typeof useGraphQLHook<TResult, TVariables>>>,
        Error,
        TResult
      >
    >;
  }
) => {
  const queryOptions = useGraphQLOptions(query, variables, options);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const q = useSuspenseQuery(
    queryOptions
  ) as UseSuspenseQueryResult<TResult> & {
    queryKey: QueryKey;
  };
  q.queryKey = queryOptions.queryKey;
  return q;
};
