import { InvoiceColumnWithMfFile } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import { ActionMenuItems, IconAdd } from '@moneyforward/ap-frontend-components';
import { useMemo } from 'react';
import { IconSubmitApplication } from '../Icons';
import { InputInvoiceSearch } from './ActionMenu';

type ItemArgs = {
  onNewClick: VoidFunction;
  onInvoiceClick: VoidFunction;
  onInputSearchClick: (invoice: InvoiceColumnWithMfFile) => void;
};

export const useItems = ({
  onNewClick,
  onInvoiceClick,
  onInputSearchClick,
}: ItemArgs): ActionMenuItems => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        label: t('relation_action.new_invoice'),
        onClick: onNewClick,
        key: '1',
        icon: <IconAdd size={16} />,
      },
      {
        label: t('relation_action.selected_invoice'),
        onClick: onInvoiceClick,
        key: '2',
        icon: <IconSubmitApplication />,
      },
      {
        label: <InputInvoiceSearch onInputSearchClick={onInputSearchClick} />,
        key: '3',
        isCustom: true,
        divider: true,
      },
    ],
    [onInputSearchClick, onInvoiceClick, onNewClick, t]
  );
};
