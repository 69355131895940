import { RelationBusinessDocumentDialog } from '@/components/RelationActionMenu/BusinessDocument/Dialog';
import { useItems } from '@/components/RelationActionMenu/BusinessDocument/Items';
import {
  MaxSelectedCount,
  SelectedBusinessDocument,
} from '@/components/RelationActionMenu/BusinessDocument/type';
import { useRelationDialogOpen } from '@/components/RelationActionMenu/hooks/useRelationDialogOpen';
import { AgentApplicantOfficeMemberProvider } from '@/context/AgentApplicantOfficeMember';
import { BusinessDocumentColumnWithMfFile } from '@/features/business_documents/List/type';
import { useTranslation } from '@/i18n';

import {
  ActionMenu,
  ActionMenuItems,
  ActionMenuProps,
  ButtonV2Props,
  IconTrailing,
} from '@moneyforward/ap-frontend-components';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import { FC, memo, useCallback, useMemo, useRef } from 'react';

export type Props = {
  container: HTMLElement;
  onSelectedItems?: (selected: SelectedBusinessDocument[]) => void;
  selectedItems?: SelectedBusinessDocument[];
  agentApplicantOfficeMember?: string;
};

export const BusinessDocumentActionMenu: FC<Props> = memo(
  ({ agentApplicantOfficeMember, ...rest }) => {
    return (
      <AgentApplicantOfficeMemberProvider
        officeMember={agentApplicantOfficeMember}
        business_document
        mf_file
      >
        <InnerBusinessDocumentActionMenu {...rest} />
      </AgentApplicantOfficeMemberProvider>
    );
  }
);
BusinessDocumentActionMenu.displayName = 'BusinessDocumentActionMenu';

const InnerBusinessDocumentActionMenu: FC<Props> = memo(
  ({ onSelectedItems: _onSelectedItems, container, selectedItems }) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement | null>(null);
    const { open, onOpen, onClose } = useRelationDialogOpen();
    const {
      open: _open,
      onOpen: _onOpen,
      onClose: _onClose,
    } = useRelationDialogOpen();

    const items: ActionMenuItems = useItems({
      onInputSearchClick: (item: BusinessDocumentColumnWithMfFile) => {
        _onSelectedItems?.([
          ...selectedItems!,
          {
            businessDocumentId: item.id,
            searchableNumber: item.searchable_number,
            documentTitle: item.document_title,
            mfFileId: item.mfFile.id,
          },
        ]);
        _onClose();
      },
      onItemsClick: () => {
        onOpen();
        _onClose();
      },
    });
    const onOpenChange = useCallback(
      (_open: boolean) => {
        _open ? _onOpen() : _onClose();
      },
      [_onOpen, _onClose]
    );
    const onSelectedItems = useCallback(
      (value: SelectedBusinessDocument[]) => {
        onClose();
        _onSelectedItems?.(value);
      },
      [onClose, _onSelectedItems]
    );
    const buttonProps: ButtonV2Props = useMemo(
      () => ({
        children: t('relation_action.business_document.select_button'),
        color: 'primary',
        isSecondary: true,
        isTertiary: false,
        rightIcon: <IconTrailing size={16} />,
        isDisabled: selectedItems && selectedItems?.length > MaxSelectedCount,
      }),
      [t, selectedItems]
    );
    const dropDownProps: ActionMenuProps['dropDownProps'] = useMemo(
      () => ({
        open: _open,
        onOpenChange,
        destroyPopupOnHide: true,
      }),
      [_open, onOpenChange]
    );

    return (
      <div ref={ref}>
        <ActionMenu
          type='button'
          dropDownProps={dropDownProps}
          items={items}
          buttonProps={buttonProps}
          disabled={selectedItems && selectedItems?.length > MaxSelectedCount}
        />
        <ErrorBoundary>
          <RelationBusinessDocumentDialog
            open={open}
            onClose={onClose}
            container={container!}
            onSelectedItems={onSelectedItems}
            selectedItems={selectedItems}
          />
        </ErrorBoundary>
      </div>
    );
  }
);
InnerBusinessDocumentActionMenu.displayName = 'InnerBusinessDocumentActionMenu';
