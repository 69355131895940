import { KeywordCont } from '@/components/KeywordCont';
import { useTranslation } from '@/i18n';
import {
  DatepickerProps,
  Form,
  FormDatepicker,
} from '@moneyforward/ap-frontend-components';
import { Col, Row } from 'antd';
import classnames from 'classnames/bind';
import { toISODateStrings } from 'date-util';
import parser from 'html-react-parser';
import { FC, memo, useEffect, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import styles from './Search.module.scss';

const cx = classnames.bind(styles);

export type SearchInput = {
  keyword_cont: string | undefined;
  uploadedAt: string[] | undefined;
};

export type SearchProps = {
  onSearch: (item: SearchInput) => void;
};

export const Search: FC<SearchProps> = memo(({ onSearch }) => {
  const { t } = useTranslation();
  const methods = useForm<SearchInput>({
    defaultValues: {
      keyword_cont: '',
      uploadedAt: undefined,
    },
    mode: 'all',
  });
  const { control, watch } = methods;
  const values = watch();
  const inputs = useMemo(() => {
    const { keyword_cont, uploadedAt } = values;
    const input = {
      keyword_cont,
      uploadedAt: uploadedAt ? toISODateStrings(uploadedAt) : undefined,
    };
    return input;
  }, [values]);
  const onDebounceSearch = useDebouncedCallback(onSearch, 600);

  useEffect(() => {
    onDebounceSearch(inputs);
  }, [inputs, onDebounceSearch]);

  const keywordLabelProps = useMemo(
    () => ({
      text: t('business_document_key_word'),
      tooltip: parser(t('relation_action.business_document_keyword_tooltip')),
    }),
    [t]
  );

  const keywordInputProps = useMemo(
    () => ({
      placeholder: t('business_document_key_word_placeholder'),
    }),
    [t]
  );

  return (
    <Form formMethod={methods} className={cx(styles['container'])}>
      <Row gutter={8}>
        <Col span={12}>
          <KeywordCont
            name='keyword_cont'
            control={control}
            labelProps={keywordLabelProps}
            inputProps={keywordInputProps}
          />
        </Col>
        <Col span={12}>
          <UploadedAt control={control} />
        </Col>
      </Row>
    </Form>
  );
});
Search.displayName = 'Search';

type UploadedAtProps = {
  control: Control<SearchInput>;
};

const UploadedAt: FC<UploadedAtProps> = memo(({ control }) => {
  const { t } = useTranslation();
  const datepickerProps: DatepickerProps = useMemo(
    () => ({
      placeholder: [t('date_gteq_placeholder'), t('date_lteq_placeholder')],
    }),
    [t]
  );
  const label = useMemo(
    () => ({
      text: t('label_uploaded_at'),
    }),
    [t]
  );
  return (
    <FormDatepicker<SearchInput>
      control={control}
      name='uploadedAt'
      inputId='select-uploaded-at'
      label={label}
      datepickerProps={datepickerProps}
    />
  );
});
UploadedAt.displayName = 'UploadedAt';
