import { WrapperProps } from '@/components/OpenFeature/types';
import { isStorybook } from '@/utils/is';
import { createConnectTransport } from '@connectrpc/connect-web';
import { AxiosProvider } from '@moneyforward/user-flex-feature-hooks';
import { TransportProvider } from '@moneyforward/user-flex-feature-openapi';
import Axios from 'axios';
import { FC, PropsWithChildren, memo, useMemo } from 'react';

export const Wrapper: FC<PropsWithChildren<WrapperProps>> = memo(
  ({ baseUrl: baseURL, authorization, children, isProto = true }) => {
    const _baseURL = useMemo(() => baseURL ?? location.origin, [baseURL]);
    const transport = useMemo(
      () =>
        createConnectTransport({
          baseUrl: _baseURL,
          useBinaryFormat: isStorybook() ? false : isProto,
        }),
      [_baseURL, isProto]
    );
    const headers = useMemo(
      () => (authorization ? { Authorization: authorization } : {}),
      [authorization]
    );
    const axios = useMemo(
      () =>
        Axios.create({
          baseURL: _baseURL,
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
        }),
      [_baseURL, headers]
    );
    return (
      <TransportProvider transport={transport}>
        <AxiosProvider value={axios}>{children}</AxiosProvider>
      </TransportProvider>
    );
  }
);
Wrapper.displayName = 'Wrapper';
