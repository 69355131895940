import { ListViewBody } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  invitationStatus: string | undefined;
};

export const InvitationStatus: FC<Props> = memo(({ invitationStatus }) => {
  if (!invitationStatus) {
    return null;
  }

  return <ListViewBody key='right'>{invitationStatus}</ListViewBody>;
});
InvitationStatus.displayName = 'InvitationStatus';
