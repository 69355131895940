import { ChangeEvent, forwardRef, useCallback, useRef } from 'react';

type FileInputProps = {
  onChange: (file: File | null) => void;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ onChange }, ref) => {
    const onFileInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event?.target?.files?.[0] || null);
        event.target.value = '';
      },
      [onChange]
    );
    return <input type='file' hidden ref={ref} onChange={onFileInputChange} />;
  }
);
FileInput.displayName = 'FileInput';

export type FileSelected = {
  click: VoidFunction;
};

export const useNewFile = (
  onSelected: (file: File | null) => void,
  onConfirm?: (input: FileSelected | null) => void,
  input?: HTMLInputElement | null
) => {
  const ref = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    const inputEle = input ? input : ref.current!;
    if (onConfirm) {
      onConfirm(inputEle);
    } else {
      inputEle.click();
    }
  }, [input, onConfirm]);
  return {
    onClick,
    FileInput: () => <FileInput ref={ref} onChange={onSelected} />,
  };
};
