import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import { FormRadioButton } from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';

export type TCounterpartyInvoiceNumberRegisterTypeProps = {
  control: Control<CounterpartyForm>;
};
export const InvoiceNumberRegisterType: FC<TCounterpartyInvoiceNumberRegisterTypeProps> =
  memo(({ control }) => {
    const { t } = useTranslation();
    const radioButtonOptions = useMemo(
      () => [
        {
          id: 'registered',
          label: t('registered'),
          value: 'registered',
        },
        {
          id: 'not_registered',
          label: t('not_registered'),
          value: 'not_registered',
        },
      ],
      [t]
    );
    return (
      <>
        <FormRadioButton<CounterpartyForm>
          key='right'
          options={radioButtonOptions}
          control={control}
          name='register_type'
          inputId='register_type'
          testId='register_type'
        />
      </>
    );
  });
InvoiceNumberRegisterType.displayName = 'InvoiceNumberRegisterType';
