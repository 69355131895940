import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { useEmptyState } from '@/components/RelationActionMenu/Invoice/Dialog/Body/hooks';
import {
  EmptyPreview,
  MainPreview,
} from '@/components/RelationActionMenu/Invoice/Dialog/Preview';
import {
  SelectedInvoice,
  SelectedPayee,
} from '@/components/RelationActionMenu/Invoice/type';
import { SearchableWithPage } from '@/components/RelationActionMenu/type';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { useReceivedInvoicesSearchWithReportableAndWithIncludeMfFile } from '@/context/services/invoice/ReceivedInvoice.service';
import { ReceivedInvoicesQuery } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import {
  DATE_FORMAT,
  InlineNotificationProps,
  RelationDialogBody,
} from '@moneyforward/ap-frontend-components';
import type { ColumnType } from 'antd/es/table/interface';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import styles from './Body.module.scss';
import { Search, SearchInput } from './Search';

const cx = classnames.bind(styles);

type Props = {
  setSelectedFile: (value: File | SelectedInvoice | null) => void;
  /**
   * @deprecated
   */
  payeeId?: string;
  payee?: SelectedPayee;
};

type Record = {
  id: string;
  payee: string | undefined;
  uploadedAt: string;
  invoiceId: string;
  fileName: string;
  mfFileId: string;
};

const InnerDialogBody: FC<Props> = memo(
  ({ setSelectedFile, payeeId, payee }) => {
    const { t } = useTranslation();
    const perPage = 10;
    const [selectedMfFile, setSelectedMfFile] = useState<
      { id: string; fileName: string; invoiceId: string } | undefined
    >(undefined);
    const columns = useColumns<Record>();
    const [query, setQuery] = useState<SearchableWithPage<SearchInput>>({
      apPayee: payeeId,
      keyword: undefined,
      uploadedAt: undefined,
    });
    const onSearch = useCallback((input: SearchableWithPage<SearchInput>) => {
      setQuery({ ...input });
    }, []);
    const searchQuery = useMemo(() => {
      const q: ReceivedInvoicesQuery = {
        ap_payee_id_in: [query.apPayee].filter((v): v is string => Boolean(v)),
        searchable_number_or_filename_cont: query.keyword,
        uploaded_at_gteq: query?.uploadedAt?.[0],
        uploaded_at_lteq: query?.uploadedAt?.[1],
      };
      return q;
    }, [query]);

    const onPageChange = useCallback(
      (page: number) => {
        setQuery({ ...query, page });
      },
      [query]
    );

    const { data, pagination, isLoading, isError } =
      useReceivedInvoicesSearchWithReportableAndWithIncludeMfFile({
        page: query.page,
        perPage,
        query: searchQuery,
        onPageChange,
      });

    const invoiceData: Record[] = useMemo(
      () =>
        data?.map(
          (item): Record => ({
            id: `IV-${item.id}`,
            fileName: item.mfFile.name,
            payee: item.payeeName,
            uploadedAt: dayjs(item.uploadedAt).format(DATE_FORMAT),
            invoiceId: item.id,
            mfFileId: item.mfFile.id,
          })
        ) ?? [],
      [data]
    );
    const { emptyType, emptyMessage } = useEmptyState(data, isLoading, isError);
    const notification: InlineNotificationProps = useMemo(
      () => ({
        visible: isError,
        type: 'error',
        message: t('system_error_with_dialog', { resource: t('invoice') }),
      }),
      [isError, t]
    );

    return (
      <RelationDialogBody<Record>
        columns={columns}
        data={invoiceData}
        pagination={pagination}
        pdfViewer={
          selectedMfFile ? (
            <MainPreview
              mfFileId={selectedMfFile.id}
              fileName={selectedMfFile.fileName}
              invoiceId={selectedMfFile.invoiceId}
              setSelectedFile={setSelectedFile}
            />
          ) : (
            <EmptyPreview />
          )
        }
        rowKey='id'
        rowMode='radio'
        searchComponents={
          <Search onSearch={onSearch} payeeId={payeeId} payee={payee} />
        }
        tableTitle={t('received_invoices_uploaded')}
        onSelectedRows={({ invoiceId, mfFileId, fileName }: Record) => {
          setSelectedMfFile({ id: mfFileId, fileName, invoiceId: invoiceId });
        }}
        contentContainerClassName={cx(styles['contentContainer'])}
        isLoading={isLoading}
        emptyType={emptyType}
        emptyText={emptyMessage}
        notification={notification}
      />
    );
  }
);
InnerDialogBody.displayName = 'InnerDialogBody';

export const DialogBody: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary>
      <InnerDialogBody {...props} />
    </SuspenseErrorBoundary>
  );
});
DialogBody.displayName = 'DialogBody';

const useColumns = <T,>(): ColumnType<T>[] => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        dataIndex: 'id',
        title: t('インボイスID'),
      },
      {
        dataIndex: 'fileName',
        title: t('ファイル名'),
        render: (value: string) => {
          return (
            <FileNameSeparatorWithExtension>
              {value}
            </FileNameSeparatorWithExtension>
          );
        },
      },
      {
        dataIndex: 'payee',
        title: t('label_ap_payee'),
      },
      {
        dataIndex: 'uploadedAt',
        title: t('アップロード日'),
      },
    ],
    [t]
  );
};
