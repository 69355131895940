import { ModalContainer } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Loading,
  Modal,
  TextLink,
} from '@moneyforward/ap-frontend-components';
import { FC, Suspense, memo, useCallback, useState } from 'react';

export type ModalProps = {
  apPayeeId: string;
  open: boolean;
  onClose: VoidFunction;
};

export const DetailModal: FC<ModalProps> = memo(
  ({ apPayeeId, open, onClose }) => {
    const containerRef = useGlobalContainerRef();

    const { t } = useTranslation();

    if (!open) {
      return null;
    }

    return (
      <Modal
        open={open}
        onClose={onClose}
        getContainer={containerRef}
        title={t('ap_payee')}
        size='lg'
        footer={
          <ButtonGroup>
            <ButtonV2 size='sm' onClick={onClose} isTertiary>
              {t('close')}
            </ButtonV2>
          </ButtonGroup>
        }
      >
        <ModalContainer apPayeeId={apPayeeId} />
      </Modal>
    );
  }
);
DetailModal.displayName = 'DetailModal';

export type Props = {
  apPayeeId: string;
  apPayeeName: string;
};

export const PayeeDetailModal: FC<Props> = memo(
  ({ apPayeeId, apPayeeName }) => {
    const [open, setOpen] = useState(false);

    const onOpen = useCallback(() => {
      setOpen(true);
    }, []);

    const onClose = useCallback(() => {
      setOpen(false);
    }, []);

    return (
      <>
        <TextLink onClick={onOpen} href='#'>
          {apPayeeName}
        </TextLink>
        <Suspense
          fallback={<Loading isOverlay size='xlarge' color='default' />}
        >
          <DetailModal apPayeeId={apPayeeId} open={open} onClose={onClose} />
        </Suspense>
      </>
    );
  }
);

PayeeDetailModal.displayName = 'PayeeDetailModal';
