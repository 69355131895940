import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

type LoadingContextType = {
  isTpmFetching: boolean;
  setTpmFetching: (loading: boolean) => void;
};

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const TpmInvoiceLoadingProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isTpmFetching, setTpmFetching] = useState(false);

  const loadingContextValue = useMemo(
    () => ({ isTpmFetching, setTpmFetching }),
    [isTpmFetching, setTpmFetching]
  );

  return (
    <LoadingContext.Provider value={loadingContextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useTpmInvoiceLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error(
      'useTpmInvoiceLoading must be used within a TpmInvoiceLoadingProvider'
    );
  }
  return context;
};
