import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import parser from 'html-react-parser';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TPaymentClosingDateProps = {
  control: Control<ApPayeeForm>;
};

export const PaymentClosingDate: FC<TPaymentClosingDateProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();

    const inputRules = useMemo(
      () => ({
        required: t('required'),
      }),
      [t]
    );

    return (
      <>
        <Left>
          <Label
            required
            text={t('payment_closing_date_label')}
            tooltip={parser(t('payment_closing_date_tooltip'))}
            htmlFor='payment_closing_date'
          />
        </Left>
        <Right>
          <div className={cx(styles['form-text-row'])}>
            <div className={cx(styles['form-text-nowrap'])}>
              {t('payment_closing_date_before')}
            </div>
            <FormInput<ApPayeeForm>
              key='right'
              control={control}
              name='payment_closing_date'
              inputId='payment_closing_date'
              rules={inputRules}
            />
            <div>{t('payment_closing_date_after')}</div>
          </div>
        </Right>
      </>
    );
  }
);
PaymentClosingDate.displayName = 'PaymentClosingDate';
