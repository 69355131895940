import { PayeeDetail } from '@/context/services/ap_payee/type';
import { Payee } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo/components/Payee';
import { PersonInCharge } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo/components/PersonInCharge';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  apPayee: PayeeDetail;
};

export const DefaultInfo: FC<Props> = memo(({ apPayee }) => {
  return (
    <div className={cx(styles['container'])}>
      <Payee displayName={apPayee.displayName} />
      <PersonInCharge
        contactPersonName={apPayee.contactPersonName}
        contactPersonEmail={apPayee.contactPersonEmail}
        contactOfficeMemberNames={apPayee.contactOfficeMemberNames}
      />
    </div>
  );
});
DefaultInfo.displayName = 'DefaultInfo';
