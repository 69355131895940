import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';

export type TCounterpartyIndividualNumberProps = {
  control: Control<CounterpartyForm>;
};
export const CounterpartyIndividualNumber: FC<TCounterpartyIndividualNumberProps> =
  memo(({ control }) => {
    const { t } = useTranslation();

    return (
      <>
        <Left>
          <Label
            tooltip={t('counterparty_individual_number_tooltip')}
            text={t('counterparty_individual_number')}
            htmlFor='individual_number'
          />
        </Left>
        <Right>
          <FormInput<CounterpartyForm>
            control={control}
            name='individual_number'
            inputId='individual_number'
          />
        </Right>
      </>
    );
  });
CounterpartyIndividualNumber.displayName = 'CounterpartyIndividualNumber';
