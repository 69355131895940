import { CopyButton } from '@/components/CopyButton';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  invitationUrl: string | undefined;
};

export const PublishedUrl: FC<Props> = memo(({ invitationUrl }) => {
  const { t } = useTranslation();
  if (!invitationUrl) {
    return null;
  }

  return (
    <ListViewBody key='right' className={cx(styles['copy-wrap'])}>
      <div>{invitationUrl} </div>
      <CopyButton
        show
        copyTooltip={t('ap_payee_web_receive_account_url_copy_text')}
        copiedTooltip={t('ap_payee_web_receive_copied_text')}
        text={`${invitationUrl}`}
      />
    </ListViewBody>
  );
});
PublishedUrl.displayName = 'PublishedUrl';
