import { SelectedInvoice } from '@/components/RelationActionMenu/Invoice/type';
import { useEffect, useState } from 'react';

export const useSelectedInvoice = (open: boolean) => {
  const [selectedFile, setSelectedFile] = useState<
    File | SelectedInvoice | null
  >(null);
  useEffect(() => {
    if (!open) {
      setSelectedFile(null);
    }
  }, [open]);
  return {
    selectedFile,
    setSelectedFile,
  };
};
