import { IconApproval } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC } from 'react';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type Props = {
  type?: 'disabled' | 'success' | 'error';
};

export const Approval: FC<Props> = ({ type = 'disabled' }) => {
  return (
    <div className={cx(styles['container'], type !== 'error' && styles[type])}>
      <IconApproval size={16} />
    </div>
  );
};
