import { PAYEE_INVOICE_RECEIVE_SUPPORT_SITE } from '@/consts/guideLine';
import { PayeeDetail } from '@/context/services/ap_payee/type';
import { OfficeMemberName } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/MailReceive/OfficeMemberName';
import { ReceiveInvoiceMailAddress } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/MailReceive/ReceiveInvoiceMailAddress';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  Heading,
  Label,
  ListView,
  ListViewBody,
  ListViewLabel,
  Single,
  TextLink,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  apPayee: PayeeDetail;
};

export const MailReceive: FC<Props> = memo(({ apPayee }) => {
  const { t } = useTranslation();
  const isEmailSetting = apPayee.isInvoiceReceiveEmailSetting;
  const isEmailProcessType = apPayee.invoiceReceiveEmailProcessType;
  const contactOfficeMemberName = apPayee.contactOfficeMemberName;
  const invoiceReceiveEmailAddress = apPayee.invoiceReceiveEmailAddress;

  const emailProcessTypeLabel = useMemo(() => {
    if (isEmailProcessType === 'received_invoice') {
      return t('ap_payee_mail_receive_received_invoice');
    }
    return t('ap_payee_mail_receive_payment_request_draft');
  }, [isEmailProcessType, t]);

  const contactOfficeMemberNameComponent = useMemo(() => {
    if (isEmailProcessType === 'payment_request') {
      return (
        <Top>
          <Label
            key='left'
            tooltip={t(
              'ap_payee_mail_receive_contact_office_member_name_tooltip'
            )}
            labelContentClassName={cx(styles['tooltip-label'])}
          >
            {t('ap_payee_mail_receive_contact_office_member_name')}
          </Label>
          <OfficeMemberName contactOfficeMemberName={contactOfficeMemberName} />
        </Top>
      );
    }
    return null;
  }, [isEmailProcessType, contactOfficeMemberName, t]);

  const invoiceReceiveEmailAddressComponent = useMemo(() => {
    if (
      isEmailProcessType === 'received_invoice' ||
      (isEmailProcessType === 'payment_request' && contactOfficeMemberName)
    ) {
      return (
        <ReceiveInvoiceMailAddress
          invoiceReceiveEmailAddress={invoiceReceiveEmailAddress}
        />
      );
    }
    return (
      <ListViewBody key='right'>
        {t('ap_payee_mail_receive_ocr_email_address_required')}
      </ListViewBody>
    );
  }, [
    isEmailProcessType,
    contactOfficeMemberName,
    invoiceReceiveEmailAddress,
    t,
  ]);

  return (
    <section>
      <Heading as='h3' className={cx(styles['section-title'])}>
        {t('ap_payee_mail_receive')}
      </Heading>
      {isEmailSetting ? (
        <ListView>
          <Top>
            <ListViewLabel key='left'>
              {t('ap_payee_mail_receive_invoice_storage_location')}
            </ListViewLabel>
            <ListViewBody key='right'>{emailProcessTypeLabel}</ListViewBody>
          </Top>
          {contactOfficeMemberNameComponent}
          <Bottom>
            <ListViewLabel key='left'>
              {t('ap_payee_mail_receive_invoice_receive_email_address')}
            </ListViewLabel>
            {invoiceReceiveEmailAddressComponent}
          </Bottom>
        </ListView>
      ) : (
        <>
          <div className={cx(styles['mail-receive-description'])}>
            {t('ap_payee_mail_receive_description')}
            <TextLink target='_blank' href={PAYEE_INVOICE_RECEIVE_SUPPORT_SITE}>
              {t('support_site')}
            </TextLink>
          </div>
          <ListView>
            <Single>
              <ListViewLabel key='left'>
                {t('ap_payee_mail_receive_invoice_receive_email_address')}
              </ListViewLabel>
              <ReceiveInvoiceMailAddress
                invoiceReceiveEmailAddress={invoiceReceiveEmailAddress}
              />
            </Single>
          </ListView>
        </>
      )}
    </section>
  );
});
MailReceive.displayName = 'MailReceive';
