import { useCreateApCounterpartySubmit } from '@/context/services/ap_counterparty/ApCounterparty.service';
import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { ApCounterpartyItem } from 'ap-openapi';
import { useCallback } from 'react';

export const useSubmit = (
  setError: (error: Error) => void,
  _onCallback: ((data: ApCounterpartyItem) => void) | undefined,
  handleResetForm: () => void
) => {
  const onServiceCall = useCreateApCounterpartySubmit();
  return useCallback(
    async (data: CounterpartyForm) => {
      try {
        const results = await onServiceCall(data);
        if (results) {
          if (_onCallback) {
            handleResetForm();
            _onCallback(results.data);
          } else {
            location = results.redirectPath;
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        }
      }
    },
    [_onCallback, handleResetForm, onServiceCall, setError]
  );
};
