import { SupplierUploadSetting } from '@/context/services/ap_payee/type';
import { InvitationStatus } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/RegisteredAccount/InvitationStatus';
import { TenantName } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/RegisteredAccount/TenantName';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  ListView,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  supplierUploadSetting: SupplierUploadSetting;
  isActive: boolean;
};

export const RegisteredAccount: FC<Props> = memo(
  ({ supplierUploadSetting, isActive }) => {
    const { t } = useTranslation();

    return (
      <ListView>
        <Top>
          <ListViewLabel key='left'>
            {t('ap_payee_web_receive_invitation_status')}
          </ListViewLabel>
          <InvitationStatus
            invitationStatus={supplierUploadSetting.invitationStatus}
          />
        </Top>
        <Bottom>
          <ListViewLabel key='left'>
            {t('ap_payee_web_receive_register_office_name')}
          </ListViewLabel>
          <TenantName
            tenantName={supplierUploadSetting.tenantName}
            isActive={isActive}
          />
        </Bottom>
      </ListView>
    );
  }
);
RegisteredAccount.displayName = 'RegisteredAccount';
