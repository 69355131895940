import { AgentReceiveSetting } from '@/context/services/ap_payee/type';
import { Destination } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/AgentReceive/Destination';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import {
  Heading,
  ListView,
  ListViewLabel,
  Single,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  agentReceiveSetting: AgentReceiveSetting | undefined;
};

export const AgentReceive: FC<Props> = memo(({ agentReceiveSetting }) => {
  const { t } = useTranslation();

  if (!agentReceiveSetting) {
    return null;
  }

  return (
    <section>
      <Heading as='h3' className={cx(styles['section-title'])}>
        {t('ap_payee_agent_receive')}
      </Heading>
      <ListView>
        <Single>
          <ListViewLabel key='left'>
            {t('ap_payee_agent_receive_destination')}
          </ListViewLabel>
          <Destination
            address={agentReceiveSetting.address}
            destination={agentReceiveSetting.destination}
            uniqueCode={agentReceiveSetting.uniqueCode}
          />
        </Single>
      </ListView>
    </section>
  );
});
AgentReceive.displayName = 'AgentReceive';
