import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import { useTranslation } from '@/i18n';
import { TableEmptyType } from '@moneyforward/ap-frontend-components';
import parser from 'html-react-parser';
import { ReactNode, useEffect, useState } from 'react';

export const useEmptyState = <T>(
  data: Array<T>,
  isLoading: boolean,
  isError: boolean,
  resource: 'business_document' | 'invoice' = 'invoice'
) => {
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const [emptyType, setEmptyType] = useState<TableEmptyType | undefined>(
    undefined
  );
  const [emptyMessage, setEmptyMessage] = useState<ReactNode | undefined>(
    undefined
  );
  useEffect(() => {
    if (!isLoading) {
      // エラーの場合は早期リターン
      if (isError) {
        setEmptyType('error');
        setEmptyMessage(undefined);
        return;
      }
      if (!isOnline) {
        setEmptyType('error');
        setEmptyMessage(parser(t('system_error_network')));
      } else if (data.length === 0) {
        setEmptyType('search');
        setEmptyMessage(
          resource === 'invoice'
            ? parser(t('notfound_search_message'))
            : parser(t('business_document.notfound_search_message'))
        );
      } else {
        setEmptyMessage(undefined);
      }
    }
  }, [data.length, isError, isLoading, t, resource, isOnline]);

  return {
    emptyType,
    emptyMessage,
  };
};
