import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';

export type TCounterpartyCodeProps = {
  control: Control<CounterpartyForm>;
};
export const CounterpartyCode: FC<TCounterpartyCodeProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();
    const codeInputProps = useMemo(
      () => ({
        placeholder: t('max_50_letters'),
      }),
      [t]
    );
    const requiredRules = useMemo(
      () => ({
        required: t('required'),
      }),
      [t]
    );
    return (
      <>
        <Left>
          <Label
            required
            text={t('counterparty_code')}
            testId='code'
            htmlFor='code'
          />
        </Left>
        <Right>
          <FormInput<CounterpartyForm>
            control={control}
            name='code'
            inputId='code'
            testId='code'
            inputProps={codeInputProps}
            rules={requiredRules}
          />
        </Right>
      </>
    );
  }
);
CounterpartyCode.displayName = 'CounterpartyCode';
