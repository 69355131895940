import { useTranslation } from '@/i18n';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export const ExpiredURL: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <ListViewBody key='right'>
      {t('ap_payee_web_receive_account_url_expired')}
    </ListViewBody>
  );
});
ExpiredURL.displayName = 'ExpiredURL';
