import { r2wc } from '@/wc/helper/render';
import { routes } from '@generouted/react-router';
import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const Routes: FC = () => (
  <RouterProvider router={createBrowserRouter(routes)} />
);
Routes.displayName = 'account-payable';

export const AccountPayable = r2wc(Routes, {
  shadow: 'open',
});

export const AccountPayablePage: FC = () => {
  return <account-payable />;
};
