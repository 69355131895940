import { useBusinessPersonData } from '@/context/BusinessPersonData';
import { useTpmInvoiceLoading } from '@/context/TpmInvoiceLoading';
import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInputInvoiceRegistrationNumber,
  Label,
  Left,
  ListViewBody,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useEffect, useMemo } from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import { InvoiceNumberRegisterType } from '../InvoiceNumberRegisterType';
import { useInvoiceRegistrationNumberHooks } from './hooks';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type TInvoiceRegistrationNumberProps = {
  control: Control<CounterpartyForm>;
};

export const InvoiceRegistrationNumber: FC<TInvoiceRegistrationNumberProps> =
  memo(({ control }) => {
    const { t } = useTranslation();
    const { businessPersonData } = useBusinessPersonData();
    const { setTpmFetching } = useTpmInvoiceLoading();
    const invoiceRegisterType = useWatch({
      control,
      name: 'register_type',
    });
    const isRegistered = invoiceRegisterType === 'registered';
    const { callBackAfterInput13text, isLoading } =
      useInvoiceRegistrationNumberHooks();
    setTpmFetching(isLoading);
    const { setValue } = useFormContext<CounterpartyForm>();

    const invoiceRegistrationNumberFormName = 'invoice_registration_number';

    useEffect(() => {
      setValue(invoiceRegistrationNumberFormName, '', {
        shouldValidate: true,
      });
    }, [invoiceRegisterType, invoiceRegistrationNumberFormName, setValue]);

    const registerNumberInputProps = useMemo(
      () => ({
        maxLength: 13,
        placeholder: t('enter_13_digit_number'),
        disabled: !isRegistered,
        required: isRegistered,
      }),
      [t, isRegistered]
    );

    const businessPersonDataProps = useMemo(() => {
      return {
        ...businessPersonData,
        disabled:
          isRegistered && !businessPersonData?.companyType
            ? true
            : !isRegistered,
      };
    }, [businessPersonData, isRegistered]);

    return (
      <>
        <Left>
          <Label
            text={t('invoice_registration_number')}
            tooltip={t('invoice_registration_number_tooltip')}
            htmlFor={invoiceRegistrationNumberFormName}
          />
        </Left>
        <Right>
          <ListViewBody>
            <InvoiceNumberRegisterType control={control} />
            <div className={cx(styles['invoice-input-wrap'])}>
              <FormInputInvoiceRegistrationNumber<CounterpartyForm>
                control={control}
                inputId={invoiceRegistrationNumberFormName}
                name={invoiceRegistrationNumberFormName}
                inputProps={registerNumberInputProps}
                businessPersonData={businessPersonDataProps}
                callbackOnInput13Text={callBackAfterInput13text}
                withPopover
              />
            </div>
            <p className={cx(styles['invoice-form-text'])}>
              {t('invoice_registration_number_description')}
            </p>
          </ListViewBody>
        </Right>
      </>
    );
  });
InvoiceRegistrationNumber.displayName = 'InvoiceRegistrationNumber';
