import { faker } from '@faker-js/faker';
import { HttpResponse, delay, http } from 'msw';

faker.seed(1);

export const getGetApStamplessContractsMock = () => {
  return {
    next: faker.helpers.arrayElement([
      faker.helpers.arrayElement([faker.word.sample(), null]),
      undefined,
    ]),
    prev: faker.helpers.arrayElement([
      faker.helpers.arrayElement([faker.word.sample(), null]),
      undefined,
    ]),
    ap_stampless_contracts: Array.from({ length: 10 }, () => {
      return {
        contract_name:
          'AP-' +
          faker.number
            .int({ min: 1000000000000, max: 9999999999999 })
            .toString(),
        contract_number: 'AP-' + faker.string.uuid(),
        contract_partners: [
          {
            name: '株式会社マネーフォワード',
          },
        ],
      };
    }),
  };
};

export const handlers = [
  http.get('*/api/js/ap_stampless_contracts', async () => {
    await delay(1000);

    return new HttpResponse(JSON.stringify(getGetApStamplessContractsMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
