import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import { SendErrorTracking } from '@/utils/errors';
import { useGetApCounterpartiesSuspense } from 'ap-openapi';
import { useCallback } from 'react';
import { useCreateApCounterparty } from './CreateApCounterparty.service';

export const useGetApCounterparties = (query?: string) => {
  const { data, error } = useGetApCounterpartiesSuspense({ query: query });
  if (error) throw error;
  return data?.data;
};

export const useCreateApCounterpartySubmit = () => {
  const { t } = useTranslation();
  const { mutateAsync } = useCreateApCounterparty();
  return useCallback(
    async (apCounterparty: CounterpartyForm) => {
      if (apCounterparty.register_type === 'not_registered') {
        apCounterparty.invoice_registration_number = undefined;
      }
      try {
        const { status, redirectPath, errors, data } = await mutateAsync({
          data: { ap_counterparty: apCounterparty },
        });
        if (status === 'success' && data) {
          return {
            data,
            redirectPath,
          };
        } else {
          if (errors?.messages) {
            throw new Error(errors.messages.join(''));
          }
        }
      } catch (error) {
        let message;
        if (error instanceof Error) {
          message = error.message;
        } else {
          message = t('system_error');
        }
        throw new SendErrorTracking(message);
      }
    },
    [mutateAsync, t]
  );
};
