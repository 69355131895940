import {
  EmptyPreview,
  MainPreview,
} from '@/components/RelationActionMenu/BusinessDocument/Dialog/Preview';
import {
  MaxSelectedCount,
  SelectedBusinessDocument,
} from '@/components/RelationActionMenu/BusinessDocument/type';
import { useEmptyState } from '@/components/RelationActionMenu/Invoice/Dialog/Body/hooks';
import { SearchableWithPage } from '@/components/RelationActionMenu/type';
import { SuspenseErrorBoundary } from '@/components/SuspenseErrorBoundary';
import { useBusinessDocumentSearchWithMfFile } from '@/context/services/business_document/BusinessDocument.service';
import { BusinessDocumentsSearchQuery } from '@/context/services/business_document/type';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import { useTranslation } from '@/i18n';
import {
  DATE_FORMAT,
  InlineNotificationProps,
  RelationDialogBody,
} from '@moneyforward/ap-frontend-components';
import { ColumnType } from 'antd/es/table/interface';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import styles from './Body.module.scss';
import { Search, SearchInput } from './Search';

const cx = classnames.bind(styles);

type Props = {
  onSelectedItems: (value: SelectedBusinessDocument[]) => void;
  selectedItems?: SelectedBusinessDocument[];
};

type Record = {
  id: string;
  searchableNumber: number;
  businessDocumentId: string;
  fileName: string;
  documentTitle: string;
  counterpartyName: string;
  mfFileId: string;
  uploadedAt: string;
};

const perPage = 10;
const InnerDialogBody: FC<Props> = memo((props) => {
  const { selectedItems: _selectedItems, onSelectedItems } = props;
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const [selectedMfFile, setSelectedMfFile] = useState<
    | { id: string; businessDocumentId: string; documentTitle: string }
    | undefined
  >(undefined);
  const columns = useColumns<Record>();
  const [query, setQuery] = useState<SearchableWithPage<SearchInput>>({
    keyword_cont: undefined,
    uploadedAt: undefined,
  });
  const onSearch = useCallback((input: SearchableWithPage<SearchInput>) => {
    setQuery(input);
  }, []);
  const searchQuery = useMemo<BusinessDocumentsSearchQuery>(
    () => ({
      keyword_cont: query?.keyword_cont || undefined,
      uploaded_at_gteq: query?.uploadedAt?.[0] || undefined,
      uploaded_at_lteq: query?.uploadedAt?.[1] || undefined,
    }),
    [query]
  );
  const onPageChange = useCallback(
    (page: number) => {
      setQuery({ ...query, page });
    },
    [query]
  );
  const { data, pagination, isLoading, isError } =
    useBusinessDocumentSearchWithMfFile({
      page: query.page,
      perPage,
      query: searchQuery,
      onPageChange,
    });
  const businessDocumentData: Record[] = useMemo(
    () =>
      data.map(
        (item): Record => ({
          id: `Doc-${item.searchable_number}`,
          searchableNumber: item.searchable_number,
          businessDocumentId: item.id,
          documentTitle: item.document_title,
          counterpartyName: item.counterparty_name,
          mfFileId: item.mfFile.id,
          fileName: item.mfFile.name,
          uploadedAt: dayjs(item.uploaded_at).format(DATE_FORMAT),
        })
      ) ?? [],
    [data]
  );
  const selectedItems = useMemo(
    () => _selectedItems?.map((item) => `Doc-${item.searchableNumber}`) ?? [],
    [_selectedItems]
  );
  const { emptyType, emptyMessage } = useEmptyState(
    data,
    isLoading,
    isError,
    'business_document'
  );
  const notification = useMemo<InlineNotificationProps>(
    () => ({
      visible: isError || !isOnline,
      type: 'error',
      message: t('system_error_with_dialog', {
        resource: t('business_document'),
      }),
    }),
    [isError, t, isOnline]
  );
  const dataMap = useMemo(
    () =>
      new Map(
        _selectedItems?.map((item) => [`Doc-${item.searchableNumber}`, item])
      ),
    [_selectedItems]
  );
  const handleSelectedRows = useCallback(
    (records: Record[]) => {
      const fullSelectedRecords = records
        .map((record) =>
          record.businessDocumentId ? record : dataMap.get(record.id)
        )
        .filter((record): record is Record => record !== undefined);

      const selectedBusinessDocuments: SelectedBusinessDocument[] =
        fullSelectedRecords.map((record) => ({
          businessDocumentId: record.businessDocumentId,
          searchableNumber: record.searchableNumber,
          documentTitle: record.documentTitle,
          mfFileId: record.mfFileId,
        }));

      onSelectedItems(selectedBusinessDocuments);
    },
    [onSelectedItems, dataMap]
  );
  const handleSelectedRow = useCallback(
    ({ mfFileId, businessDocumentId, documentTitle }: Record) => {
      setSelectedMfFile({ id: mfFileId, businessDocumentId, documentTitle });
    },
    []
  );

  return (
    <RelationDialogBody<Record>
      columns={columns}
      data={businessDocumentData}
      pagination={pagination}
      selectedItems={selectedItems}
      pdfViewer={
        selectedMfFile ? (
          <MainPreview mfFileId={selectedMfFile.id} />
        ) : isOnline ? (
          <EmptyPreview />
        ) : (
          <div />
        )
      }
      rowKey='id'
      rowMode='checkbox'
      searchComponents={<Search onSearch={onSearch} />}
      tableTitle={t(
        'relation_action.business_document.business_document_table_title'
      )}
      onSelectedRows={handleSelectedRows}
      onSelectedRow={handleSelectedRow}
      contentContainerClassName={cx(styles['contentContainer'])}
      isLoading={isLoading}
      maxSelectedCount={MaxSelectedCount}
      emptyType={emptyType}
      emptyText={emptyMessage}
      notification={notification}
    />
  );
});
InnerDialogBody.displayName = 'InnerDialogBody';

export const DialogBusinessDocumentBody: FC<Props> = memo((props) => {
  return (
    <SuspenseErrorBoundary>
      <InnerDialogBody {...props} />
    </SuspenseErrorBoundary>
  );
});
DialogBusinessDocumentBody.displayName = 'DialogBusinessDocumentBody';

const useColumns = <T,>(): ColumnType<T>[] => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        dataIndex: 'id',
        title: t('business_document_id'),
      },
      {
        dataIndex: 'documentTitle',
        title: t('business_document_file_name'),
      },
      {
        dataIndex: 'counterpartyName',
        title: t('business_document_counterparty_name'),
      },
      {
        dataIndex: 'uploadedAt',
        title: t('label_uploaded_at'),
      },
    ],
    [t]
  );
};
