import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';

export type TCounterpartyNameProps = {
  control: Control<CounterpartyForm>;
};
export const CounterpartyName: FC<TCounterpartyNameProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();
    const requiredRules = useMemo(
      () => ({
        required: t('required'),
      }),
      [t]
    );
    return (
      <>
        <Left>
          <Label required text={t('counterparty_name')} htmlFor='name' />
        </Left>
        <Right>
          <FormInput<CounterpartyForm>
            control={control}
            name='name'
            inputId='name'
            rules={requiredRules}
          />
        </Right>
      </>
    );
  }
);
CounterpartyName.displayName = 'CounterpartyName';
