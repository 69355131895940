export const featureFlags = (): Record<string, boolean> => {
  const flags = process.env.FEATURE_FLAG ?? {};
  return Object.keys(flags)
    .filter((v) => {
      return v.startsWith('FEATURE_FLAG_');
    })
    .reduce((pre, cur) => {
      const name = cur.replace('FEATURE_FLAG_', '').toUpperCase();
      const flag = (flags[cur] || '').toLowerCase();
      return {
        ...pre,
        [name]: flag === 'true',
      };
    }, {});
};
