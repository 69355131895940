import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { PaymentClosingDate } from '@/features/ap_payees/components/Forms/PaymentClosingDate';
import { PaymentHolidayRule } from '@/features/ap_payees/components/Forms/PaymentHolidayRule';
import { PaymentMonth } from '@/features/ap_payees/components/Forms/PaymentMonth';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  IconExternalLink,
  ListView,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type Props = {};
export type TPayementRuleProps = {
  control: Control<ApPayeeForm>;
};

export const PaymentRule: FC<TPayementRuleProps> = memo(({ control }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={cx(styles['content-title'])}>
        {t('payment_information_title_payment_rule')}
      </div>
      <div className={cx(styles['text-link-wrap'])}>
        <p>{t('payment_rule_description')}</p>
        <a
          href='https://biz.moneyforward.com/support/payable/faq/counterparties-faq/co13.html#imp01'
          target='_blank'
          className={cx(styles['text-link'])}
        >
          {t('payment_rule_text-link')}
          <IconExternalLink />
        </a>
      </div>
      <ListView>
        <Top>
          <PaymentClosingDate control={control} />
        </Top>
        <Top>
          <PaymentMonth control={control} />
        </Top>
        <Bottom>
          <PaymentHolidayRule control={control} />
        </Bottom>
      </ListView>
    </div>
  );
});
PaymentRule.displayName = 'PaymentRule';
