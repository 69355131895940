import { useCallback } from 'react';

export const useResetForm = (
  reset: () => void,
  clearErrors: () => void,
  onClearMessage: () => void
) => {
  return useCallback(() => {
    reset();
    clearErrors();
    onClearMessage();
  }, [clearErrors, onClearMessage, reset]);
};
