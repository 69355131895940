import { TFlagStatus } from '@/components/OpenFeature/Dialog/type';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import { Modal } from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Body } from './Body';
import { Footer } from './Footer';

export type Props = {
  open: boolean;
  onClose: VoidFunction;
  onPrimaryClick: VoidFunction;
  status: TFlagStatus;
  isLoading?: boolean;
};

export const Dialog: FC<Props> = memo(
  ({ status, open, onClose, onPrimaryClick, isLoading }) => {
    const containerRef = useGlobalContainerRef();
    const { t } = useTranslation();
    const message = useMemo(
      () =>
        status === 'old'
          ? t('old_to_new_flag_header')
          : t('new_to_old_flag_header'),
      [status, t]
    );
    return (
      <Modal
        open={open}
        onClose={onClose}
        getContainer={containerRef}
        footer={
          <Footer
            status={status}
            onCancelClick={onClose}
            onPrimaryClick={onPrimaryClick}
            isLoading={isLoading}
          />
        }
        title={message}
        isEscKeyClose={false}
        isOutsideClose={false}
      >
        <Body status={status} />
      </Modal>
    );
  }
);
Dialog.displayName = 'dialog';
