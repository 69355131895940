import { useSelectedInvoice } from '@/components/RelationActionMenu/Invoice/Dialog/hooks';
import { useTranslation } from '@/i18n';
import { RelationDialog } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useCallback } from 'react';
import { SelectedInvoice, SelectedPayee } from '../type';
import { DialogBody } from './Body';
import styles from './Dialog.module.scss';
import { Footer } from './Footer';

const cx = classnames.bind(styles);

export type Props = {
  open: boolean;
  onClose: () => void;
  onSelected: (selectedId: File | SelectedInvoice | null) => void;
  container: HTMLElement | null;
  /**
   * @deprecated
   */
  payeeId?: string;
  payee?: SelectedPayee;
};

export const RelationInvoiceDialog: FC<Props> = memo(
  ({ open, onClose, onSelected: _onSelected, container, payeeId, payee }) => {
    const { t } = useTranslation();
    const { selectedFile, setSelectedFile } = useSelectedInvoice(open);
    const onSelected = useCallback(() => {
      _onSelected(selectedFile);
    }, [_onSelected, selectedFile]);
    return (
      <RelationDialog
        open={open}
        onClose={onClose}
        getContainer={() => container!}
        footer={
          <Footer
            onCancel={onClose}
            onSelected={onSelected}
            disabled={selectedFile === null}
          />
        }
        title={t('received_invoice_dialog_title')}
        modalWrapperClassName={cx(styles['container'])}
        modalMainClassName={cx(styles['content'])}
      >
        {open && (
          <DialogBody
            setSelectedFile={setSelectedFile}
            payeeId={payeeId}
            payee={payee}
          />
        )}
      </RelationDialog>
    );
  }
);
RelationInvoiceDialog.displayName = 'RelationInvoiceDialog';
