import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  contactPersonEmail: string | undefined;
};

export const ContactPersonEmail: FC<Props> = memo(({ contactPersonEmail }) => {
  return (
    <ListViewBody key='right' className={cx(styles['optional-field'])}>
      <div>{contactPersonEmail}</div>
    </ListViewBody>
  );
});
ContactPersonEmail.displayName = 'ContactPersonEmail';
