import { Counterparties, Props } from '@/components/Counterparties';
import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { useTranslation } from '@/i18n';
import {
  IconAdd,
  Label,
  ListView,
  Single,
  TextLink,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TRelateCounterpartyProps = {
  control: Control<ApPayeeForm>;
  onCounterpartyModalOpen: () => void;
  defaultOptions?: Props<ApPayeeForm>['defaultOptions'];
  counterpartyOpen: boolean;
};

export const RelateCounterparty: FC<TRelateCounterpartyProps> = memo(
  ({ control, onCounterpartyModalOpen, defaultOptions, counterpartyOpen }) => {
    const { t } = useTranslation();
    const selectRules = useMemo(
      () => ({
        search: true,
      }),
      []
    );

    return (
      <div className={cx(styles['section-wrap'])}>
        <div className={cx(styles['section-title-button-wrap'])}>
          <div className={cx(styles['section-title'])}>
            {t('relate_counterparty_title')}
          </div>
        </div>
        <ListView>
          <Single>
            <Label
              required
              text={t('ap_payee_counterparty_account')}
              htmlFor='ap_counterparty_id'
            />
            <Counterparties<ApPayeeForm>
              // Key: To reset a search query in Dropdown of Counterparties(Keeping the query make the dropdown show encrypted id when adding a new counterparty)
              key={`ap_counterparty_combobox_${counterpartyOpen}`}
              control={control}
              name='ap_counterparty_id'
              inputId='ap_counterparty_id'
              testId='ap_counterparty_id'
              selectProps={selectRules}
              defaultOptions={defaultOptions}
            />
          </Single>
        </ListView>

        <div className={cx(styles['text-link-wrap-secondary'])}>
          {t('relate_counterparty_info_text')}
          <TextLink onClick={onCounterpartyModalOpen} type='button'>
            <IconAdd height={0} size={16} width={0} />
            {t('add_new_payee_button')}
          </TextLink>
        </div>
      </div>
    );
  }
);

RelateCounterparty.displayName = 'RelateCounterparty';
