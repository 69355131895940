import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { PayeeCode } from '@/features/ap_payees/components/Forms/PayeeCode';
import { PayeeName } from '@/features/ap_payees/components/Forms/PayeeName';
import { PayeePriority } from '@/features/ap_payees/components/Forms/PayeePriority';
import { useTranslation } from '@/i18n';
import { Bottom, ListView, Top } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TPayeeInformationProps = {
  control: Control<ApPayeeForm>;
};

export const PayeeInformation: FC<TPayeeInformationProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();

    return (
      <div className={cx(styles['section-wrap'])}>
        <div>
          <div className={cx(styles['section-title'])}>
            {t('payment_information_title')}
          </div>
        </div>
        <div>
          <div className={cx(styles['content-title'])}>
            {t('payment_information_title_payee')}
          </div>
          <ListView>
            <Top>
              <PayeeCode control={control} />
            </Top>
            <Top>
              <PayeeName control={control} />
            </Top>
            <Bottom>
              <PayeePriority control={control} />
            </Bottom>
          </ListView>
        </div>
      </div>
    );
  }
);
PayeeInformation.displayName = 'PayeeInformation';
