import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';

export type TRepresentativeNameProps = {
  control: Control<CounterpartyForm>;
};

export const RepresentativeName: FC<TRepresentativeNameProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();
    return (
      <>
        <>
          <Left>
            <Label
              text={t('counterparty_representative_name')}
              htmlFor='representative_name'
            />
          </Left>
          <Right>
            <FormInput<CounterpartyForm>
              control={control}
              name='representative_name'
              inputId='representative_name'
            />
          </Right>
        </>
      </>
    );
  }
);
RepresentativeName.displayName = 'RepresentativeName';
