import { FC } from 'react';

type Props = {
  size?: number;
  width?: number;
  height?: number;
};

export const SubmitApplication: FC<Props> = ({
  size,
  width = 17,
  height = 16,
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox='0 0 17 16'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='SubmitApplication'>
      <g id='Symbol'>
        <path
          d='M15.6113 4.44423L12.0557 0.888672H4.0557C3.56478 0.888672 3.16682 1.28664 3.16682 1.77756V4.83217C3.58316 4.59133 4.03001 4.39735 4.50015 4.25744V2.22201H11.1668V5.33312H14.2779L14.2779 13.7776H11.3846C11.033 14.2815 10.6088 14.7311 10.127 15.1109H14.7224C15.2133 15.1109 15.6113 14.7129 15.6113 14.222L15.6113 4.44423Z'
          fill='#747474'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.27793 13.7776C8.24119 13.7776 9.83348 12.1853 9.83348 10.222C9.83348 8.25834 8.24128 6.66645 6.27793 6.66645C4.31417 6.66645 2.72237 8.25825 2.72237 10.222C2.72237 12.1854 4.31426 13.7776 6.27793 13.7776ZM6.27793 15.1109C8.97757 15.1109 11.1668 12.9216 11.1668 10.222C11.1668 7.52187 8.97757 5.33312 6.27793 5.33312C3.57779 5.33312 1.38904 7.52187 1.38904 10.222C1.38904 12.9216 3.57779 15.1109 6.27793 15.1109Z'
          fill='#747474'
        />
        <path
          d='M4.85993 10.6702C4.61236 10.8996 4.2257 10.8849 3.99632 10.6373C3.76694 10.3897 3.78168 10.0031 4.02926 9.77367L6.27793 7.78006L8.5266 9.77367C8.77418 10.0031 8.78893 10.3897 8.55954 10.6373C8.33016 10.8849 7.9435 10.8996 7.69593 10.6702L6.88904 10.0124V12.0553C6.88904 12.3928 6.61543 12.6664 6.27793 12.6664C5.94042 12.6664 5.66682 12.3928 5.66682 12.0553V10.0125L4.85993 10.6702Z'
          fill='#747474'
        />
      </g>
    </g>
  </svg>
);
