import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  contactOfficeMemberName: string | undefined;
};

export const OfficeMemberName: FC<Props> = memo(
  ({ contactOfficeMemberName }) => {
    return (
      <ListViewBody key='right' className={cx(styles['optional-field'])}>
        {contactOfficeMemberName}
      </ListViewBody>
    );
  }
);
OfficeMemberName.displayName = 'OfficeMemberName';
