import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormCheckbox,
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TAddressProps = {
  control: Control<CounterpartyForm>;
};

export type Props = {};

export const Address: FC<TAddressProps> = memo(({ control }) => {
  const { t } = useTranslation();
  return (
    <>
      <>
        <Left>
          <Label text={t('counterparty_address')} htmlFor='address' />
        </Left>
        <Right>
          <div className={cx(styles['right-content'])}>
            <div>
              <FormInput<CounterpartyForm>
                control={control}
                name='address'
                inputId='address'
              />
            </div>
            <FormCheckbox<CounterpartyForm>
              control={control}
              name='is_domestic'
              inputId='is_domestic'
              testId='is_domestic'
              label={t('counterparty_address_domestic')}
            />
          </div>
        </Right>
      </>
    </>
  );
});
Address.displayName = 'Address';
