import { TextLink as CompTextLink } from '@/components/TextLink';
import { r2wc } from '@/wc/helper/render';

export const TextLink = r2wc(CompTextLink, {
  shadow: 'open',
  props: {
    fileName: 'string',
    href: 'string',
    newWindow: 'boolean',
    containerClassName: 'string',
    textClassName: 'string',
    extensionClassName: 'string',
    containerMaxWidth: 'string',
    allowedFileTypes: 'string',
  },
});
