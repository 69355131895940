import { useGetApPayeesDetail } from '@/context/services/ap_payee/ApPayee.service';
import { DefaultInfo } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo';
import { ReceiveInvoice } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

import { FC, memo } from 'react';

export type Props = {
  apPayeeId: string;
};

export const ModalContainer: FC<Props> = memo(({ apPayeeId }) => {
  const result = useGetApPayeesDetail(apPayeeId);

  return (
    <>
      <DefaultInfo apPayee={result} />
      <hr className={cx(styles['container-line'])} />
      <ReceiveInvoice apPayee={result} />
    </>
  );
});
ModalContainer.displayName = 'ModalContainer';
