import { CopyButton } from '@/components/CopyButton';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import { ListViewBody } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  invoiceReceiveEmailAddress: string | undefined;
};

export const ReceiveInvoiceMailAddress: FC<Props> = memo(
  ({ invoiceReceiveEmailAddress }) => {
    const { t } = useTranslation();

    return (
      <ListViewBody
        key='right'
        className={cx(styles['optional-field'], styles['copy-wrap'])}
      >
        {invoiceReceiveEmailAddress}
        <CopyButton
          show
          copyTooltip={t('ap_payee_agent_receive_mail_copy_text')}
          copiedTooltip={t('ap_payee_web_receive_copied_text')}
          text={invoiceReceiveEmailAddress || ''}
        />
      </ListViewBody>
    );
  }
);
ReceiveInvoiceMailAddress.displayName = 'ReceiveInvoiceMailAddress';
