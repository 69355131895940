import { useExistsInvoice } from '@/context/services/invoice/ReceivedInvoice.service';
import { InvoiceColumnWithMfFile } from '@/context/services/invoice/type';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  FormFields,
  FormFieldsProps,
  IconApproval,
  Input,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import styles from './InputInvoiceSearch.module.scss';

const cx = classnames.bind(styles);

type Status = 'none' | 'success' | 'alert' | 'error';

export type Props = {
  onInputSearchClick: (invoice: InvoiceColumnWithMfFile) => void;
};

export const InputInvoiceSearch: FC<Props> = memo(({ onInputSearchClick }) => {
  const { t } = useTranslation();
  const [caption, setCaption] =
    useState<FormFieldsProps['captions']>(undefined);
  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    []
  );
  const [status, setStatus] = useState<Status>('none');
  const [searchableInvoiceId, setSearchableInvoiceId] = useState('');
  const fetchResult = useExistsInvoice(searchableInvoiceId);
  const isSuccess = useMemo(() => status === 'success', [status]);
  const _onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setStatus('none');
      setCaption(undefined);
      setSearchableInvoiceId(e.target.value);
    },
    []
  );
  const onInputChange = useDebouncedCallback(_onInputChange, 600);
  const [selectedInvoice, setSelectedInvoice] = useState<
    InvoiceColumnWithMfFile | undefined
  >(undefined);
  const onClick = useCallback(() => {
    if (selectedInvoice) onInputSearchClick(selectedInvoice);
  }, [onInputSearchClick, selectedInvoice]);
  useEffect(() => {
    if (fetchResult) {
      const { data, error } = fetchResult;
      if (data) {
        setCaption(undefined);
        setStatus('success');
        setSelectedInvoice(data);
        return;
      } else {
        if (error) {
          setCaption({
            error: {
              children: error,
            },
          });
          setStatus('error');
        }
      }
    }
    setSelectedInvoice(undefined);
  }, [fetchResult]);
  return (
    <div className={cx(styles['container'])}>
      <FormFields label={undefined} captions={caption}>
        <div className={cx(styles['inputContainer'])}>
          {isSuccess && (
            <div className={cx(styles['icon'])}>
              <IconApproval size={20} />
            </div>
          )}
          <Input
            status={status === 'error' ? 'error' : undefined}
            onChange={onInputChange}
            onKeyDown={onInputKeyDown}
          />
        </div>
      </FormFields>
      <ButtonV2
        className={cx(styles['button-container'])}
        type='button'
        onClick={onClick}
        size='xs'
        isTransparent
        isDisabled={!selectedInvoice}
      >
        {t('relation_action.input_invoice')}
      </ButtonV2>
    </div>
  );
});
InputInvoiceSearch.displayName = 'InputInvoiceSearch';
