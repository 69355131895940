import { ApPayee } from '@/components/ApPayee';
import { SelectedPayee } from '@/components/RelationActionMenu/Invoice/type';
import { UpdatedAt } from '@/components/UpdatedAt';
import { useTranslation } from '@/i18n';
import {
  Form,
  FormInput,
  InputProps,
} from '@moneyforward/ap-frontend-components';
import { Col, Row } from 'antd';
import classnames from 'classnames/bind';
import { toISODateStrings } from 'date-util';
import { FC, memo, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import styles from './Search.module.scss';

const cx = classnames.bind(styles);

export type SearchInput = {
  apPayee: string | undefined;
  keyword: string | undefined;
  uploadedAt: string[] | undefined;
};

export type Props = {
  onSearch?: (input: SearchInput) => void;
  /**
   * @deprecated
   */
  payeeId?: string;
  payee?: SelectedPayee;
};

export const Search: FC<Props> = memo(({ onSearch, payeeId, payee }) => {
  const { t } = useTranslation();
  const methods = useForm<SearchInput>({
    defaultValues: {
      apPayee: payee?.id || payeeId,
      keyword: '',
      uploadedAt: undefined,
    },
    mode: 'all',
  });
  const { control, watch } = methods;
  const values = watch();
  const inputs = useMemo(() => {
    const { apPayee, keyword, uploadedAt } = values;
    const input = {
      apPayee,
      keyword,
      uploadedAt: uploadedAt ? toISODateStrings(uploadedAt) : undefined,
    };
    return input;
  }, [values]);
  const onDebounceSearch = useDebouncedCallback(onSearch || (() => {}), 600);
  onDebounceSearch(inputs);

  const payeeDefaultOptions = useMemo(
    () =>
      payee
        ? [{ label: payee.name, value: payee.id, caption: payee.code }]
        : undefined,
    [payee]
  );

  const _label = useMemo(
    () => ({
      text: t('label_ap_payee'),
    }),
    [t]
  );

  return (
    <Form formMethod={methods} className={cx(styles['container'])}>
      <Row gutter={8}>
        <Col span={8}>
          <Keyword control={control} />
        </Col>
        <Col span={8}>
          <ApPayee<SearchInput>
            label={_label}
            control={control}
            name='apPayee'
            inputId='select-ap-payee'
            defaultOptions={payeeDefaultOptions}
          />
        </Col>
        <Col span={8}>
          <UpdatedAt<SearchInput> control={control} name='uploadedAt' />
        </Col>
      </Row>
    </Form>
  );
});
Search.displayName = 'Search';

type KeywordProps = {
  control: Control<SearchInput>;
};

const Keyword: FC<KeywordProps> = memo(({ control }) => {
  const { t } = useTranslation();
  const labelKeyword = useMemo(
    () => ({
      text: t('received_invoice_key_word'),
    }),
    [t]
  );
  const inputProps: InputProps = useMemo(
    () => ({
      search: true,
      allowClear: true,
    }),
    []
  );
  return (
    <FormInput<SearchInput>
      control={control}
      name='keyword'
      inputId='select-keyword'
      label={labelKeyword}
      inputProps={inputProps}
    />
  );
});
Keyword.displayName = 'Keyword';
