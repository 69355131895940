import { ContactPersonEmail } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo/components/PersonInCharge/ContactPersonEmail';
import { ContactPersonMemberNames } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo/components/PersonInCharge/ContactPersonMemberNames';
import { ContactPersonName } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/DefaultInfo/components/PersonInCharge/ContactPersonName';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import {
  Bottom,
  Heading,
  ListView,
  ListViewLabel,
  Top,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  contactPersonName: string | undefined;
  contactPersonEmail: string | undefined;
  contactOfficeMemberNames: string | undefined;
};

export const PersonInCharge: FC<Props> = memo(
  ({ contactPersonName, contactPersonEmail, contactOfficeMemberNames }) => {
    const { t } = useTranslation();

    return (
      <section>
        <Heading as='h3' className={cx(styles['section-title'])}>
          {t('ap_payee_person_in_charge')}
        </Heading>
        <ListView>
          <Top>
            <ListViewLabel key='left'>
              {t('ap_payee_contact_person_name')}
            </ListViewLabel>
            <ContactPersonName contactPersonName={contactPersonName} />
          </Top>
          <Top>
            <ListViewLabel key='left'>
              {t('ap_payee_contact_person_email')}
            </ListViewLabel>
            <ContactPersonEmail contactPersonEmail={contactPersonEmail} />
          </Top>
          <Bottom>
            <ListViewLabel key='left'>
              {t('ap_payee_contact_office_member_names')}
            </ListViewLabel>
            <ContactPersonMemberNames
              contactOfficeMemberNames={contactOfficeMemberNames}
            />
          </Bottom>
        </ListView>
      </section>
    );
  }
);
PersonInCharge.displayName = 'PersonInCharge';
