import { PayeeDetail } from '@/context/services/ap_payee/type';
import { isSuccess } from '@/context/services/type';
import { useTranslation } from '@/i18n';
import {
  CommonError,
  useCreateApPayee as useBaseCreateApPayee,
  useGetApPayeesDetailSuspense,
} from 'ap-openapi';
import { useCallback, useMemo } from 'react';

type TCreateApPayeeMutateAsync = ReturnType<
  typeof useBaseCreateApPayee
>['mutateAsync'];
type CreateApPayeeMutateArgs = Parameters<TCreateApPayeeMutateAsync>['0'];
type CreateApPayeeMutateOption = Parameters<TCreateApPayeeMutateAsync>['1'] &
  TFetchQueries;

export const useCreateApPayee = () => {
  const { mutateAsync: _mutateAsync } = useBaseCreateApPayee();
  const mutateAsync = useCallback(
    async (
      args: CreateApPayeeMutateArgs,
      options?: CreateApPayeeMutateOption
    ) => {
      const resp = await _mutateAsync(args, options);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
          redirectPath: resp?.headers.location,
        };
      } else {
        return {
          status: 'error',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as CommonError,
        };
      }
    },
    [_mutateAsync]
  );
  return { mutateAsync };
};

export const useGetApPayeesDetail = (apPayeeId: string): PayeeDetail => {
  const { t } = useTranslation();
  const { data, error } = useGetApPayeesDetailSuspense(apPayeeId);
  if (error) {
    throw error;
  }

  if (!isSuccess(data.status)) {
    throw new Error(
      t('system_error_with_dialog', {
        resource: t('ap_payee'),
      })
    );
  }

  const apPayee = data.data;
  return useMemo(() => {
    return {
      id: apPayee.id,
      displayName: apPayee.display_name,
      contactOfficeMemberName: apPayee.contact_office_member_name,
      contactOfficeMemberNames: apPayee.contact_office_member_names,
      isActive: apPayee.is_active,
      contactPersonEmail: apPayee.contact_person_email,
      contactPersonName: apPayee.contact_person_name,
      invoiceReceiveEmailAddress: apPayee.invoice_receive_email_address,
      invoiceReceiveEmailProcessType:
        apPayee.invoice_receive_email_process_type,
      isInvoiceReceiveEmailSetting: apPayee.is_invoice_receive_email_setting,
      isAgentReceiveSetting: apPayee.is_agent_receive_setting,
      isSupplierUploadSetting: apPayee.is_supplier_upload_setting,
      agentReceiveSetting: apPayee.is_agent_receive_setting
        ? {
            address: apPayee.agent_receive_setting?.address,
            destination: apPayee.agent_receive_setting?.destination,
            uniqueCode: apPayee.agent_receive_setting?.unique_code,
          }
        : undefined,
      supplierUploadSetting: apPayee.is_supplier_upload_setting
        ? {
            expiredAt: apPayee.supplier_upload_setting?.expired_at,
            invitationStatus:
              apPayee.supplier_upload_setting?.invitation_status,
            invitationUrl: apPayee.supplier_upload_setting?.invitation_url,
            isAcceptedInvitation:
              apPayee.supplier_upload_setting?.is_accepted_invitation,
            isExpiredInvitationUrl:
              apPayee.supplier_upload_setting?.is_expired_invitation_url,
            isPublishedInvitationUrl:
              apPayee.supplier_upload_setting?.is_published_invitation_url,
            tenantName: apPayee.supplier_upload_setting?.tenant_name,
          }
        : undefined,
    } satisfies PayeeDetail;
  }, [
    apPayee.id,
    apPayee.display_name,
    apPayee.contact_office_member_name,
    apPayee.contact_office_member_names,
    apPayee.is_active,
    apPayee.contact_person_email,
    apPayee.contact_person_name,
    apPayee.invoice_receive_email_address,
    apPayee.is_invoice_receive_email_setting,
    apPayee.invoice_receive_email_process_type,
    apPayee.is_agent_receive_setting,
    apPayee.is_supplier_upload_setting,
    apPayee.agent_receive_setting?.address,
    apPayee.agent_receive_setting?.destination,
    apPayee.agent_receive_setting?.unique_code,
    apPayee.supplier_upload_setting?.expired_at,
    apPayee.supplier_upload_setting?.invitation_status,
    apPayee.supplier_upload_setting?.invitation_url,
    apPayee.supplier_upload_setting?.is_accepted_invitation,
    apPayee.supplier_upload_setting?.is_expired_invitation_url,
    apPayee.supplier_upload_setting?.is_published_invitation_url,
    apPayee.supplier_upload_setting?.tenant_name,
  ]);
};
