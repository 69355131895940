import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';

export type TPhoneNumberProps = {
  control: Control<CounterpartyForm>;
};

export const PhoneNumber: FC<TPhoneNumberProps> = memo(({ control }) => {
  const { t } = useTranslation();
  return (
    <>
      <>
        <Left>
          <Label text={t('counterparty_phone_number')} htmlFor='phone_number' />
        </Left>
        <Right>
          <FormInput<CounterpartyForm>
            control={control}
            name='phone_number'
            inputId='phone_number'
          />
        </Right>
      </>
    </>
  );
});
PhoneNumber.displayName = 'PhoneNumber';
