import { useBusinessPersonData } from '@/context/BusinessPersonData';
import { useLazyTpmInvoices } from '@/context/services/tpm/hooks';
import { isSuccess } from '@/context/services/type';
import { tpmResponseHasMessage } from '@/libs/typeguard/isError';

import { useCallback } from 'react';

export const useInvoiceRegistrationNumberHooks = () => {
  const { setBusinessPersonData } = useBusinessPersonData();
  const { fetch, isLoading } = useLazyTpmInvoices();

  const callBackAfterInput13text = useCallback(
    async (number: string) => {
      if (!number || number.length !== 13) {
        setBusinessPersonData({ disabled: true });
        return true;
      }
      const resp = await fetch({ number: number });
      if (!isSuccess(resp.status)) {
        setBusinessPersonData({ disabled: true });
        if (tpmResponseHasMessage(resp.data)) {
          return {
            error: true,
            message:
              resp.data.message ||
              '国税庁のデータベースに存在しない登録番号です',
          };
        } else {
          return '国税庁のデータベースに存在しない登録番号です';
        }
      } else {
        const data = resp.data;
        setBusinessPersonData({
          name: data?.name,
          companyType: data?.kind,
          address: data?.address,
          term: data?.qualified_period?.[0]?.period,
          disabled: false,
        });
        return true;
      }
    },
    [fetch, setBusinessPersonData]
  );

  return {
    callBackAfterInput13text,
    isLoading,
  };
};
