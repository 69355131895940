import { DialogBusinessDocumentBody } from '@/components/RelationActionMenu/BusinessDocument/Dialog/Body';
import { Footer } from '@/components/RelationActionMenu/BusinessDocument/Dialog/Footer';
import { SelectedBusinessDocument } from '@/components/RelationActionMenu/BusinessDocument/type';
import { useTranslation } from '@/i18n';
import { RelationDialog } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import styles from './Dialog.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  open: boolean;
  onClose: () => void;
  onSelectedItems: (selected: SelectedBusinessDocument[]) => void;
  selectedItems?: SelectedBusinessDocument[];
  container: HTMLElement | null;
};

export const RelationBusinessDocumentDialog: FC<Props> = memo(
  ({
    open,
    onClose,
    onSelectedItems: _onSelectedItems,
    container,
    selectedItems = [],
  }) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState<
      SelectedBusinessDocument[]
    >([]);

    useEffect(() => {
      setSelectedFiles(open ? selectedItems : []);
    }, [open, selectedItems]);

    const onSelectFiles = useCallback(() => {
      _onSelectedItems(selectedFiles);
    }, [_onSelectedItems, selectedFiles]);

    return (
      <RelationDialog
        open={open}
        onClose={onClose}
        getContainer={() => container!}
        title={t('relation_action.business_document.dialog_title')}
        modalWrapperClassName={cx(styles['container'])}
        modalMainClassName={cx(styles['content'])}
        footer={
          <Footer
            onCancel={onClose}
            onSelected={onSelectFiles}
            disabled={selectedFiles.length === 0 && selectedItems?.length === 0}
          />
        }
      >
        {open && (
          <DialogBusinessDocumentBody
            onSelectedItems={setSelectedFiles}
            selectedItems={selectedItems}
          />
        )}
      </RelationDialog>
    );
  }
);
RelationBusinessDocumentDialog.displayName = 'RelationBusinessDocumentDialog';
