import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  FormSelect,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TSubcontractTypeProps = {
  control: Control<CounterpartyForm>;
};

export const SubcontractType: FC<TSubcontractTypeProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();

    const checkMemoProps = useMemo(
      () => ({
        placeholder: t('counterparty_subcontract_check_memo_placeholder'),
      }),
      [t]
    );

    const subcontractTypeSelectProps = useMemo(
      () => ({
        options: [
          {
            label: t('counterparty_subcontract_unknown'),
            value: 'unknown',
          },
          {
            label: t('counterparty_subcontract_target'),
            value: 'target',
          },
          {
            label: t(
              'counterparty_subcontract_not_target_no_target_transactions'
            ),
            value: 'not_target_no_target_transactions',
          },
          {
            label: t(
              'counterparty_subcontract_not_target_capital_50_million_or_more'
            ),
            value: 'not_target_capital_50_million_or_more',
          },
          {
            label: t(
              'counterparty_subcontract_not_target_capital_300_million_or_more'
            ),
            value: 'not_target_capital_300_million_or_more',
          },
        ],
      }),
      [t]
    );

    return (
      <>
        <Left>
          <Label
            text={t('counterparty_subcontract_type')}
            htmlFor='subcontract_type'
          />
        </Left>
        <Right>
          <div className={cx(styles['right-content'])}>
            <div className={cx(styles['form-select-wrap'])}>
              <FormSelect<CounterpartyForm>
                control={control}
                name='subcontract_type'
                inputId='subcontract_type'
                testId='subcontract_type'
                selectProps={subcontractTypeSelectProps}
              />
            </div>
            <FormInput<CounterpartyForm>
              control={control}
              name='subcontract_check_memo'
              inputId='subcontract_check_memo'
              inputProps={checkMemoProps}
            />
          </div>
        </Right>
      </>
    );
  }
);
SubcontractType.displayName = 'SubcontractType';
