import { SetupWorker, StartOptions } from 'msw/browser';
import { SetupServer } from 'msw/node';
import { Handles } from './handle.d';

export type SetupApi = SetupWorker | SetupServer;
let api: SetupApi | undefined;

export type Mock = {
  worker: SetupApi | undefined;
  cleanUp: () => void;
};

export const mockAPI = async (
  options?: StartOptions,
  ...handlers: Handles
): Promise<Mock> => {
  if (typeof window === 'undefined') {
    // const worker = (await import('./server')).worker(...handlers);
    // worker.listen();
    return {
      worker: undefined,
      cleanUp: () => {},
    };
  } else {
    const worker = (await import('./browser')).worker(...handlers);
    await worker.start(options);
    api = worker;
    return {
      worker,
      cleanUp: () => worker.stop(),
    };
  }
};

export const getWorker = () => api;
