import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';

export type TPayeePriorityProps = {
  control: Control<ApPayeeForm>;
};
export const PayeePriority: FC<TPayeePriorityProps> = memo(({ control }) => {
  const { t } = useTranslation();

  const requiredRules = useMemo(
    () => ({
      required: t('required'),
    }),
    [t]
  );
  return (
    <>
      <Left>
        <Label required text={t('ap_payee_priority')} htmlFor='priority' />
      </Left>
      <Right>
        <FormInput<ApPayeeForm>
          control={control}
          name='priority'
          inputId='priority'
          rules={requiredRules}
        />
      </Right>
    </>
  );
});
PayeePriority.displayName = 'PayeePriority';
