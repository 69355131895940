/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `BigDecimal` scalar type represents non-fractional signed whole numeric
   * values with encoded as a `string`. Be careful with `Number.MAX_SAFE_INTEGER`
   * when using JavaScript for your client
   */
  BigDecimal: { input: any; output: any; }
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type AccessRights = {
  __typename?: 'AccessRights';
  /** Permission to access the Business Document Setting page */
  canAccessBusinessDocumentSetting: Scalars['Boolean']['output'];
  /** Permission to access the Business Documents page */
  canAccessBusinessDocuments: Scalars['Boolean']['output'];
  /** Permission to access the Received Invoices page */
  canAccessReceivedInvoices: Scalars['Boolean']['output'];
};

/** 会計サービス連携設定 */
export enum AccountingService {
  /** マネーフォワードクラウド会計と連携 */
  Ca = 'ca',
  /** マネーフォワードクラウド会計Plusと連携 */
  CaMid = 'ca_mid',
  /** 連携しない */
  None = 'none',
  /** 未設定 */
  Unset = 'unset'
}

/** ヘッダーに表示する緊急時お知らせ情報 */
export type Announcement = {
  __typename?: 'Announcement';
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 表示終了時間 */
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** お知らせメッセージ */
  message: Scalars['String']['output'];
  /** メッセージのリンク先 */
  messageLinkUrl?: Maybe<Scalars['String']['output']>;
  /** 表示開始時間 */
  startsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** 部門毎の経費科目設定 */
export type ApActiveExDeptExItem = {
  __typename?: 'ApActiveExDeptExItem';
  /** 経費科目ID */
  apItemId: Scalars['ID']['output'];
  /** 経費科目名 */
  apItemName: Scalars['String']['output'];
  /** 部門ID */
  exDeptId: Scalars['ID']['output'];
  /** 利用するかどうか */
  isActive: Scalars['Boolean']['output'];
  /** 事業者ID */
  officeId: Scalars['ID']['output'];
};

/** クラウド債務支払の消費税端数処理方法 */
export type ApOfficeExciseSetting = {
  __typename?: 'ApOfficeExciseSetting';
  /** ID（難読化された文字列） */
  id?: Maybe<Scalars['ID']['output']>;
  /** 消費税端数処理方法 */
  roundingMethod: RoundingMethod;
};

/** クラウド債務支払の事業者設定情報 */
export type ApOfficeSetting = {
  __typename?: 'ApOfficeSetting';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 支払仕訳を利用するかどうか */
  isPaymentJournalEnabled: Scalars['Boolean']['output'];
  /** 手数料設定が有効かどうか、現在のプランも考慮します */
  isTransferFeeEnabled: Scalars['Boolean']['output'];
};

/** クラウド債務支払の特例区分 */
export type ApSpecialExceptionStatus = {
  __typename?: 'ApSpecialExceptionStatus';
  /** 摘要文言 */
  customRemark: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 表示設定 */
  isActive: Scalars['Boolean']['output'];
  /** 特例区分名称 */
  name: Scalars['String']['output'];
};

/** The connection type for ApSpecialExceptionStatus. */
export type ApSpecialExceptionStatusConnection = {
  __typename?: 'ApSpecialExceptionStatusConnection';
  /** A list of edges. */
  edges: Array<ApSpecialExceptionStatusEdge>;
  /** A list of nodes. */
  nodes: Array<ApSpecialExceptionStatus>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ApSpecialExceptionStatusEdge = {
  __typename?: 'ApSpecialExceptionStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ApSpecialExceptionStatus;
};

/** 承認者に指定されている申請の件数を返す */
export type ApprovingExReportsCount = {
  __typename?: 'ApprovingExReportsCount';
  /** 現在承認待ちの件数 */
  pending: Scalars['Int']['output'];
  /** 差し戻しの件数 */
  rejected: Scalars['Int']['output'];
};

/** 銀行 */
export type Bank = {
  __typename?: 'Bank';
  /** コード */
  code: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 名称 */
  name: Scalars['String']['output'];
  /** 名称カナ */
  nameKana: Scalars['String']['output'];
};

/** 口座種別 */
export enum BankAccount {
  /** 当座 */
  Checking = 'checking',
  /** 普通 */
  Ordinary = 'ordinary',
  /** その他 */
  Other = 'other',
  /** 貯蓄 */
  Saving = 'saving'
}

/** 銀行支店 */
export type BankBranch = {
  __typename?: 'BankBranch';
  /** 銀行 */
  bank?: Maybe<Bank>;
  /** 銀行ID */
  bankId: Scalars['ID']['output'];
  /** コード */
  code: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 支店名 */
  name: Scalars['String']['output'];
  /** 支店名カナ */
  nameKana: Scalars['String']['output'];
};

/** 振込連携担当者設定 */
export type BankLink = {
  __typename?: 'BankLink';
  /** 連携アクション */
  actions: Array<BankLinkAction>;
  /** 連携済みであるか */
  isLinking: Scalars['Boolean']['output'];
  /** 振込担当者である従業員のID */
  officeMemberId: Scalars['ID']['output'];
  /** 振込担当者である従業員の名前 */
  officeMemberName: Scalars['String']['output'];
};

/** 振込連携担当者に関する操作 */
export type BankLinkAction = {
  __typename?: 'BankLinkAction';
  /** ラベル */
  label: Scalars['String']['output'];
  /** URL */
  url: Scalars['String']['output'];
  /** 操作種類 */
  verb: BankLinkActionVerb;
};

/** 振込担当者の連携アクション */
export enum BankLinkActionVerb {
  /** 連携開始 */
  Create = 'create',
  /** 連携解除 */
  Delete = 'delete',
  /** 連携更新 */
  Update = 'update'
}

export type BaseNotificationSetting = {
  __typename?: 'BaseNotificationSetting';
  /** ID */
  id: Scalars['ID']['output'];
  /** 通知する/通知しない */
  isActive: Scalars['Boolean']['output'];
};

export type BaseNotificationSettingInput = {
  /** 通知フラグ */
  isActive: Scalars['Boolean']['input'];
};

/** Autogenerated input type of BulkCheckReadNotificationTimelines */
export type BulkCheckReadNotificationTimelinesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 既読にするNotificationTimeline idの配列 */
  notificationTimelineIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BulkCheckReadNotificationTimelines. */
export type BulkCheckReadNotificationTimelinesPayload = {
  __typename?: 'BulkCheckReadNotificationTimelinesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 対象のNotificationTimeline */
  notificationTimelines: Array<NotificationTimeline>;
};

/** Cloud Accounting Sector Information */
export type CaDept = {
  __typename?: 'CaDept';
  /** ID (encrypted) */
  id: Scalars['ID']['output'];
  /** Dept name */
  name: Scalars['String']['output'];
};

/** Cloud Accounting Plus Sector Information */
export type CaMidDept = {
  __typename?: 'CaMidDept';
  /** ID (encrypted) */
  id: Scalars['ID']['output'];
  /** Dept name */
  name: Scalars['String']['output'];
};

export type ClosingAlertNotificationSetting = {
  __typename?: 'ClosingAlertNotificationSetting';
  /** ID */
  id: Scalars['ID']['output'];
  /** 通知する/通知しない */
  isActive: Scalars['Boolean']['output'];
  /** 締日から何日後に通知するか */
  notifyDaysAfterClosing: Scalars['Int']['output'];
};

export type ClosingAlertNotificationSettingInput = {
  /** 通知フラグ */
  isActive: Scalars['Boolean']['input'];
  /** （締め日アラートのみ）経費精算の締め日から何日後に通知するか */
  notifyDaysAfterClosing?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of CopyExReport */
export type CopyExReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 複製元の申請ID */
  exReportId: Scalars['String']['input'];
};

/** Autogenerated return type of CopyExReport. */
export type CopyExReportPayload = {
  __typename?: 'CopyExReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 複製先の申請 */
  exReport: ExReport;
};

/** Autogenerated input type of CreateDept */
export type CreateDeptInput = {
  /** Cloud Accounting dept */
  caDeptId?: InputMaybe<Scalars['ID']['input']>;
  /** Cloud Accounting Plus dept */
  caMidDeptId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Dept code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Display order */
  displayOrder: Scalars['Int']['input'];
  /** Cost burden dept/Dept for journal */
  exJournalDeptId?: InputMaybe<Scalars['ID']['input']>;
  /** Display flag */
  isActive: Scalars['Boolean']['input'];
  /** Dept name */
  name: Scalars['String']['input'];
  /** Parent dept */
  parentDeptId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateDept. */
export type CreateDeptPayload = {
  __typename?: 'CreateDeptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Dept */
  dept: Dept;
};

/** Autogenerated input type of CreateExBusinessTravelJtbAccount */
export type CreateExBusinessTravelJtbAccountInput = {
  /** アカウント名 */
  accountName: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 貸方勘定科目初期値 */
  crItemId: Scalars['ID']['input'];
  /** 貸方補助科目初期値 */
  crSubItemId?: InputMaybe<Scalars['ID']['input']>;
  /** 取引日として初期設定する日付の設定値 */
  defaultRecognizedAtType: ExBusinessTravelJtbAccountDefaultRecognizedAt;
  /** API連携用にJTBから提供される「認証キー」 */
  ebbaKey: Scalars['String']['input'];
  /** API連携用にJTBから提供される「企業コード」 */
  locationCode: Scalars['String']['input'];
  /** API連携用にJTBから提供される「企業パスワード」 */
  locationPassword: Scalars['String']['input'];
};

/** Autogenerated return type of CreateExBusinessTravelJtbAccount. */
export type CreateExBusinessTravelJtbAccountPayload = {
  __typename?: 'CreateExBusinessTravelJtbAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exBusinessTravelJtbAccountId: Scalars['ID']['output'];
};

/** Autogenerated input type of CreateOfficeAccount */
export type CreateOfficeAccountInput = {
  /** 口座種別 */
  accountType: BankAccount;
  /** 銀行支店ID */
  bankBranchId: Scalars['ID']['input'];
  /** 銀行ID */
  bankId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 振込依頼人コード */
  fbCustomerCode?: InputMaybe<Scalars['String']['input']>;
  /** 名義人 */
  holderName: Scalars['String']['input'];
  /** 名義人カナ */
  holderNameKana: Scalars['String']['input'];
  /** 口座番号 */
  number: Scalars['String']['input'];
  /** 事業者ID */
  officeId: Scalars['ID']['input'];
  /** 支払仕訳の貸方勘定科目 */
  paymentJournalItemId?: InputMaybe<Scalars['ID']['input']>;
  /** 支払仕訳の貸方補助科目 */
  paymentJournalSubItemId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateOfficeAccount. */
export type CreateOfficeAccountPayload = {
  __typename?: 'CreateOfficeAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  officeAccount: OfficeAccount;
};

/** Autogenerated input type of CreateOfficeMemberOfficeAccount */
export type CreateOfficeMemberOfficeAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 支払元口座ID */
  officeAccountId: Scalars['ID']['input'];
  /** 事業者ID */
  officeId: Scalars['ID']['input'];
  /** 従業員ID */
  officeMemberId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateOfficeMemberOfficeAccount. */
export type CreateOfficeMemberOfficeAccountPayload = {
  __typename?: 'CreateOfficeMemberOfficeAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 振込データAPI連携担当者ID */
  officeMemberOfficeAccountId: Scalars['String']['output'];
};

/** Autogenerated input type of CreatePosition */
export type CreatePositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the position is an authorizer */
  isAuthorizer: Scalars['Boolean']['input'];
  /** position name */
  name: Scalars['String']['input'];
  /** Display priority */
  priority: Scalars['Int']['input'];
};

/** Autogenerated return type of CreatePosition. */
export type CreatePositionPayload = {
  __typename?: 'CreatePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Position */
  position: Position;
};

/** Autogenerated input type of CreateProjectCode */
export type CreateProjectCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Project code code */
  code: Scalars['String']['input'];
  /** Whether the project code is active */
  isActive: Scalars['Boolean']['input'];
  /** Project code name */
  name: Scalars['String']['input'];
  /** Parent project code id */
  parentProjectCodeId?: InputMaybe<Scalars['ID']['input']>;
  /** Display priority */
  priority: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateProjectCode. */
export type CreateProjectCodePayload = {
  __typename?: 'CreateProjectCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Project code */
  projectCode: ProjectCode;
};

/** Autogenerated input type of CreateSession */
export type CreateSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 認可コード */
  code: Scalars['String']['input'];
  /** code challengeの検証文字列 */
  codeVerifier: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

/** Autogenerated return type of CreateSession. */
export type CreateSessionPayload = {
  __typename?: 'CreateSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exAccessToken: Scalars['String']['output'];
};

/** 日付範囲指定 */
export type DateRangeInput = {
  /** 始点 */
  from: Scalars['ISO8601Date']['input'];
  /** 終点 */
  to: Scalars['ISO8601Date']['input'];
};

/** 事業者の部門情報 */
export type Dept = {
  __typename?: 'Dept';
  /** Cloud Accounting dept */
  caDept?: Maybe<CaDept>;
  /** Cloud Accounting Plus dept */
  caMidDept?: Maybe<CaMidDept>;
  /** Dept code */
  code?: Maybe<Scalars['String']['output']>;
  /** Display order */
  displayOrder: Scalars['Int']['output'];
  /** Cost burden dept/Dept for journal */
  exJournalDept?: Maybe<Dept>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Display flag */
  isActive: Scalars['Boolean']['output'];
  /** Dept name */
  name: Scalars['String']['output'];
  /** Parent dept */
  parentDept?: Maybe<Dept>;
};

/** The connection type for Dept. */
export type DeptConnection = {
  __typename?: 'DeptConnection';
  /** A list of edges. */
  edges: Array<DeptEdge>;
  /** A list of nodes. */
  nodes: Array<Dept>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DeptEdge = {
  __typename?: 'DeptEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Dept;
};

/** List of departments */
export type DeptList = {
  __typename?: 'DeptList';
  /** Departments */
  items: Array<Dept>;
  /** Total count of departments */
  totalCount: Scalars['Int']['output'];
};

/** Dept order */
export enum DeptOrderEnum {
  /** Code ascending order */
  CodeAsc = 'code_asc',
  /** Code descending order */
  CodeDesc = 'code_desc',
  /** Priority ascending order */
  PriorityAsc = 'priority_asc',
  /** Priority descending order */
  PriorityDesc = 'priority_desc'
}

/** Search conditions for departments */
export type DeptSearchInput = {
  /** Whether the project code is active */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Keyword */
  keyword?: InputMaybe<Scalars['String']['input']>;
  /** Order */
  order?: InputMaybe<DeptOrderEnum>;
};

/** Autogenerated input type of DestroyDept */
export type DestroyDeptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Dept IDs */
  deptIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of DestroyDept. */
export type DestroyDeptPayload = {
  __typename?: 'DestroyDeptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Dept IDs */
  deptIds: Array<Scalars['ID']['output']>;
};

/** Autogenerated input type of DestroyExBusinessTravelJtbAccount */
export type DestroyExBusinessTravelJtbAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** JTB連携サービスID */
  exBusinessTravelJtbAccountId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyExBusinessTravelJtbAccount. */
export type DestroyExBusinessTravelJtbAccountPayload = {
  __typename?: 'DestroyExBusinessTravelJtbAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** JTB連携サービスID */
  exBusinessTravelJtbAccountId: Scalars['ID']['output'];
};

/** Autogenerated input type of Destroy */
export type DestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 支払元口座ID */
  officeAccountId: Scalars['ID']['input'];
  /** 事業者ID */
  officeId: Scalars['ID']['input'];
  /** 従業員ID */
  officeMemberId: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyMfFile */
export type DestroyMfFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 削除対象ファイルのID */
  mfFileId: Scalars['String']['input'];
};

/** Autogenerated return type of DestroyMfFile. */
export type DestroyMfFilePayload = {
  __typename?: 'DestroyMfFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 削除済ファイルのID */
  mfFileId: Scalars['String']['output'];
};

/** Autogenerated input type of DestroyOfficeMemberExJtbUserAccount */
export type DestroyOfficeMemberExJtbUserAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 従業員に対して設定するJTB_USERのアカウントのID */
  officeMemberExJtbUserAccountId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyOfficeMemberExJtbUserAccount. */
export type DestroyOfficeMemberExJtbUserAccountPayload = {
  __typename?: 'DestroyOfficeMemberExJtbUserAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  officeMemberExJtbUserAccountId: Scalars['ID']['output'];
};

/** Autogenerated return type of Destroy. */
export type DestroyPayload = {
  __typename?: 'DestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 振込データAPI連携担当者ID */
  officeMemberOfficeAccountId: Scalars['String']['output'];
};

/** Autogenerated input type of DestroyPosition */
export type DestroyPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** position id */
  positionId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyPosition. */
export type DestroyPositionPayload = {
  __typename?: 'DestroyPositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Position id */
  positionId: Scalars['ID']['output'];
};

/** Autogenerated input type of DestroyProjectCode */
export type DestroyProjectCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Project code ids */
  projectCodeIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of DestroyProjectCode. */
export type DestroyProjectCodePayload = {
  __typename?: 'DestroyProjectCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Project code ids */
  projectCodeIds: Array<Scalars['ID']['output']>;
};

/** Autogenerated input type of DestroySession */
export type DestroySessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DestroySession. */
export type DestroySessionPayload = {
  __typename?: 'DestroySessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** リダイレクト先URL */
  mfidEndSessionEndpoint: Scalars['String']['output'];
};

/** Autogenerated input type of DestroySubOfficeMemberExJtbUserAccount */
export type DestroySubOfficeMemberExJtbUserAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 従業員に対して設定するJTB_USERのアカウントのID */
  officeMemberExJtbUserAccountId: Scalars['ID']['input'];
  /** 従業員ID */
  officeMemberId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroySubOfficeMemberExJtbUserAccount. */
export type DestroySubOfficeMemberExJtbUserAccountPayload = {
  __typename?: 'DestroySubOfficeMemberExJtbUserAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  subOfficeMemberExJtbUserAccountId: Scalars['ID']['output'];
};

/** Electronic Bookkeeping law setting (電子帳簿保存法設定) */
export type EDocPlan = {
  __typename?: 'EDocPlan';
  /** Whether the electronic bookkeeping law (電子帳簿保存法) setting is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Whether both The electronic bookkeeping law (電子帳簿保存法) and Type of Receipts or Invoices （受領区分） settings are enabled. */
  isReceiptTypeEnabled: Scalars['Boolean']['output'];
};

/** ex_business_travel_jtb_transaction estimation_target_type_list */
export enum EstimationTarget {
  /** bulk_billing */
  BulkBilling = 'bulk_billing',
  /** bulk_credit */
  BulkCredit = 'bulk_credit',
  /** cash_on_delivery */
  CashOnDelivery = 'cash_on_delivery',
  /** individual_billing */
  IndividualBilling = 'individual_billing',
  /** individual_credit */
  IndividualCredit = 'individual_credit',
  /** local payment */
  LocalPayment = 'local_payment'
}

export type ExBusinessTravelJtbAccount = {
  __typename?: 'ExBusinessTravelJtbAccount';
  /** アカウント名 */
  accountName: Scalars['String']['output'];
  /** 貸方勘定科目初期値 */
  crItem: Item;
  /** 貸方補助科目初期値。初期値を設定していない場合はnilになる */
  crSubItem?: Maybe<SubItem>;
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 明細の取引日として使用する日付 */
  defaultRecognizedAtType: ExBusinessTravelJtbAccountDefaultRecognizedAt;
  /** 認証キー */
  ebbaKey: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 企業コード */
  locationCode: Scalars['String']['output'];
  /** 従業員に対して設定するJTB_USERのアカウント */
  officeMemberExJtbUserAccount: OfficeMemberExJtbUserAccount;
};


export type ExBusinessTravelJtbAccountOfficeMemberExJtbUserAccountArgs = {
  officeMemberExJtbUserAccountId: Scalars['ID']['input'];
};

/** The connection type for ExBusinessTravelJtbAccount. */
export type ExBusinessTravelJtbAccountConnection = {
  __typename?: 'ExBusinessTravelJtbAccountConnection';
  /** A list of edges. */
  edges: Array<ExBusinessTravelJtbAccountEdge>;
  /** A list of nodes. */
  nodes: Array<ExBusinessTravelJtbAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** 明細の取引日として使用する日付 */
export enum ExBusinessTravelJtbAccountDefaultRecognizedAt {
  /** 請求確定日 */
  BillingDate = 'billing_date',
  /** 利用開始日 */
  UsageDate = 'usage_date',
  /** 利用終了日 */
  UsageEndDate = 'usage_end_date'
}

/** An edge in a connection. */
export type ExBusinessTravelJtbAccountEdge = {
  __typename?: 'ExBusinessTravelJtbAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExBusinessTravelJtbAccount;
};

/** ExBusinessTravelJtbTransaction */
export type ExBusinessTravelJtbTransaction = {
  __typename?: 'ExBusinessTravelJtbTransaction';
  /** Amount in Japanese yen (JTB item: KG) */
  amount: Scalars['BigDecimal']['output'];
  /** Billing date (JTB item: SEIKYUHSSEIYMD) */
  billingDate: Scalars['ISO8601Date']['output'];
  /** Class information (JTB item: DATA07) */
  classInfo?: Maybe<Scalars['String']['output']>;
  /** Select a default item from the Jtb_transaction */
  defaultExItem?: Maybe<ExItem>;
  /** Departure location (JTB item: DATA03) */
  departureLocation?: Maybe<Scalars['String']['output']>;
  /** Destination (JTB item: DATA04) */
  destination?: Maybe<Scalars['String']['output']>;
  /** Name of the employee on business trip (JTB item: DATA14) */
  employeeName?: Maybe<Scalars['String']['output']>;
  /**
   * Accumulation target type (local payment=0, individual billing=1, bulk
   * billing=2, individual credit=3, bulk credit=4, cash on delivery=5, JTB item: SETSHOUKBN)
   */
  estimationTargetType: EstimationTarget;
  /** ex_business_travel_transaction_info records linked to ex_business_travel_jtb_transaction. */
  exBusinessTravelTransactionInfo?: Maybe<ExBusinessTravelTransactionInfo>;
  /** Fare type (JTB item: DATA05) */
  fareType?: Maybe<Scalars['String']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Registration number of qualified invoice issuer (JTB item: DATA21) */
  invoiceRegistrationNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Transaction status (reservation OK=1, canceled=2, cancellation/refund fee=3,
   * final confirmation=4, OP=5, cancellation waiting=6, ticket delivered=7, JTB item: DATA11)
   */
  jtbTransactionStatus?: Maybe<JtbTransactionStatus>;
  /** User ID (typically assumed to be employee number, JTB item: USERID) */
  jtbUserid: Scalars['String']['output'];
  /** Payee (JTB item: DATA01) */
  payee?: Maybe<Scalars['String']['output']>;
  /** Date to use as transaction date based on ex_business_travel_jtb_account.default_recognized_at_type */
  recognizedAtDate?: Maybe<Scalars['String']['output']>;
  /** Flight name, train name (JTB item: DATA06) */
  serviceName?: Maybe<Scalars['String']['output']>;
  /** Name of transportation facility, accommodation, etc. (JTB item: DATA02) */
  serviceProviderName?: Maybe<Scalars['String']['output']>;
  /** Whether it is taxable or not (JTB item: DATA15) */
  taxable?: Maybe<Scalars['Boolean']['output']>;
  /** Tour number (JTB item: RYOKOUNO) */
  tourNo: Scalars['String']['output'];
  /** Usage date (JTB item: SPYMD) */
  usageDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Usage end date (JTB item: TCYMD) */
  usageEndDate?: Maybe<Scalars['ISO8601Date']['output']>;
};


/** ExBusinessTravelJtbTransaction */
export type ExBusinessTravelJtbTransactionDefaultExItemArgs = {
  officeMemberId: Scalars['ID']['input'];
};

export type ExBusinessTravelTransactionInfo = {
  __typename?: 'ExBusinessTravelTransactionInfo';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Memo of the ex_business_travel_transaction_info */
  memo?: Maybe<Scalars['String']['output']>;
  /** Status of the ex_business_travel_transaction_info */
  status: ExBusinessTravelTransactionInfoStatus;
};

/** Status of cloud expense statement registration. */
export enum ExBusinessTravelTransactionInfoStatus {
  /** 対象外 */
  Excluded = 'excluded',
  /** 登録済 */
  Registered = 'registered',
  /** 未登録 */
  Unregistered = 'unregistered'
}

/** ExFunctionの関数 */
export type ExFunctionFunction = {
  __typename?: 'ExFunctionFunction';
  /** 関数についての説明 */
  description: Scalars['String']['output'];
  /** 関数名 */
  name: Scalars['String']['output'];
};

/** 経費科目 */
export type ExItem = {
  __typename?: 'ExItem';
  /** コード */
  code?: Maybe<Scalars['String']['output']>;
  /** デフォルト税区分 */
  defaultDrExciseId?: Maybe<Scalars['ID']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 経費科目の利用可否 */
  isActive: Scalars['Boolean']['output'];
  /** 出席者欄 */
  isAttendantFieldDisplayed?: Maybe<Scalars['Boolean']['output']>;
  /** 借方勘定科目ID */
  itemId: Scalars['ID']['output'];
  /** 経費科目名 */
  name: Scalars['String']['output'];
  /** 表示順 */
  priority: Scalars['Int']['output'];
  /** 補助科目ID */
  subItemId?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for ExItem. */
export type ExItemConnection = {
  __typename?: 'ExItemConnection';
  /** A list of edges. */
  edges: Array<ExItemEdge>;
  /** A list of nodes. */
  nodes: Array<ExItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExItemEdge = {
  __typename?: 'ExItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExItem;
};

/** メール通知の種別 */
export enum ExMailKindEnum {
  /** 締め日アラート（経費のみ） */
  ClosingAlert = 'closing_alert',
  /** オペレーター入力完了（経費のみ） */
  OperationComplete = 'operation_complete',
  /** 承認・差し戻し（申請内チャットコメント） */
  WorkflowNotification = 'workflow_notification'
}

/** メール通知設定 */
export type ExMailSetting = {
  __typename?: 'ExMailSetting';
  /** （締め日アラートのみ）経費精算の締め日から何日後に通知するか */
  daysUntilNotification?: Maybe<Scalars['Int']['output']>;
  /** 通知の種別 */
  exMailKind: ExMailKindEnum;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 通知フラグ */
  isActive: Scalars['Boolean']['output'];
};

export type ExMailSettingInput = {
  /** （締め日アラートのみ）経費精算の締め日から何日後に通知するか */
  daysUntilNotification?: InputMaybe<Scalars['Int']['input']>;
  /** メール通知設定ID */
  exMailSettingId: Scalars['ID']['input'];
  /** 通知フラグ */
  isActive: Scalars['Boolean']['input'];
};

/** クラウド経費の消費税端数処理方法 */
export type ExOfficeExciseSetting = {
  __typename?: 'ExOfficeExciseSetting';
  /** ID（難読化された文字列） */
  id?: Maybe<Scalars['ID']['output']>;
  /** 消費税端数処理方法 */
  roundingMethod: RoundingMethod;
};

/** 事業者毎の外貨設定情報 */
export type ExOfficeForeignCurrencySetting = {
  __typename?: 'ExOfficeForeignCurrencySetting';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 端数処理設定 */
  roundingMode: ExOfficeForeignCurrencySettingRoundingMode;
};

/** 端数処理設定の種類 */
export enum ExOfficeForeignCurrencySettingRoundingMode {
  /** 切り捨て */
  RoundDown = 'round_down',
  /** 四捨五入 */
  RoundOff = 'round_off',
  /** 切り上げ */
  RoundUp = 'round_up'
}

/** クラウド経費の事業者設定情報 */
export type ExOfficeSetting = {
  __typename?: 'ExOfficeSetting';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 支払仕訳を利用するかどうか */
  isPaymentJournalEnabled: Scalars['Boolean']['output'];
  /** 仕訳連動モード */
  linkJournalSettingKind: Scalars['String']['output'];
  /** 外貨設定を利用するかどうか */
  useExJpyRate: Scalars['Boolean']['output'];
};

/** プラン種別 */
export enum ExPlan {
  /** コーポレート */
  Corporate = 'corporate',
  /** エンタープライズ */
  Enterprise = 'enterprise',
  /** プランなし */
  Nothing = 'nothing',
  /** パーソナル */
  Personal = 'personal',
  /** チーム */
  Team = 'team'
}

/** プッシュ通知の種別(経費のみ) */
export enum ExPushKindEnum {
  /** 新規承認 */
  ApprovableReport = 'approvable_report',
  /** 締め日アラート */
  ClosingAlert = 'closing_alert',
  /** オペレーター入力完了 */
  OperationComplete = 'operation_complete',
  /** 新規メッセージ */
  ReportTimeline = 'report_timeline',
  /** 承認・差し戻し（申請内チャットコメント） */
  WorkflowNotification = 'workflow_notification'
}

/** プッシュ通知設定(経費のみ) */
export type ExPushSetting = {
  __typename?: 'ExPushSetting';
  /** （締め日アラートのみ）経費精算の締め日から何日後に通知するか */
  daysUntilNotification?: Maybe<Scalars['Int']['output']>;
  /** 通知の種別 */
  exPushKind: ExPushKindEnum;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 通知フラグ */
  isActive: Scalars['Boolean']['output'];
};

/** プッシュ通知設定(経費のみ) */
export type ExPushSettingInput = {
  /** （締め日アラートのみ）経費精算の締め日から何日後に通知するか */
  daysUntilNotification?: InputMaybe<Scalars['Int']['input']>;
  /** プッシュ通知設定ID */
  exPushSettingId: Scalars['ID']['input'];
  /** 通知フラグ */
  isActive: Scalars['Boolean']['input'];
};

export type ExReport = {
  __typename?: 'ExReport';
  /** 承認日時 */
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 編集画面へのpath */
  editPath: Scalars['String']['output'];
  /** 入力項目一覧 */
  exReportFormInputValues: ExReportFormInputValueConnection;
  /** 申請タイプ */
  exReportType?: Maybe<ReportType>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['String']['output'];
  /** 申請した従業員 */
  officeMember: OfficeMember;
  /** ステータス */
  status: ExReportStatus;
  /** 申請日時 */
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** タイムスタンプステータス */
  timestampStatus?: Maybe<ExReportTimestampStatus>;
  /** タイトル */
  title: Scalars['String']['output'];
};


export type ExReportExReportFormInputValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ExReportFormInput = {
  __typename?: 'ExReportFormInput';
  /** 注記 */
  annotation?: Maybe<Scalars['String']['output']>;
  /** デフォルト値 */
  defaultValue?: Maybe<Scalars['String']['output']>;
  /** 選択肢の一覧 */
  exReportFormInputOptions: ExReportFormInputOptionConnection;
  /** 「その他」選択肢の有無 */
  hasOtherValue: Scalars['Boolean']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 必須入力かどうか */
  isRequired: Scalars['Boolean']['output'];
  /** 表示ラベル */
  label: Scalars['String']['output'];
  /** タイプ（テキストフィールド・チェックボックス・ラジオボタンなど） */
  type: Scalars['String']['output'];
};


export type ExReportFormInputExReportFormInputOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ExReportFormInput. */
export type ExReportFormInputConnection = {
  __typename?: 'ExReportFormInputConnection';
  /** A list of edges. */
  edges: Array<ExReportFormInputEdge>;
  /** A list of nodes. */
  nodes: Array<ExReportFormInput>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExReportFormInputEdge = {
  __typename?: 'ExReportFormInputEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExReportFormInput;
};

export type ExReportFormInputOption = {
  __typename?: 'ExReportFormInputOption';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 申請フォーム入力項目の選択肢 */
  value: Scalars['String']['output'];
};

/** The connection type for ExReportFormInputOption. */
export type ExReportFormInputOptionConnection = {
  __typename?: 'ExReportFormInputOptionConnection';
  /** A list of edges. */
  edges: Array<ExReportFormInputOptionEdge>;
  /** A list of nodes. */
  nodes: Array<ExReportFormInputOption>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExReportFormInputOptionEdge = {
  __typename?: 'ExReportFormInputOptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExReportFormInputOption;
};

export type ExReportFormInputValue = {
  __typename?: 'ExReportFormInputValue';
  /** 表示ラベル */
  displayValue?: Maybe<Scalars['String']['output']>;
  /** 申請フォーム項目 */
  exReportFormInput: ExReportFormInput;
  /** ファイル画像プレビューURL */
  fileImagePreviewUrl?: Maybe<Scalars['String']['output']>;
  /** ファイル名 */
  fileName?: Maybe<Scalars['String']['output']>;
  /** ファイルURL */
  fileUrl?: Maybe<Scalars['String']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 設定中の値一覧 */
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** The connection type for ExReportFormInputValue. */
export type ExReportFormInputValueConnection = {
  __typename?: 'ExReportFormInputValueConnection';
  /** A list of edges. */
  edges: Array<ExReportFormInputValueEdge>;
  /** A list of nodes. */
  nodes: Array<ExReportFormInputValue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExReportFormInputValueEdge = {
  __typename?: 'ExReportFormInputValueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExReportFormInputValue;
};

/** 申請の検索用ステータス */
export enum ExReportSearchStatus {
  /** 承認済み */
  Approved = 'approved',
  /** キャンセル */
  Canceled = 'canceled',
  /** 差し戻し */
  Rejected = 'rejected',
  /** 下書き */
  Unsubmitted = 'unsubmitted',
  /** 承認待ち */
  Waiting = 'waiting',
  /** 第8承認待ち */
  WaitingStepEight = 'waiting_step_eight',
  /** 第5承認待ち */
  WaitingStepFive = 'waiting_step_five',
  /** 第4承認待ち */
  WaitingStepFour = 'waiting_step_four',
  /** 第9承認待ち */
  WaitingStepNine = 'waiting_step_nine',
  /** 第1承認待ち */
  WaitingStepOne = 'waiting_step_one',
  /** 第7承認待ち */
  WaitingStepSeven = 'waiting_step_seven',
  /** 第6承認待ち */
  WaitingStepSix = 'waiting_step_six',
  /** 第10承認待ち */
  WaitingStepTen = 'waiting_step_ten',
  /** 第3承認待ち */
  WaitingStepThree = 'waiting_step_three',
  /** 第2承認待ち */
  WaitingStepTwo = 'waiting_step_two'
}

/** 申請のステータス */
export enum ExReportStatus {
  /** 承認済み */
  Approved = 'approved',
  /** キャンセル */
  Canceled = 'canceled',
  /** 差し戻し */
  Rejected = 'rejected',
  /** 下書き */
  Unsubmitted = 'unsubmitted',
  /** 第8承認待ち */
  WaitingStepEight = 'waiting_step_eight',
  /** 第5承認待ち */
  WaitingStepFive = 'waiting_step_five',
  /** 第4承認待ち */
  WaitingStepFour = 'waiting_step_four',
  /** 第9承認待ち */
  WaitingStepNine = 'waiting_step_nine',
  /** 第1承認待ち */
  WaitingStepOne = 'waiting_step_one',
  /** 第7承認待ち */
  WaitingStepSeven = 'waiting_step_seven',
  /** 第6承認待ち */
  WaitingStepSix = 'waiting_step_six',
  /** 第10承認待ち */
  WaitingStepTen = 'waiting_step_ten',
  /** 第3承認待ち */
  WaitingStepThree = 'waiting_step_three',
  /** 第2承認待ち */
  WaitingStepTwo = 'waiting_step_two'
}

/** 申請のタイムスタンプステータス */
export enum ExReportTimestampStatus {
  /** 作成中 */
  Creating = 'creating',
  /** 完了 */
  Done = 'done'
}

export type ExReportType = {
  __typename?: 'ExReportType';
  /** 申請フォーム入力項目 */
  exReportFormInputs: ExReportFormInputConnection;
  /** 編集権限設定 の form */
  exReportTypeEditControlForm: ReportTypeEditControlForm;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 申請の種類 */
  kind: ExReportTypeKind;
  /** 申請名 */
  name: Scalars['String']['output'];
};


export type ExReportTypeExReportFormInputsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ExReportType. */
export type ExReportTypeConnection = {
  __typename?: 'ExReportTypeConnection';
  /** A list of edges. */
  edges: Array<ExReportTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ExReportType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExReportTypeEdge = {
  __typename?: 'ExReportTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExReportType;
};

/** 申請の種類 */
export enum ExReportTypeKind {
  /** 経費申請 */
  Expense = 'expense',
  /** 事前・各種申請 */
  General = 'general',
  /** 仮払申請 */
  SuspensePayment = 'suspense_payment'
}

/** 申請の件数を返す */
export type ExReportsCount = {
  __typename?: 'ExReportsCount';
  /** 差し戻しの件数 */
  rejected: Scalars['Int']['output'];
  /** 下書きの件数 */
  unsubmitted: Scalars['Int']['output'];
  /** 承認待ちの件数 */
  waiting: Scalars['Int']['output'];
};

/** クラウド経費の特例区分 */
export type ExSpecialExceptionStatus = {
  __typename?: 'ExSpecialExceptionStatus';
  /** 摘要文言 */
  customRemark: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 特例区分名称 */
  name: Scalars['String']['output'];
};

/** The connection type for ExSpecialExceptionStatus. */
export type ExSpecialExceptionStatusConnection = {
  __typename?: 'ExSpecialExceptionStatusConnection';
  /** A list of edges. */
  edges: Array<ExSpecialExceptionStatusEdge>;
  /** A list of nodes. */
  nodes: Array<ExSpecialExceptionStatus>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExSpecialExceptionStatusEdge = {
  __typename?: 'ExSpecialExceptionStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExSpecialExceptionStatus;
};

/** expense transaction */
export type ExTransaction = {
  __typename?: 'ExTransaction';
  /** Credit Account */
  crItem?: Maybe<Item>;
  /** Default Sub Credit Account */
  crSubItem?: Maybe<SubItem>;
  /** Department Information */
  dept?: Maybe<Dept>;
  /** Expense Account */
  exItem?: Maybe<ExItem>;
  /** Invoice Transaction */
  exTransactionInvoiceValue?: Maybe<ExTransactionInvoiceValue>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Transaction Number */
  number: Scalars['Int']['output'];
  /** Information About The Project Code */
  projectCode?: Maybe<ProjectCode>;
  /** Recognized Date */
  recognizedAt: Scalars['ISO8601DateTime']['output'];
  /** Remark */
  remark: Scalars['String']['output'];
  /** office_member who owns the resource */
  resourceOwner: OfficeMember;
  /** Amount */
  value: Scalars['BigDecimal']['output'];
};

/** 明細カスタム項目 */
export type ExTransactionCustomField = {
  __typename?: 'ExTransactionCustomField';
  /** カスタム項目種別 */
  fieldType: ExTransactionCustomFieldField;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 表示設定 */
  isVisible: Scalars['Boolean']['output'];
  /** 明細カスタム項目名 */
  label: Scalars['String']['output'];
  /** 表示順 */
  priority: Scalars['Int']['output'];
  /** 汎用マスタ */
  userDefinedMaster?: Maybe<UserDefinedMaster>;
};

/** Autogenerated input type of ExTransactionCustomFieldBulkUpdatePriority */
export type ExTransactionCustomFieldBulkUpdatePriorityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 明細カスタム項目の表示順に並べたID配列 */
  exTransactionCustomFieldIds: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of ExTransactionCustomFieldBulkUpdatePriority. */
export type ExTransactionCustomFieldBulkUpdatePriorityPayload = {
  __typename?: 'ExTransactionCustomFieldBulkUpdatePriorityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exTransactionCustomFieldIds: Array<Scalars['String']['output']>;
};

/** The connection type for ExTransactionCustomField. */
export type ExTransactionCustomFieldConnection = {
  __typename?: 'ExTransactionCustomFieldConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExTransactionCustomFieldEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExTransactionCustomField>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of ExTransactionCustomFieldCreate */
export type ExTransactionCustomFieldCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** カスタム項目種別 */
  fieldType: ExTransactionCustomFieldField;
  /** 表示設定 */
  isVisible: Scalars['Boolean']['input'];
  /** カスタム項目名 */
  label: Scalars['String']['input'];
  /** 汎用マスタのID */
  userDefinedMasterId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ExTransactionCustomFieldCreate. */
export type ExTransactionCustomFieldCreatePayload = {
  __typename?: 'ExTransactionCustomFieldCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exTransactionCustomField: ExTransactionCustomField;
};

/** Autogenerated input type of ExTransactionCustomFieldDestroy */
export type ExTransactionCustomFieldDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 明細カスタム項目のID */
  exTransactionCustomFieldId: Scalars['String']['input'];
};

/** Autogenerated return type of ExTransactionCustomFieldDestroy. */
export type ExTransactionCustomFieldDestroyPayload = {
  __typename?: 'ExTransactionCustomFieldDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exTransactionCustomFieldId: Scalars['String']['output'];
};

/** An edge in a connection. */
export type ExTransactionCustomFieldEdge = {
  __typename?: 'ExTransactionCustomFieldEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ExTransactionCustomField>;
};

/** 明細カスタム項目種別 */
export enum ExTransactionCustomFieldField {
  /** 添付ファイル */
  MfFile = 'mf_file',
  /** カスタムメモ */
  Text = 'text',
  /** 汎用マスタ */
  UserDefinedMaster = 'user_defined_master'
}

/** Autogenerated input type of ExTransactionCustomFieldUpdate */
export type ExTransactionCustomFieldUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 明細カスタム項目のID */
  exTransactionCustomFieldId: Scalars['String']['input'];
  /** 表示設定 */
  isVisible: Scalars['Boolean']['input'];
  /** カスタム項目名 */
  label: Scalars['String']['input'];
};

/** Autogenerated return type of ExTransactionCustomFieldUpdate. */
export type ExTransactionCustomFieldUpdatePayload = {
  __typename?: 'ExTransactionCustomFieldUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exTransactionCustomField: ExTransactionCustomField;
};

/** 関連明細 */
export type ExTransactionFamily = {
  __typename?: 'ExTransactionFamily';
  /** 経費科目 */
  exItemName?: Maybe<Scalars['String']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['Int']['output'];
  /** 支払先・内容 */
  remark?: Maybe<Scalars['String']['output']>;
  /** 金額 */
  yenValue?: Maybe<Scalars['BigDecimal']['output']>;
};

/** Invoice Transaction */
export type ExTransactionInvoiceValue = {
  __typename?: 'ExTransactionInvoiceValue';
  /** Excise Value */
  exciseValue: Scalars['Int']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Qualified invoice issuer registration number */
  invoiceRegistrationNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Office */
  office: Office;
};

/** 経費申請 */
export type ExpenseReport = {
  __typename?: 'ExpenseReport';
  /** 承認日時 */
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 編集画面へのpath */
  editPath: Scalars['String']['output'];
  /** 入力項目一覧 */
  exReportFormInputValues: ExReportFormInputValueConnection;
  /** 申請タイプ */
  exReportType?: Maybe<ReportType>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['String']['output'];
  /** 申請した従業員 */
  officeMember: OfficeMember;
  /** ステータス */
  status: ExReportStatus;
  /** 申請日時 */
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** タイムスタンプステータス */
  timestampStatus?: Maybe<ExReportTimestampStatus>;
  /** タイトル */
  title: Scalars['String']['output'];
};


/** 経費申請 */
export type ExpenseReportExReportFormInputValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ExpenseReport. */
export type ExpenseReportConnection = {
  __typename?: 'ExpenseReportConnection';
  /** A list of edges. */
  edges: Array<ExpenseReportEdge>;
  /** A list of nodes. */
  nodes: Array<ExpenseReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExpenseReportEdge = {
  __typename?: 'ExpenseReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ExpenseReport;
};

/** 事業者が利用できる機能 */
export type Feature = {
  __typename?: 'Feature';
  /** 利用日当設定 */
  isEnabledExDailyPay: Scalars['Boolean']['output'];
  /** 明細カスタム項目 */
  isEnabledExTransactionCustomField: Scalars['Boolean']['output'];
  /** 汎用マスタ */
  isEnabledUserDefinedMaster: Scalars['Boolean']['output'];
};

/** 事前・各種申請 */
export type GeneralReport = {
  __typename?: 'GeneralReport';
  /** 承認日時 */
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 編集画面へのpath */
  editPath: Scalars['String']['output'];
  /** 入力項目一覧 */
  exReportFormInputValues: ExReportFormInputValueConnection;
  /** 申請タイプ */
  exReportType?: Maybe<ReportType>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['String']['output'];
  /** 申請した従業員 */
  officeMember: OfficeMember;
  /** ステータス */
  status: ExReportStatus;
  /** 申請日時 */
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** タイムスタンプステータス */
  timestampStatus?: Maybe<ExReportTimestampStatus>;
  /** タイトル */
  title: Scalars['String']['output'];
};


/** 事前・各種申請 */
export type GeneralReportExReportFormInputValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for GeneralReport. */
export type GeneralReportConnection = {
  __typename?: 'GeneralReportConnection';
  /** A list of edges. */
  edges: Array<GeneralReportEdge>;
  /** A list of nodes. */
  nodes: Array<GeneralReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GeneralReportEdge = {
  __typename?: 'GeneralReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GeneralReport;
};

export type GroupedTransaction = {
  __typename?: 'GroupedTransaction';
  /** jtb transaction tour no */
  tourNo: Scalars['String']['output'];
  /** JTB transactions grouped by tour number */
  transactions: Array<ExBusinessTravelJtbTransaction>;
};

/** The connection type for GroupedTransaction. */
export type GroupedTransactionConnection = {
  __typename?: 'GroupedTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupedTransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<GroupedTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GroupedTransactionEdge = {
  __typename?: 'GroupedTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GroupedTransaction>;
};

/** Autogenerated input type of IgnoreExBusinessTravelJtbTransaction */
export type IgnoreExBusinessTravelJtbTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the ExBusinessTravelJtbTransaction to be updated to ignored */
  exBusinessTravelJtbTransactionIds: Array<Scalars['ID']['input']>;
  /** (optional) Updated Memo Content */
  memo?: InputMaybe<Scalars['String']['input']>;
  /** ID of the office member performing the ignore operation */
  officeMemberId: Scalars['ID']['input'];
};

/** Autogenerated return type of IgnoreExBusinessTravelJtbTransaction. */
export type IgnoreExBusinessTravelJtbTransactionPayload = {
  __typename?: 'IgnoreExBusinessTravelJtbTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** ExBusinessTravelJtbTransaction that has been updated with the memo. */
  exBusinessTravelJtbTransactions: Array<ExBusinessTravelJtbTransaction>;
};

/** 支払依頼 */
export type InvoiceReport = {
  __typename?: 'InvoiceReport';
  /** 承認日時 */
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 編集画面へのpath */
  editPath: Scalars['String']['output'];
  /** 入力項目一覧 */
  exReportFormInputValues: ExReportFormInputValueConnection;
  /** 申請タイプ */
  exReportType?: Maybe<ReportType>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['String']['output'];
  /** 申請した従業員 */
  officeMember: OfficeMember;
  /** ステータス */
  status: ExReportStatus;
  /** 申請日時 */
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** タイムスタンプステータス */
  timestampStatus?: Maybe<ExReportTimestampStatus>;
  /** タイトル */
  title: Scalars['String']['output'];
};


/** 支払依頼 */
export type InvoiceReportExReportFormInputValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for InvoiceReport. */
export type InvoiceReportConnection = {
  __typename?: 'InvoiceReportConnection';
  /** A list of edges. */
  edges: Array<InvoiceReportEdge>;
  /** A list of nodes. */
  nodes: Array<InvoiceReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InvoiceReportEdge = {
  __typename?: 'InvoiceReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: InvoiceReport;
};

/** 勘定科目 */
export type Item = {
  __typename?: 'Item';
  /** コード */
  code?: Maybe<Scalars['String']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 名称 */
  name: Scalars['String']['output'];
};

/** The connection type for Item. */
export type ItemConnection = {
  __typename?: 'ItemConnection';
  /** A list of edges. */
  edges: Array<ItemEdge>;
  /** A list of nodes. */
  nodes: Array<Item>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ItemEdge = {
  __typename?: 'ItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Item;
};

/** Jtb Transaction status */
export enum JtbTransactionStatus {
  /** cancel waiting */
  CancelWaiting = 'cancel_waiting',
  /** canceled */
  Canceled = 'canceled',
  /** cancellation refund fee */
  CancellationRefundFee = 'cancellation_refund_fee',
  /** last checked */
  LastChecked = 'last_checked',
  /** others */
  Others = 'others',
  /** reservation ok */
  ReservationOk = 'reservation_ok',
  /** ticket delivered */
  TicketDelivered = 'ticket_delivered'
}

/** Kotaro information */
export type Kotaro = {
  __typename?: 'Kotaro';
  /** Parameters required to display "こたろうくん" */
  parameters: Scalars['JSON']['output'];
  /** URL of the widget script that is delivered as static assets from rails */
  scriptUrl: Scalars['String']['output'];
};

/** サービスで利用可能な言語 */
export enum Language {
  /** 英語 */
  En = 'en',
  /** 日本語 */
  Ja = 'ja'
}

/** 会計サービス連携の設定 */
export type LinkExternalAccountingSetting = {
  __typename?: 'LinkExternalAccountingSetting';
  /** 連携先 */
  accountingService: AccountingService;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 仕訳の連携をするかどうか */
  isLinkJournal: Scalars['Boolean']['output'];
};

/** 登録済み領収書 */
export type MfFileReferencesExTransaction = {
  __typename?: 'MfFileReferencesExTransaction';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['String']['output'];
  /** 日付 */
  recognizedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  /** 支払先・内容 */
  remark?: Maybe<Scalars['String']['output']>;
  /** 金額 */
  yenValue?: Maybe<Scalars['BigDecimal']['output']>;
};

export type MfcServiceSsoUrl = {
  __typename?: 'MfcServiceSsoUrl';
  /** クラウド固定資産 への URL。current_officeが nil の場合 nil となる */
  aaSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 債務支払へのSSO URL。current_officeがnilの場合nilとなる */
  apSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド請求書Plus への URL。current_officeが nil の場合 nil となる */
  armSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウドBoxへのSSO URL。current_officeがnilの場合nilとなる */
  boxSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 会計PlusへのSSO URL。current_officeがnilの場合nilとなる */
  caMidSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 会計へのSSO URL。current_officeがnilの場合nilとなる */
  caSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド連結会計 への URL。current_officeが nil の場合 nil となる */
  conacSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド人事管理 への URL。current_officeが nil の場合 nil となる */
  connectedDbSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 経費へのSSO URL。current_officeがnilの場合nilとなる */
  exSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 請求書へのSSO URL。current_officeがnilの場合nilとなる */
  ivSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 勤怠へのSSO URL。current_officeがnilの場合nilとなる */
  mfAttendanceSsoUrl?: Maybe<Scalars['String']['output']>;
  /** マイページ への URL。current_officeが nil の場合 nil となる */
  myPageSsoUrl?: Maybe<Scalars['String']['output']>;
  /** マイナンバーへのSSO URL。current_officeがnilの場合nilとなる */
  mySsoUrl?: Maybe<Scalars['String']['output']>;
  /** 給与へのSSO URL。current_officeがnilの場合nilとなる */
  paSsoUrl?: Maybe<Scalars['String']['output']>;
  /** Pay for Business への URL。current_officeが nil の場合 nil となる */
  payForBusinessSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド個別原価 への URL。current_officeが nil の場合 nil となる */
  pjcSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウドインボイスへのSSO URL。current_officeがnilの場合nilとなる */
  rciSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド債権管理 への URL。current_officeが nil の場合 nil となる */
  receivableManagementSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウドインボイス[送付] への URL。current_officeが nil の場合 nil となる */
  sciSsoUrl?: Maybe<Scalars['String']['output']>;
  /** 社会保険へのSSO URL。current_officeがnilの場合nilとなる */
  siSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド契約 への URL。current_officeが nil の場合 nil となる */
  stamplessSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド年末調整 への URL。current_officeが nil の場合 nil となる */
  taSsoUrl?: Maybe<Scalars['String']['output']>;
  /** クラウド確定申告 への URL。current_officeが nil の場合 nil となる */
  taxReturnSsoUrl?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** NotificationTimelineの既読処理 */
  bulkCheckNotificationTimelines?: Maybe<BulkCheckReadNotificationTimelinesPayload>;
  /** マスタデータの削除 */
  bulkDestroyUserDefinedMasterItem?: Maybe<UserDefinedMasterItemBulkDestroyPayload>;
  /** 明細カスタム項目の表示優先順の一括更新 */
  bulkUpdatePriorityExTransactionCustomField?: Maybe<ExTransactionCustomFieldBulkUpdatePriorityPayload>;
  /** 申請を複製する */
  copyExReport?: Maybe<CopyExReportPayload>;
  /** Create dept. */
  createDept: CreateDeptPayload;
  /** JTB連携アカウントを作成する */
  createExBusinessTravelJtbAccount: CreateExBusinessTravelJtbAccountPayload;
  /** 明細カスタム項目の作成 */
  createExTransactionCustomField?: Maybe<ExTransactionCustomFieldCreatePayload>;
  /** 支払元口座を作成する */
  createOfficeAccount?: Maybe<CreateOfficeAccountPayload>;
  /** 振込データAPI連携担当者を作成する */
  createOfficeMemberOfficeAccount?: Maybe<CreateOfficeMemberOfficeAccountPayload>;
  /** Create position. */
  createPosition: CreatePositionPayload;
  /** Create project code. */
  createProjectCode: CreateProjectCodePayload;
  /** アクセストークンを発行、期限の延長 */
  createSession?: Maybe<CreateSessionPayload>;
  /** Destroy dept. (Both single and bulk destroy operations are possible) */
  destroyDept: DestroyDeptPayload;
  /** JTB連携サービスの連携中止 */
  destroyExBusinessTravelJtbAccount: DestroyExBusinessTravelJtbAccountPayload;
  /** 明細カスタム項目の削除 */
  destroyExTransactionCustomField?: Maybe<ExTransactionCustomFieldDestroyPayload>;
  /** ファイルを削除する */
  destroyMfFile?: Maybe<DestroyMfFilePayload>;
  /** 従業員に対して設定するJTB_USERのアカウントの削除 */
  destroyOfficeMemberExJtbUserAccount: DestroyOfficeMemberExJtbUserAccountPayload;
  /** 振込データAPI連携担当者を削除する */
  destroyOfficeMemberOfficeAccount?: Maybe<DestroyPayload>;
  /** Destroy position. */
  destroyPosition: DestroyPositionPayload;
  /** Destroy project code. (Both single and bulk destroy operations are possible) */
  destroyProjectCode: DestroyProjectCodePayload;
  /** セッションを削除して従業員のログアウトを行う */
  destroySession: DestroySessionPayload;
  /** JTB_USERのアカウントに対して設定するその他の申請可能な従業員の削除 */
  destroySubOfficeMemberExJtbUserAccount: DestroySubOfficeMemberExJtbUserAccountPayload;
  /** Update status of ex_business_travel_transaction_infos associated with the ex_business_travel_jtb_transactions to ignored. */
  ignoreExBusinessTravelJtbTransaction: IgnoreExBusinessTravelJtbTransactionPayload;
  /** 部門毎の経費科目設定の更新 */
  updateApActiveExDeptExItem?: Maybe<UpdateApActiveExDeptExItemPayload>;
  /** クラウド債務支払の消費税端数処理方法を更新する */
  updateApOfficeExciseSetting?: Maybe<UpdateApOfficeExciseSettingPayload>;
  /** クラウド債務支払の特例区分の更新 */
  updateApSpecialExceptionStatus?: Maybe<UpdateApSpecialExceptionStatusPayload>;
  /** Update dept. */
  updateDept: UpdateDeptPayload;
  /** JTB連携アカウントの更新 */
  updateExBusinessTravelJtbAccount: UpdateExBusinessTravelJtbAccountPayload;
  /** Update the memo in the ex_business_travel_transaction_info record associated with the ex_business_travel_jtb_transactions. */
  updateExBusinessTravelJtbTransactionMemo: UpdateMemoExBusinessTravelJtbTransactionPayload;
  updateExFunctionPrompt?: Maybe<UpdateExFunctionPromptPayload>;
  /** メール通知設定の一括更新 */
  updateExMailSettings: UpdateExMailSettingsPayload;
  /** クラウド経費の消費税端数処理方法を更新する */
  updateExOfficeExciseSetting?: Maybe<UpdateExOfficeExciseSettingPayload>;
  /** 事業者毎の外貨設定を更新する */
  updateExOfficeForeignCurrencySetting?: Maybe<UpdateExOfficeForeignCurrencySettingPayload>;
  /** プッシュ通知設定の更新 */
  updateExPushSettings: UpdateExPushSettingsPayload;
  /** クラウド経費の特例区分の更新 */
  updateExSpecialExceptionStatus?: Maybe<UpdateExSpecialExceptionStatusPayload>;
  /** 明細カスタム項目の更新 */
  updateExTransactionCustomField?: Maybe<ExTransactionCustomFieldUpdatePayload>;
  /** メール通知設定の一括更新 */
  updateNotificationSettings: UpdateNotificationSettingsPayload;
  /** 支払元口座を更新する */
  updateOfficeAccount?: Maybe<UpdatePayload>;
  /** 従業員に対して設定するJTB_USERのアカウントの更新 */
  updateOfficeMemberExJtbUserAccount: UpdateOfficeMemberExJtbUserAccountPayload;
  /** 仮払設定を更新する */
  updateOfficeSuspensePaymentSetting?: Maybe<UpdateOfficeSuspensePaymentSettingPayload>;
  /** Update position. */
  updatePosition: UpdatePositionPayload;
  /** Update project code. */
  updateProjectCode: UpdateProjectCodePayload;
  /** 申請フォームの編集権限設定を更新する */
  updateReportTypeEditControls?: Maybe<UpdateReportTypeEditControlsPayload>;
  /** 振込手数料の控除額の設定を更新する */
  updateTransferFeeRules?: Maybe<UpdateTransferFeeRulesPayload>;
  /** 事業者切り替えを行う */
  updateUserStatus?: Maybe<UpdateUserStatusPayload>;
};


export type MutationBulkCheckNotificationTimelinesArgs = {
  input: BulkCheckReadNotificationTimelinesInput;
};


export type MutationBulkDestroyUserDefinedMasterItemArgs = {
  input: UserDefinedMasterItemBulkDestroyInput;
};


export type MutationBulkUpdatePriorityExTransactionCustomFieldArgs = {
  input: ExTransactionCustomFieldBulkUpdatePriorityInput;
};


export type MutationCopyExReportArgs = {
  input: CopyExReportInput;
};


export type MutationCreateDeptArgs = {
  input: CreateDeptInput;
};


export type MutationCreateExBusinessTravelJtbAccountArgs = {
  input: CreateExBusinessTravelJtbAccountInput;
};


export type MutationCreateExTransactionCustomFieldArgs = {
  input: ExTransactionCustomFieldCreateInput;
};


export type MutationCreateOfficeAccountArgs = {
  input: CreateOfficeAccountInput;
};


export type MutationCreateOfficeMemberOfficeAccountArgs = {
  input: CreateOfficeMemberOfficeAccountInput;
};


export type MutationCreatePositionArgs = {
  input: CreatePositionInput;
};


export type MutationCreateProjectCodeArgs = {
  input: CreateProjectCodeInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationDestroyDeptArgs = {
  input: DestroyDeptInput;
};


export type MutationDestroyExBusinessTravelJtbAccountArgs = {
  input: DestroyExBusinessTravelJtbAccountInput;
};


export type MutationDestroyExTransactionCustomFieldArgs = {
  input: ExTransactionCustomFieldDestroyInput;
};


export type MutationDestroyMfFileArgs = {
  input: DestroyMfFileInput;
};


export type MutationDestroyOfficeMemberExJtbUserAccountArgs = {
  input: DestroyOfficeMemberExJtbUserAccountInput;
};


export type MutationDestroyOfficeMemberOfficeAccountArgs = {
  input: DestroyInput;
};


export type MutationDestroyPositionArgs = {
  input: DestroyPositionInput;
};


export type MutationDestroyProjectCodeArgs = {
  input: DestroyProjectCodeInput;
};


export type MutationDestroySessionArgs = {
  input: DestroySessionInput;
};


export type MutationDestroySubOfficeMemberExJtbUserAccountArgs = {
  input: DestroySubOfficeMemberExJtbUserAccountInput;
};


export type MutationIgnoreExBusinessTravelJtbTransactionArgs = {
  input: IgnoreExBusinessTravelJtbTransactionInput;
};


export type MutationUpdateApActiveExDeptExItemArgs = {
  input: UpdateApActiveExDeptExItemInput;
};


export type MutationUpdateApOfficeExciseSettingArgs = {
  input: UpdateApOfficeExciseSettingInput;
};


export type MutationUpdateApSpecialExceptionStatusArgs = {
  input: UpdateApSpecialExceptionStatusInput;
};


export type MutationUpdateDeptArgs = {
  input: UpdateDeptInput;
};


export type MutationUpdateExBusinessTravelJtbAccountArgs = {
  input: UpdateExBusinessTravelJtbAccountInput;
};


export type MutationUpdateExBusinessTravelJtbTransactionMemoArgs = {
  input: UpdateMemoExBusinessTravelJtbTransactionInput;
};


export type MutationUpdateExFunctionPromptArgs = {
  input: UpdateExFunctionPromptInput;
};


export type MutationUpdateExMailSettingsArgs = {
  input: UpdateExMailSettingsInput;
};


export type MutationUpdateExOfficeExciseSettingArgs = {
  input: UpdateExOfficeExciseSettingInput;
};


export type MutationUpdateExOfficeForeignCurrencySettingArgs = {
  input: UpdateExOfficeForeignCurrencySettingInput;
};


export type MutationUpdateExPushSettingsArgs = {
  input: UpdateExPushSettingsInput;
};


export type MutationUpdateExSpecialExceptionStatusArgs = {
  input: UpdateExSpecialExceptionStatusInput;
};


export type MutationUpdateExTransactionCustomFieldArgs = {
  input: ExTransactionCustomFieldUpdateInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput;
};


export type MutationUpdateOfficeAccountArgs = {
  input: UpdateInput;
};


export type MutationUpdateOfficeMemberExJtbUserAccountArgs = {
  input: UpdateOfficeMemberExJtbUserAccountInput;
};


export type MutationUpdateOfficeSuspensePaymentSettingArgs = {
  input: UpdateOfficeSuspensePaymentSettingInput;
};


export type MutationUpdatePositionArgs = {
  input: UpdatePositionInput;
};


export type MutationUpdateProjectCodeArgs = {
  input: UpdateProjectCodeInput;
};


export type MutationUpdateReportTypeEditControlsArgs = {
  input: UpdateReportTypeEditControlsInput;
};


export type MutationUpdateTransferFeeRulesArgs = {
  input: UpdateTransferFeeRulesInput;
};


export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};

/** ホーム画面・ヘッダーのお知らせアイコンに表示するお知らせ情報 */
export type News = {
  __typename?: 'News';
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 強調するかどうか */
  isEmphasis: Scalars['Boolean']['output'];
  /** お知らせメッセージ */
  message: Scalars['String']['output'];
  /** メッセージのリンク先 */
  messageLinkUrl: Scalars['String']['output'];
  /** 掲載日 */
  publicationDate: Scalars['ISO8601Date']['output'];
  /** 更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for News. */
export type NewsConnection = {
  __typename?: 'NewsConnection';
  /** A list of edges. */
  edges: Array<NewsEdge>;
  /** A list of nodes. */
  nodes: Array<News>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NewsEdge = {
  __typename?: 'NewsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: News;
};

/** 通知媒体毎の通知設定 */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  /** 申請の承認依頼通知の設定 */
  approvableReport: BaseNotificationSetting;
  /** 締め日アラートの通知設定。このフィールドは ex のみに使用されます。ap からこの値を更新することはできません。 */
  closingAlert: ClosingAlertNotificationSetting;
  /** オペレーター入力完了の通知設定。このフィールドは ex のみに使用されます。ap からこの値を更新することはできません。 */
  operatorInput: BaseNotificationSetting;
  /** 申請のチャットコメントの設定 */
  reportTimeline: BaseNotificationSetting;
  /** 申請の承認通知の設定 */
  workflow: WorkflowNotificationSetting;
};

/** 通知媒体毎の通知設定の更新内容 */
export type NotificationSettingInput = {
  /** 申請の承認依頼通知設定の更新内容 */
  approvableReport?: InputMaybe<BaseNotificationSettingInput>;
  /** 締日アラートメール通知設定の更新内容。このフィールドは ex のみに使用されます。ap からこの値を更新することはできません。 */
  closingAlert?: InputMaybe<ClosingAlertNotificationSettingInput>;
  /** オペレーター入力完了メール通知設定の更新内容。このフィールドは ex のみに使用されます。ap からこの値を更新することはできません。 */
  operatorInput?: InputMaybe<BaseNotificationSettingInput>;
  /** チャットサービスのメール通知設定の更新内容 */
  reportTimeline?: InputMaybe<BaseNotificationSettingInput>;
  /** 承認完了メール通知設定の更新内容 */
  workflow?: InputMaybe<WorkflowNotificationSettingInput>;
};

/** 通知設定 */
export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  /** メール通知設定 */
  mail: NotificationSetting;
  /** プッシュ通知設定。このフィールドは ex のみで使用されます。ap はこのフィールドを取得出来ません。 */
  push: NotificationSetting;
};

/** 通知タイムライン */
export type NotificationTimeline = {
  __typename?: 'NotificationTimeline';
  /** カテゴリ */
  category: NotificationTimelineCategoryEnum;
  /** 詳細メッセージ */
  content: Scalars['String']['output'];
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 既読かどうか */
  isRead: Scalars['Boolean']['output'];
  /** 従業員情報 */
  officeMember: OfficeMember;
  /** 通知タイトル */
  title: Scalars['String']['output'];
  /** タイトルのリンク先 */
  titleLinkUrl: Scalars['String']['output'];
  /** 更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** 通知カテゴリ */
export enum NotificationTimelineCategoryEnum {
  Approval = 'approval',
  AsyncExport = 'async_export',
  AsyncImport = 'async_import',
  BankApiRequest = 'bank_api_request',
  General = 'general',
  LinePayDepositForAdmin = 'line_pay_deposit_for_admin',
  LinePayDepositForMember = 'line_pay_deposit_for_member',
  ReceiveInvoice = 'receive_invoice',
  Report = 'report',
  SaveJournalForReportUnit = 'save_journal_for_report_unit'
}

/** The connection type for NotificationTimeline. */
export type NotificationTimelineConnection = {
  __typename?: 'NotificationTimelineConnection';
  /** A list of edges. */
  edges: Array<NotificationTimelineEdge>;
  /** A list of nodes. */
  nodes: Array<NotificationTimeline>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NotificationTimelineEdge = {
  __typename?: 'NotificationTimelineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: NotificationTimeline;
};

/** 通知タイムラインのリスト */
export type NotificationTimelineList = {
  __typename?: 'NotificationTimelineList';
  /** 通知タイムライン */
  items: Array<NotificationTimeline>;
  /** 総件数 */
  totalCount: Scalars['Int']['output'];
};

/** 通知タイムラインの検索条件 */
export type NotificationTimelineSearchInput = {
  /** 通知カテゴリ */
  category?: InputMaybe<NotificationTimelineCategoryEnum>;
  /** 日付 */
  dateRange?: InputMaybe<DateRangeInput>;
  /** キーワード */
  keyword?: InputMaybe<Scalars['String']['input']>;
  /** ステータス */
  status?: InputMaybe<NotificationTimelineStatusEnum>;
};

/** ステータス */
export enum NotificationTimelineStatusEnum {
  All = 'all',
  Checked = 'checked',
  Unchecked = 'unchecked'
}

/** クラウド経費の事業者 */
export type Office = {
  __typename?: 'Office';
  /** 部門毎の経費科目設定 */
  apActiveExDeptExItems: Array<ApActiveExDeptExItem>;
  /** 消費税端数処理方法 */
  apOfficeExciseSetting: ApOfficeExciseSetting;
  /** 事業者設定 */
  apOfficeSetting?: Maybe<ApOfficeSetting>;
  /** 特例区分 */
  apSpecialExceptionStatuses: ApSpecialExceptionStatusConnection;
  /** Cached office member total count (caching time is 10 minutes) */
  cachedOfficeMemberCount: Scalars['Int']['output'];
  /** 勘定科目 */
  crItems: ItemConnection;
  /** 補助科目 */
  crSubItems: ItemConnection;
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** List of departments held by the office */
  deptList: DeptList;
  /** 部門データ */
  depts: DeptConnection;
  /** Electronic Bookkeeping law setting (電子帳簿保存法設定) */
  eDocPlan: EDocPlan;
  /** JTBアカウント */
  exBusinessTravelJtbAccount?: Maybe<ExBusinessTravelJtbAccount>;
  /** JTBアカウント */
  exBusinessTravelJtbAccounts: ExBusinessTravelJtbAccountConnection;
  /** 経費科目 */
  exItem: ExItem;
  /** 経費科目 */
  exItems: ExItemConnection;
  /** 消費税端数処理方法 */
  exOfficeExciseSetting: ExOfficeExciseSetting;
  /** 事業者者毎の外貨設定 */
  exOfficeForeignCurrencySetting?: Maybe<ExOfficeForeignCurrencySetting>;
  /** 事業者設定 */
  exOfficeSetting?: Maybe<ExOfficeSetting>;
  /** クラウド経費の加入プラン */
  exPlan: ExPlan;
  /**
   * プラン名
   * @deprecated 経費と債務で同じクエリで料金プランを取得できるようにするため、今後はplan_nameの方を使ってください
   */
  exPremiumPlanName: Scalars['String']['output'];
  /** 申請 */
  exReport: ExReport;
  /** 指定IDの申請フォーム */
  exReportType: ExReportType;
  /** 全ての申請フォーム */
  exReportTypes: ExReportTypeConnection;
  /** 特例区分 */
  exSpecialExceptionStatuses: ExSpecialExceptionStatusConnection;
  /** 明細カスタム項目 */
  exTransactionCustomFields: ExTransactionCustomFieldConnection;
  /** 経費申請の ex_report_type */
  expenseReportType: ExpenseReport;
  /** 事業者が利用できる機能 */
  feature: Feature;
  /** 事前・各種申請の申請フォーム */
  generalReportTypes: ExReportTypeConnection;
  /** 事前・各種申請の申請フォーム（「定期区間」・「払戻口座」を除く） */
  generalReportTypesWithoutRegisterToMasterType: ExReportTypeConnection;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 事業者番号（8桁） */
  identificationCode: Scalars['String']['output'];
  /** 支払依頼の ex_report_type */
  invoiceReportType: InvoiceReport;
  /** 事業者がサービス利用開始済かつサービスの退会をしていない */
  isActive: Scalars['Boolean']['output'];
  /** クレカ課金かどうか */
  isExGmoPaymentActive: Scalars['Boolean']['output'];
  /** 有料プラン事業者かどうか */
  isExPremium: Scalars['Boolean']['output'];
  /** 課金有料プランかどうか（トライアルは除く） */
  isExPremiumExceptFreeTrial: Scalars['Boolean']['output'];
  /** 無料期間内かどうか */
  isInFreeTerm: Scalars['Boolean']['output'];
  /** トライアル中かどうか */
  isInTrial: Scalars['Boolean']['output'];
  /** パーソナルプランより上のプランか？ */
  isMoreThanPersonalPlan: Scalars['Boolean']['output'];
  /** チームプランより上のプランか？（コーポレートプラン以上か？） */
  isMoreThanTeamPlan: Scalars['Boolean']['output'];
  /** オペレーター入力使いたい放題かどうか */
  isOperatorInputLimitlessPlan?: Maybe<Scalars['Boolean']['output']>;
  /** 支払仕訳が利用可能かどうか */
  isPaymentJournalEnabled: Scalars['Boolean']['output'];
  /** 会計サービス連携の設定 */
  linkExternalAccountingSetting?: Maybe<LinkExternalAccountingSetting>;
  /** 未利用オペレーター入力チケット枚数 */
  notUsedExOperationTicketsCount: Scalars['Int']['output'];
  /** 支払元口座 */
  officeAccount: OfficeAccount;
  /** 事業者名 */
  officeName: Scalars['String']['output'];
  /** 掲示板 */
  officeNoticeBoards: OfficeNotificationBoardConnection;
  /** 仮払機能事業者設定 */
  officeSuspensePaymentSetting: OfficeSuspensePaymentSetting;
  /** プラン名 */
  planName: Scalars['String']['output'];
  /** List of positions held by the office */
  positionList: PositionList;
  /** List of project codes held by the office */
  projectCodeList: ProjectCodeList;
  /** 仮払申請の ex_report_type */
  suspensePaymentReportType: SuspensePaymentReport;
  /**
   * 事業者ID 管理者がログインIDを使った従業員を招待するときや従業員がログインする際に必要なIDです。事業者IDが未設定の場合はnullを返します。(TenantLoginID. This is required when administrator invites office members or office members
   * log in without email address. If tenant_login_id is not set, it returns null.)
   */
  tenantLoginId?: Maybe<Scalars['String']['output']>;
  /** 個人事業主（individual）, 法人（corporate） */
  type: OfficeTypeEnum;
  /** 更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** 汎用マスタ マスタデータ */
  userDefinedMasterItems: UserDefinedMasterItemConnection;
  /** 汎用マスタ */
  userDefinedMasters: UserDefinedMasterConnection;
};


/** クラウド経費の事業者 */
export type OfficeApActiveExDeptExItemsArgs = {
  exDeptId: Scalars['String']['input'];
};


/** クラウド経費の事業者 */
export type OfficeApSpecialExceptionStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeCrItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeCrSubItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  crItemId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeDeptListArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  searchCondition?: InputMaybe<DeptSearchInput>;
};


/** クラウド経費の事業者 */
export type OfficeDeptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeExBusinessTravelJtbAccountArgs = {
  exBusinessTravelJtbAccountId?: InputMaybe<Scalars['ID']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeExBusinessTravelJtbAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeExItemArgs = {
  exItemId: Scalars['ID']['input'];
};


/** クラウド経費の事業者 */
export type OfficeExItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeExReportArgs = {
  id: Scalars['ID']['input'];
};


/** クラウド経費の事業者 */
export type OfficeExReportTypeArgs = {
  id: Scalars['ID']['input'];
};


/** クラウド経費の事業者 */
export type OfficeExReportTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeExSpecialExceptionStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeExTransactionCustomFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeGeneralReportTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeGeneralReportTypesWithoutRegisterToMasterTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeIdentificationCodeArgs = {
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeOfficeAccountArgs = {
  officeAccountId: Scalars['ID']['input'];
};


/** クラウド経費の事業者 */
export type OfficeOfficeNoticeBoardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** クラウド経費の事業者 */
export type OfficePositionListArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  searchCondition?: InputMaybe<PositionSearchInput>;
};


/** クラウド経費の事業者 */
export type OfficeProjectCodeListArgs = {
  hasParent?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  searchCondition?: InputMaybe<ProjectCodeSearchInput>;
};


/** クラウド経費の事業者 */
export type OfficeUserDefinedMasterItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  exTransactionCustomFieldId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userDefinedMasterId?: InputMaybe<Scalars['ID']['input']>;
};


/** クラウド経費の事業者 */
export type OfficeUserDefinedMastersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** 支払元口座 */
export type OfficeAccount = {
  __typename?: 'OfficeAccount';
  /** 口座種別 */
  accountType: Scalars['String']['output'];
  /** 銀行 */
  bank?: Maybe<Bank>;
  /** 銀行支店 */
  bankBranch?: Maybe<BankBranch>;
  /** 振込担当者の設定 */
  bankLinks?: Maybe<Array<BankLink>>;
  /** 振込依頼人コード */
  fbCustomerCode?: Maybe<Scalars['String']['output']>;
  /** 名義人 */
  holderName: Scalars['String']['output'];
  /** 名義人カナ */
  holderNameKana: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 銀行APIが利用できるかどうか */
  isBankApiUseable: Scalars['Boolean']['output'];
  /** 口座番号 */
  number: Scalars['String']['output'];
  /** 支払仕訳の貸方勘定科目 */
  paymentJournalItem?: Maybe<Item>;
  /** 支払仕訳の貸方勘定科目ID */
  paymentJournalItemId?: Maybe<Scalars['ID']['output']>;
  /** 支払仕訳の貸方補助科目 */
  paymentJournalSubItem?: Maybe<SubItem>;
  /** 支払仕訳の貸方勘定補助科目ID */
  paymentJournalSubItemId?: Maybe<Scalars['ID']['output']>;
  /** 手数料控除額の設定 */
  transferFeeRules: Array<TransferFeeRule>;
};

/** The connection type for Office. */
export type OfficeConnection = {
  __typename?: 'OfficeConnection';
  /** A list of edges. */
  edges: Array<OfficeEdge>;
  /** A list of nodes. */
  nodes: Array<Office>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OfficeEdge = {
  __typename?: 'OfficeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Office;
};

/** 事業者に所属している従業員 */
export type OfficeMember = {
  __typename?: 'OfficeMember';
  /** When the user was activated. */
  activatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 承認者に指定されている経費申請の件数 */
  approvingExpenseReportsCount: ApprovingExReportsCount;
  /** 承認者に指定されている事前・各種申請の件数 */
  approvingGeneralReportsCount: ApprovingExReportsCount;
  /** 承認者に指定されている支払依頼の件数 */
  approvingInvoiceReportsCount: ApprovingExReportsCount;
  /** 承認者に指定されている仮払申請の件数 */
  approvingSuspensePaymentReportsCount: ApprovingExReportsCount;
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** メール通知設定 */
  exMailSettings: Array<ExMailSetting>;
  /** プッシュ通知設定 */
  exPushSettings: Array<ExPushSetting>;
  /** 経費申請 */
  expenseReports: ExpenseReportConnection;
  /** 経費申請の件数 */
  expenseReportsCount: ExReportsCount;
  /** 事前・各種申請一覧 */
  generalReports: GeneralReportConnection;
  /** 事前・各種申請の件数 */
  generalReportsCount: ExReportsCount;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 従業員のログイン種別 */
  idType: OfficeMemberIdTypeEnum;
  /**
   * User identification code/ユーザー識別番号
   * @deprecated User.identification_code で代用してください / Please use User.identification_code instead
   */
  identificationCode: Scalars['ID']['output'];
  /** 支払依頼 */
  invoiceReports: InvoiceReportConnection;
  /** 支払依頼の件数 */
  invoiceReportsCount: ExReportsCount;
  /**
   * 管理権限を持つかどうか
   * @deprecated 2023/09/20 時点では officeMemberSetting.role を利用してください。role が admin の場合この権限を持ちます
   */
  isExAdministrator: Scalars['Boolean']['output'];
  /**
   * 承認権限を持つかどうか
   * @deprecated 2023/09/20 時点では officeMemberSetting.role を利用してください。role が authorizer または admin の場合この権限を持ちます
   */
  isExAuthorizer: Scalars['Boolean']['output'];
  /**
   * 閲覧権限を持つかどうか
   * @deprecated 2023/09/20 時点では officeMemberSetting.role を利用してください。role が observer の場合この権限を持ちます
   */
  isExObserver: Scalars['Boolean']['output'];
  /**
   * 申請権限を持つかどうか
   * @deprecated 2023/09/20 時点では officeMemberSetting.role を利用してください。role が normal, authorizer, admin いずれかの場合この権限を持ちます
   */
  isExUser: Scalars['Boolean']['output'];
  /** 代行ログイン中かどうか */
  isMimic: Scalars['Boolean']['output'];
  /** マイページのNewタグを出すかどうか */
  isMypageNewLabelRequired: Scalars['Boolean']['output'];
  /**
   * Whether action restriction by plan. Returns true when less than the team plan
   * and not a full administrator./ プラン制限が必要かどうか。チームプラン未満かつ全権管理者ではない時にtrueを返す。
   */
  isPlanRestrictionRequired: Scalars['Boolean']['output'];
  /** 従業員の名前 */
  name: Scalars['String']['output'];
  /** 未申請の経費明細件数 */
  notSubmittedExTransactionsCount: Scalars['Int']['output'];
  /** 通知設定 */
  notificationSettings: NotificationSettings;
  /** 通知タイムライン（オフセットページング用） */
  notificationTimelineList: NotificationTimelineList;
  /** 通知タイムライン */
  notificationTimelines?: Maybe<NotificationTimelineConnection>;
  /** 従業員番号 */
  number: Scalars['String']['output'];
  /** 従業員が所属している事業者 */
  office: Office;
  /** office_member_ex_jtb_account associated with the office_member */
  officeMemberExJtbUserAccount: OfficeMemberExJtbUserAccount;
  /** 従業員の設定。利用開始していない場合にはnullを返します。 */
  officeMemberSetting?: Maybe<OfficeMemberSetting>;
  /**
   * 従業員の役割
   * @deprecated officeMemberSetting.role を利用してください
   */
  role?: Maybe<Role>;
  /** 仮払申請 */
  suspensePaymentReports: SuspensePaymentReportConnection;
  /** 仮払申請の件数 */
  suspensePaymentReportsCount: SuspensePaymentReportsCount;
  /** 更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** クラウド経費のユーザ */
  user: User;
};


/** 事業者に所属している従業員 */
export type OfficeMemberExpenseReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchStatus?: InputMaybe<ExReportSearchStatus>;
};


/** 事業者に所属している従業員 */
export type OfficeMemberGeneralReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['String']['input']>;
  createdAtTo?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  officeMemberId?: InputMaybe<Scalars['ID']['input']>;
  searchStatus?: InputMaybe<ExReportSearchStatus>;
};


/** 事業者に所属している従業員 */
export type OfficeMemberInvoiceReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchStatus?: InputMaybe<ExReportSearchStatus>;
};


/** 事業者に所属している従業員 */
export type OfficeMemberNotificationTimelineListArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  searchCondition?: InputMaybe<NotificationTimelineSearchInput>;
};


/** 事業者に所属している従業員 */
export type OfficeMemberNotificationTimelinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** 事業者に所属している従業員 */
export type OfficeMemberOfficeMemberExJtbUserAccountArgs = {
  exBusinessTravelJtbAccountId?: InputMaybe<Scalars['ID']['input']>;
};


/** 事業者に所属している従業員 */
export type OfficeMemberSuspensePaymentReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchStatus?: InputMaybe<ExReportSearchStatus>;
};

/** The connection type for OfficeMember. */
export type OfficeMemberConnection = {
  __typename?: 'OfficeMemberConnection';
  /** A list of edges. */
  edges: Array<OfficeMemberEdge>;
  /** A list of nodes. */
  nodes: Array<OfficeMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OfficeMemberEdge = {
  __typename?: 'OfficeMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OfficeMember;
};

/** JTB連携アカウントを管理 */
export type OfficeMemberExJtbUserAccount = {
  __typename?: 'OfficeMemberExJtbUserAccount';
  /** 貸方勘定科目初期値 */
  crItem: Item;
  /** 貸方補助科目初期値 */
  crSubItem?: Maybe<SubItem>;
  /** jtb account setup information */
  exBusinessTravelJtbAccount: ExBusinessTravelJtbAccount;
  /** Jtb Transaction information grouped by tour_no */
  groupedExBusinessTravelJtbTransactions: GroupedTransactionConnection;
  /** JTBの利用履歴 */
  hasUsageHistory: Scalars['Boolean']['output'];
  /** JTBの利用履歴のテキスト */
  hasUsageHistoryText: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 実績データ参照用ユーザID(基本的に社員番号を想定していてプライマリキーのidとしては使われない文字列) */
  jtbUserid?: Maybe<Scalars['String']['output']>;
  /** 従業員 削除されてたらnullになる */
  officeMember?: Maybe<OfficeMember>;
  /** JTB_USERのアカウントに対して利用従業員 */
  subJtbOfficeMembers: OfficeMemberConnection;
  /** Total amount of JTB transactions associated with OfficeMemberExJtbUserAccount */
  summary: Scalars['BigDecimal']['output'];
};


/** JTB連携アカウントを管理 */
export type OfficeMemberExJtbUserAccountGroupedExBusinessTravelJtbTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** JTB連携アカウントを管理 */
export type OfficeMemberExJtbUserAccountSubJtbOfficeMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** 従業員のログイン種別 */
export enum OfficeMemberIdTypeEnum {
  /** emailによるログイン */
  Email = 'email',
  /** ログインIDによるログイン */
  LoginId = 'login_id'
}

/** 従業員設定 */
export type OfficeMemberSetting = {
  __typename?: 'OfficeMemberSetting';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 従業員の言語設定 */
  language?: Maybe<Language>;
  /** 従業員の役割 */
  role: RoleEnum;
};

/** 掲示板 */
export type OfficeNotificationBoard = {
  __typename?: 'OfficeNotificationBoard';
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['Int']['output'];
  /** 掲載日 */
  publicationDate: Scalars['ISO8601Date']['output'];
  /** タイトル */
  title: Scalars['String']['output'];
  /** 更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for OfficeNotificationBoard. */
export type OfficeNotificationBoardConnection = {
  __typename?: 'OfficeNotificationBoardConnection';
  /** A list of edges. */
  edges: Array<OfficeNotificationBoardEdge>;
  /** A list of nodes. */
  nodes: Array<OfficeNotificationBoard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OfficeNotificationBoardEdge = {
  __typename?: 'OfficeNotificationBoardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OfficeNotificationBoard;
};

/** 仮払機能事業者設定 */
export type OfficeSuspensePaymentSetting = {
  __typename?: 'OfficeSuspensePaymentSetting';
  /** 事業者作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 仮払が有効かどうか */
  isActive: Scalars['Boolean']['output'];
  /** 仮払の精算種別 */
  relationshipCardinality: OfficeSuspensePaymentSettingRelationshipCardinality;
  /** 事業者更新日時 */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum OfficeSuspensePaymentSettingRelationshipCardinality {
  /** 複数の仮払申請 : 一つの経費精算 */
  ManyToOne = 'many_to_one',
  /** 1つの仮払申請 : 複数の経費精算 */
  OneToMany = 'one_to_many',
  /** 1つの仮払申請 : １つの経費精算 */
  OneToOne = 'one_to_one'
}

/** office type */
export enum OfficeTypeEnum {
  /** 法人 */
  Corporate = 'corporate',
  /** 個人事業主 */
  Individual = 'individual'
}

/** Sort order */
export enum OrderEnum {
  /** Ascending order */
  Asc = 'asc',
  /** Descending order */
  Desc = 'desc'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** プラットフォーム種別 */
export enum Platform {
  /** モバイルアプリ */
  Mobile = 'mobile',
  /** web */
  Web = 'web'
}

/** Information about the position held by the office */
export type Position = {
  __typename?: 'Position';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Whether the position is an authorizer */
  isAuthorizer: Scalars['Boolean']['output'];
  /** Position name */
  name: Scalars['String']['output'];
  /** Display priority */
  priority: Scalars['Int']['output'];
};

/** List of positions */
export type PositionList = {
  __typename?: 'PositionList';
  /** Positions */
  items: Array<Position>;
  /** Total count of positions */
  totalCount: Scalars['Int']['output'];
};

/** Search conditions for positions */
export type PositionSearchInput = {
  /** Position name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Sort order */
  order?: InputMaybe<OrderEnum>;
};

/** Information about the project code */
export type ProjectCode = {
  __typename?: 'ProjectCode';
  /** Project code code */
  code: Scalars['String']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** Whether the project code is active */
  isActive: Scalars['Boolean']['output'];
  /** Project code name */
  name: Scalars['String']['output'];
  /** Parent project code */
  parentProjectCode?: Maybe<ProjectCode>;
  /** Display priority */
  priority: Scalars['Int']['output'];
};

/** List of project codes */
export type ProjectCodeList = {
  __typename?: 'ProjectCodeList';
  /** Project codes */
  items: Array<ProjectCode>;
  /** Total count of project codes */
  totalCount: Scalars['Int']['output'];
};

/** Project code order */
export enum ProjectCodeOrderEnum {
  /** Code ascending order */
  CodeAsc = 'code_asc',
  /** Code descending order */
  CodeDesc = 'code_desc',
  /** Priority ascending order */
  PriorityAsc = 'priority_asc',
  /** Priority descending order */
  PriorityDesc = 'priority_desc'
}

/** Search conditions for project codes */
export type ProjectCodeSearchInput = {
  /** Whether the project code is active */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Keyword */
  keyword?: InputMaybe<Scalars['String']['input']>;
  /** Order */
  order?: InputMaybe<ProjectCodeOrderEnum>;
};

export type Query = {
  __typename?: 'Query';
  /** Access Rights to some specific pages */
  accessRights: AccessRights;
  /** 従業員の言語設定によって決められた現在利用する言語。言語設定がなしの場合は HTTP リクエストヘッダー HTTP_ACCEPT_LANGUAGE を参照して決定する。受け入れ不可能な値が与えられた場合は自動的に "ja" となる。 */
  activeLanguage: Language;
  /** 緊急時お知らせ情報を返す。プラットフォーム種別に応じてWeb向け, Mobile向けの内容を返す。表示・非表示フラグがfalseの時はnullを返す。 */
  announcement?: Maybe<Announcement>;
  /** 銀行支店 */
  bankBranches?: Maybe<Array<BankBranch>>;
  /** 銀行 */
  banks?: Maybe<Array<Bank>>;
  /** ログイン事業者の情報を返す */
  currentOffice?: Maybe<Office>;
  /** ログイン事業者内のログイン中の従業員の情報を返す */
  currentOfficeMember?: Maybe<OfficeMember>;
  /** ログインユーザーの情報を返す */
  currentUser: User;
  /** ExFunctionの関数一覧を返す */
  exFunctionFunctions: Array<ExFunctionFunction>;
  /** ExFunctionでのChat GPTのpromptを返す */
  exFunctionPrompt: Scalars['String']['output'];
  /** ExFunctionの実行結果を返す */
  exFunctionResult: Scalars['String']['output'];
  /** Returns necessary statement ex_transaction when JTB transaction are combined. */
  exTransactionAttributesForAddUppedJtbTransaction: ExTransaction;
  /** 関連明細一覧を返す（明細番号の昇順） */
  exTransactionFamilies: Array<ExTransactionFamily>;
  /** featureフラグの可否 */
  featureFlagEnabled: Scalars['Boolean']['output'];
  /** 事前・各種申請一覧 */
  generalReports: GeneralReportConnection;
  /** return "こたろうくん"s information */
  kotaro?: Maybe<Kotaro>;
  /** 登録済み領収書一覧を返す */
  mfFileReferencesExTransactions: Array<MfFileReferencesExTransaction>;
  /** サービスからのお知らせ情報を返す */
  news: NewsConnection;
  /** 指定IDの事業者を返す */
  office: Office;
  /** Returns information on office_member belonging to the specified ID. */
  officeMember: OfficeMember;
  /** 検証および死活監視の疎通確認等に活用する */
  ping: Scalars['String']['output'];
  /** プロンプトに対してExFunctionの提案を行う（社内向けにリリースのため、本番環境では閲覧不可） */
  proposeExFunction: Scalars['String']['output'];
  /** macrowによる貸借同一の変数一覧を返す */
  ruleWordsForExportColumnOutputByJournalBranchMacro: Array<Scalars['String']['output']>;
  /** macrowによる貸借分離の変数一覧を返す */
  ruleWordsForExportColumnOutputByJournalBranchSideMacro: Array<Scalars['String']['output']>;
};


export type QueryAnnouncementArgs = {
  platform?: InputMaybe<Platform>;
};


export type QueryBankBranchesArgs = {
  bankId: Scalars['ID']['input'];
};


export type QueryExFunctionResultArgs = {
  source: Scalars['String']['input'];
};


export type QueryExTransactionAttributesForAddUppedJtbTransactionArgs = {
  exBusinessTravelJtbTransactionIds: Array<Scalars['ID']['input']>;
  officeMemberId: Scalars['ID']['input'];
};


export type QueryExTransactionFamiliesArgs = {
  exTransactionId: Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
};


export type QueryFeatureFlagEnabledArgs = {
  flagName: Scalars['String']['input'];
};


export type QueryGeneralReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['String']['input']>;
  createdAtTo?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  officeMemberId?: InputMaybe<Scalars['ID']['input']>;
  searchStatus?: InputMaybe<ExReportSearchStatus>;
};


export type QueryMfFileReferencesExTransactionsArgs = {
  number?: InputMaybe<Scalars['String']['input']>;
  officeMemberId: Scalars['ID']['input'];
};


export type QueryNewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOfficeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOfficeMemberArgs = {
  officeMemberId: Scalars['ID']['input'];
};


export type QueryProposeExFunctionArgs = {
  prompt: Scalars['String']['input'];
};

export type ReportType = {
  __typename?: 'ReportType';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 申請名 */
  name: Scalars['String']['output'];
  /** 編集権限設定 の form */
  reportTypeEditControlForm: ReportTypeEditControlForm;
};

/** 申請後の申請内容の変更を通知するか */
export enum ReportTypeEditControlChangeNotification {
  /** 申請後の変更を通知する */
  AfterApplyed = 'after_applyed',
  /** 最終承認後の変更を通知する */
  AfterApproved = 'after_approved',
  /** 通知しない */
  NotNotify = 'not_notify'
}

export type ReportTypeEditControlForm = {
  __typename?: 'ReportTypeEditControlForm';
  /** 管理者が最終承認後に編集できない項目の制限 */
  adminAfterApprovedRestriction: ReportTypeEditControlRestriction;
  /** 承認者が申請後に編集できない項目の制限 */
  adminPendingApprovalRestriction: ReportTypeEditControlRestriction;
  /** 承認者が編集できない項目の制限 */
  approverRestriction: ReportTypeEditControlRestriction;
  /** 承認後の取消し可否 true=制限する/false=制限しない */
  cancelableAfterApproval: Scalars['Boolean']['output'];
  /** 申請後の内容変更を通知するか */
  changeNotification: ReportTypeEditControlChangeNotification;
};

/** report_type_edit_control_form の params を受け取るための type */
export type ReportTypeEditControlFormArguments = {
  /** 管理者が最終承認後に編集できない項目の制限 */
  adminAfterApprovedRestriction: ReportTypeEditControlRestriction;
  /** 承認者が申請後に編集できない項目の制限 */
  adminPendingApprovalRestriction: ReportTypeEditControlRestriction;
  /** 承認者が編集できない項目の制限 */
  approverRestriction: ReportTypeEditControlRestriction;
  /** 承認後の取消し可否 true=制限する/false=制限しない */
  cancelableAfterApproval: Scalars['Boolean']['input'];
  /** 申請後の内容変更を通知するか */
  changeNotification: ReportTypeEditControlChangeNotification;
};

/** 編集権限設定の制限レベル */
export enum ReportTypeEditControlRestriction {
  /** 全て制限する */
  All = 'all',
  /** 日付と金額のみ制限する */
  DateAndAmount = 'date_and_amount',
  /** 制限しない */
  None = 'none'
}

/** クラウド経費のロール情報 */
export type Role = {
  __typename?: 'Role';
  /** ロールID */
  id: Scalars['Int']['output'];
  /** ロール名 */
  name: Scalars['String']['output'];
};

/** 従業員の役割 */
export enum RoleEnum {
  /** 全ての権限を持つ管理者 */
  Admin = 'admin',
  /** 申請や承認を行う承認者 */
  Authorizer = 'authorizer',
  /** 権限を持たない従業員、または役割未設定の従業員 */
  NoRole = 'no_role',
  /** 申請のみ可能な一般従業員 */
  Normal = 'normal',
  /** 申請や承認を行わないが内容確認はできる監査者 */
  Observer = 'observer'
}

/** 消費税端数処理方法 */
export enum RoundingMethod {
  /** 端数を切り捨て */
  RoundDown = 'round_down',
  /** 端数を四捨五入 */
  RoundOff = 'round_off'
}

/** 補助科目 */
export type SubItem = {
  __typename?: 'SubItem';
  /** コード */
  code?: Maybe<Scalars['String']['output']>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 勘定科目 */
  item: Item;
  /** 勘定科目ID */
  itemId: Scalars['ID']['output'];
  /** 名称 */
  name: Scalars['String']['output'];
};

/** 仮払申請 */
export type SuspensePaymentReport = {
  __typename?: 'SuspensePaymentReport';
  /** 承認日時 */
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 編集画面へのpath */
  editPath: Scalars['String']['output'];
  /** 入力項目一覧 */
  exReportFormInputValues: ExReportFormInputValueConnection;
  /** 申請タイプ */
  exReportType?: Maybe<ReportType>;
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** 番号 */
  number: Scalars['String']['output'];
  /** 申請した従業員 */
  officeMember: OfficeMember;
  /** ステータス */
  status: ExReportStatus;
  /** 申請日時 */
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** タイムスタンプステータス */
  timestampStatus?: Maybe<ExReportTimestampStatus>;
  /** タイトル */
  title: Scalars['String']['output'];
};


/** 仮払申請 */
export type SuspensePaymentReportExReportFormInputValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for SuspensePaymentReport. */
export type SuspensePaymentReportConnection = {
  __typename?: 'SuspensePaymentReportConnection';
  /** A list of edges. */
  edges: Array<SuspensePaymentReportEdge>;
  /** A list of nodes. */
  nodes: Array<SuspensePaymentReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SuspensePaymentReportEdge = {
  __typename?: 'SuspensePaymentReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SuspensePaymentReport;
};

/** 仮払申請の件数を返す */
export type SuspensePaymentReportsCount = {
  __typename?: 'SuspensePaymentReportsCount';
  /** 利用可能な仮払の件数 */
  opened: Scalars['Int']['output'];
  /** 差し戻しの件数 */
  rejected: Scalars['Int']['output'];
  /** 下書きの件数 */
  unsubmitted: Scalars['Int']['output'];
  /** 承認待ちの件数 */
  waiting: Scalars['Int']['output'];
};

export type TransferFeeRule = {
  __typename?: 'TransferFeeRule';
  /** 他行の場合の手数料 */
  otherBank: Scalars['BigInt']['output'];
  /** 同銀行の場合の手数料 */
  sameBank: Scalars['BigInt']['output'];
  /** 同銀行同支店の場合の手数料 */
  sameBankBranch: Scalars['BigInt']['output'];
  /** 閾値（〜円以上） */
  valueGreaterThanOrEqual: Scalars['BigInt']['output'];
  /** 閾値（〜円未満） */
  valueLessThan: Scalars['BigInt']['output'];
};

export type TransferFeeRuleInput = {
  /** 他行の場合の手数料 */
  otherBank: Scalars['BigInt']['input'];
  /** 同銀行の場合の手数料 */
  sameBank: Scalars['BigInt']['input'];
  /** 同銀行同支店の場合の手数料 */
  sameBankBranch: Scalars['BigInt']['input'];
  /** 閾値（〜円以上） 空の場合は0が自動でセットされます */
  valueGreaterThanOrEqual?: InputMaybe<Scalars['BigInt']['input']>;
  /** 閾値（〜円未満） */
  valueLessThan?: InputMaybe<Scalars['BigInt']['input']>;
};

/** Autogenerated input type of UpdateApActiveExDeptExItem */
export type UpdateApActiveExDeptExItemInput = {
  /** 経費科目ID */
  apItemId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 部門ID */
  exDeptId: Scalars['String']['input'];
  /** 利用するかどうか */
  isActive: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UpdateApActiveExDeptExItem. */
export type UpdateApActiveExDeptExItemPayload = {
  __typename?: 'UpdateApActiveExDeptExItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of UpdateApOfficeExciseSetting */
export type UpdateApOfficeExciseSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roundingMethod: RoundingMethod;
};

/** Autogenerated return type of UpdateApOfficeExciseSetting. */
export type UpdateApOfficeExciseSettingPayload = {
  __typename?: 'UpdateApOfficeExciseSettingPayload';
  apOfficeExciseSetting: ApOfficeExciseSetting;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateApSpecialExceptionStatus */
export type UpdateApSpecialExceptionStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 摘要文言 */
  customRemark: Scalars['String']['input'];
  /** 表示設定 */
  isActive: Scalars['Boolean']['input'];
  /** 特例区分ID */
  specialExceptionStatusId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateApSpecialExceptionStatus. */
export type UpdateApSpecialExceptionStatusPayload = {
  __typename?: 'UpdateApSpecialExceptionStatusPayload';
  apSpecialExceptionStatus: ApSpecialExceptionStatus;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateDept */
export type UpdateDeptInput = {
  /** Cloud Accounting dept */
  caDeptId?: InputMaybe<Scalars['ID']['input']>;
  /** Cloud Accounting Plus dept */
  caMidDeptId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Dept code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Dept id */
  deptId: Scalars['ID']['input'];
  /** Display order */
  displayOrder: Scalars['Int']['input'];
  /** Cost burden dept/Dept for journal */
  exJournalDeptId?: InputMaybe<Scalars['ID']['input']>;
  /** Display flag */
  isActive: Scalars['Boolean']['input'];
  /** Dept name */
  name: Scalars['String']['input'];
  /** Parent dept */
  parentDeptId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateDept. */
export type UpdateDeptPayload = {
  __typename?: 'UpdateDeptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Dept */
  dept: Dept;
};

/** Autogenerated input type of UpdateExBusinessTravelJtbAccount */
export type UpdateExBusinessTravelJtbAccountInput = {
  /** アカウント名 */
  accountName: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 貸方勘定科目初期値 */
  crItemId: Scalars['ID']['input'];
  /** 貸方補助科目初期値 */
  crSubItemId?: InputMaybe<Scalars['ID']['input']>;
  /** 取引日として初期設定する日付の設定値 */
  defaultRecognizedAtType: ExBusinessTravelJtbAccountDefaultRecognizedAt;
  /** JTB連携アカウントID */
  exBusinessTravelJtbAccountId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateExBusinessTravelJtbAccount. */
export type UpdateExBusinessTravelJtbAccountPayload = {
  __typename?: 'UpdateExBusinessTravelJtbAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exBusinessTravelJtbAccount: ExBusinessTravelJtbAccount;
};

/** Autogenerated input type of UpdateExFunctionPrompt */
export type UpdateExFunctionPromptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  prompt: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateExFunctionPrompt. */
export type UpdateExFunctionPromptPayload = {
  __typename?: 'UpdateExFunctionPromptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exFunctionPrompt: Scalars['String']['output'];
};

/** Autogenerated input type of UpdateExMailSettings */
export type UpdateExMailSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 更新するメール通知設定のリスト */
  exMailSettings: Array<ExMailSettingInput>;
};

/** Autogenerated return type of UpdateExMailSettings. */
export type UpdateExMailSettingsPayload = {
  __typename?: 'UpdateExMailSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exMailSettings: Array<ExMailSetting>;
};

/** Autogenerated input type of UpdateExOfficeExciseSetting */
export type UpdateExOfficeExciseSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roundingMethod: RoundingMethod;
};

/** Autogenerated return type of UpdateExOfficeExciseSetting. */
export type UpdateExOfficeExciseSettingPayload = {
  __typename?: 'UpdateExOfficeExciseSettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exOfficeExciseSetting: ExOfficeExciseSetting;
};

/** Autogenerated input type of UpdateExOfficeForeignCurrencySetting */
export type UpdateExOfficeForeignCurrencySettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 事業者ID */
  officeId: Scalars['ID']['input'];
  /** 端数設定 */
  roundingMode: ExOfficeForeignCurrencySettingRoundingMode;
};

/** Autogenerated return type of UpdateExOfficeForeignCurrencySetting. */
export type UpdateExOfficeForeignCurrencySettingPayload = {
  __typename?: 'UpdateExOfficeForeignCurrencySettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exOfficeForeignCurrencySetting: ExOfficeForeignCurrencySetting;
};

/** Autogenerated input type of UpdateExPushSettings */
export type UpdateExPushSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 更新するプッシュ通知設定のリスト */
  exPushSettings: Array<ExPushSettingInput>;
};

/** Autogenerated return type of UpdateExPushSettings. */
export type UpdateExPushSettingsPayload = {
  __typename?: 'UpdateExPushSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exPushSettings: Array<ExPushSetting>;
};

/** Autogenerated input type of UpdateExSpecialExceptionStatus */
export type UpdateExSpecialExceptionStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 摘要文言 */
  customRemark: Scalars['String']['input'];
  /** 特例区分ID */
  specialExceptionStatusId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateExSpecialExceptionStatus. */
export type UpdateExSpecialExceptionStatusPayload = {
  __typename?: 'UpdateExSpecialExceptionStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exSpecialExceptionStatus: ExSpecialExceptionStatus;
};

/** Autogenerated input type of Update */
export type UpdateInput = {
  /** 口座種別 */
  accountType: BankAccount;
  /** 銀行支店ID */
  bankBranchId: Scalars['ID']['input'];
  /** 銀行ID */
  bankId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 振込依頼人コード */
  fbCustomerCode?: InputMaybe<Scalars['String']['input']>;
  /** 名義人 */
  holderName: Scalars['String']['input'];
  /** 名義人カナ */
  holderNameKana: Scalars['String']['input'];
  /** 支払元口座ID */
  id: Scalars['ID']['input'];
  /** 口座番号 */
  number: Scalars['String']['input'];
  /** 事業者ID */
  officeId: Scalars['ID']['input'];
  /** 支払仕訳の貸方勘定科目 */
  paymentJournalItemId?: InputMaybe<Scalars['ID']['input']>;
  /** 支払仕訳の貸方補助科目 */
  paymentJournalSubItemId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateMemoExBusinessTravelJtbTransaction */
export type UpdateMemoExBusinessTravelJtbTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the ExBusinessTravelJtbTransaction to be updated in the memo */
  exBusinessTravelJtbTransactionId: Scalars['ID']['input'];
  /** Updated Memo Content */
  memo: Scalars['String']['input'];
  /** ID of the office member who performs the memo update operation */
  officeMemberId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateMemoExBusinessTravelJtbTransaction. */
export type UpdateMemoExBusinessTravelJtbTransactionPayload = {
  __typename?: 'UpdateMemoExBusinessTravelJtbTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** ExBusinessTravelJtbTransaction that has been updated with the memo */
  exBusinessTravelJtbTransaction: ExBusinessTravelJtbTransaction;
};

/** Autogenerated input type of UpdateNotificationSettings */
export type UpdateNotificationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** メール通知設定 */
  mail?: InputMaybe<NotificationSettingInput>;
  /** プッシュ通知設定(このargumentは経費でのみ利用可能です) */
  push?: InputMaybe<NotificationSettingInput>;
};

/** Autogenerated return type of UpdateNotificationSettings. */
export type UpdateNotificationSettingsPayload = {
  __typename?: 'UpdateNotificationSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mail: NotificationSetting;
  push: NotificationSetting;
};

/** Autogenerated input type of UpdateOfficeMemberExJtbUserAccount */
export type UpdateOfficeMemberExJtbUserAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 貸方勘定科目初期値 */
  crItemId: Scalars['ID']['input'];
  /** 貸方補助科目初期値 */
  crSubItemId?: InputMaybe<Scalars['ID']['input']>;
  /** 実績データ参照用ユーザID(基本的に社員番号を想定していてプライマリキーのidとしては使われない文字列) */
  jtbUserid: Scalars['String']['input'];
  /** 従業員に対して設定するJTB_USERのアカウントのID */
  officeMemberExJtbUserAccountId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateOfficeMemberExJtbUserAccount. */
export type UpdateOfficeMemberExJtbUserAccountPayload = {
  __typename?: 'UpdateOfficeMemberExJtbUserAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  officeMemberExJtbUserAccount: OfficeMemberExJtbUserAccount;
};

/** Autogenerated input type of UpdateOfficeSuspensePaymentSetting */
export type UpdateOfficeSuspensePaymentSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  relationshipCardinality?: InputMaybe<OfficeSuspensePaymentSettingRelationshipCardinality>;
};

/** Autogenerated return type of UpdateOfficeSuspensePaymentSetting. */
export type UpdateOfficeSuspensePaymentSettingPayload = {
  __typename?: 'UpdateOfficeSuspensePaymentSettingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  officeSuspensePaymentSetting: OfficeSuspensePaymentSetting;
};

/** Autogenerated return type of Update. */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 振込担当者を再連携させる必要があるかどうか */
  needReLink: Scalars['Boolean']['output'];
  officeAccount: OfficeAccount;
};

/** Autogenerated input type of UpdatePosition */
export type UpdatePositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the position is an authorizer */
  isAuthorizer: Scalars['Boolean']['input'];
  /** position name */
  name: Scalars['String']['input'];
  /** Position ID */
  positionId: Scalars['ID']['input'];
  /** Display priority */
  priority: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdatePosition. */
export type UpdatePositionPayload = {
  __typename?: 'UpdatePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Position */
  position: Position;
};

/** Autogenerated input type of UpdateProjectCode */
export type UpdateProjectCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Project code code */
  code: Scalars['String']['input'];
  /** Whether the project code is active */
  isActive: Scalars['Boolean']['input'];
  /** Project code name */
  name: Scalars['String']['input'];
  /** Parent project code id */
  parentProjectCodeId?: InputMaybe<Scalars['ID']['input']>;
  /** Display priority */
  priority: Scalars['Int']['input'];
  /** Project code id */
  projectCodeId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateProjectCode. */
export type UpdateProjectCodePayload = {
  __typename?: 'UpdateProjectCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Project code */
  projectCode: ProjectCode;
};

/** Autogenerated input type of UpdateReportTypeEditControls */
export type UpdateReportTypeEditControlsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 編集権限設定の更新内 */
  formArguments: ReportTypeEditControlFormArguments;
  /** 更新対象の申請フォームのid */
  reportTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReportTypeEditControls. */
export type UpdateReportTypeEditControlsPayload = {
  __typename?: 'UpdateReportTypeEditControlsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 申請フォーム */
  reportType: ReportType;
};

/** Autogenerated input type of UpdateTransferFeeRules */
export type UpdateTransferFeeRulesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 支払元口座ID */
  officeAccountId: Scalars['ID']['input'];
  /** 事業者ID */
  officeId: Scalars['ID']['input'];
  /** 振込手数料の控除額の設定 */
  transferFeeRules: Array<TransferFeeRuleInput>;
};

/** Autogenerated return type of UpdateTransferFeeRules. */
export type UpdateTransferFeeRulesPayload = {
  __typename?: 'UpdateTransferFeeRulesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** 振込手数料の控除額の設定 */
  transferFeeRules: Array<TransferFeeRule>;
};

/** Autogenerated input type of UpdateUserStatus */
export type UpdateUserStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateUserStatus. */
export type UpdateUserStatusPayload = {
  __typename?: 'UpdateUserStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  office: Office;
  redirectPath: Scalars['String']['output'];
};

/** クラウド経費のユーザー */
export type User = {
  __typename?: 'User';
  /** マネーフォワード クラウドのアカウント統制が有効かどうか */
  accountControlEnabled: Scalars['Boolean']['output'];
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** User identification code/ユーザー識別番号 */
  identificationCode: Scalars['String']['output'];
  /** クラウド経費の利用規約同意済みかどうか */
  isExRegistered: Scalars['Boolean']['output'];
  /** マネーフォワード クラウドの各種サービスのSSO URL */
  mfcServiceSsoUrl?: Maybe<MfcServiceSsoUrl>;
  /** ユーザーが利用可能な offices */
  permittedOffices: OfficeConnection;
};


/** クラウド経費のユーザー */
export type UserIdentificationCodeArgs = {
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


/** クラウド経費のユーザー */
export type UserPermittedOfficesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** 汎用マスタ */
export type UserDefinedMaster = {
  __typename?: 'UserDefinedMaster';
  /** ID（難読化された文字列） */
  id: Scalars['ID']['output'];
  /** マスタ名 */
  name: Scalars['String']['output'];
  /** 利用状況 */
  usageSituation: UserDefinedMasterUsageSituation;
};

/** The connection type for UserDefinedMaster. */
export type UserDefinedMasterConnection = {
  __typename?: 'UserDefinedMasterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserDefinedMasterEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserDefinedMaster>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserDefinedMasterEdge = {
  __typename?: 'UserDefinedMasterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserDefinedMaster>;
};

/** 汎用マスタ マスタデータ */
export type UserDefinedMasterItem = {
  __typename?: 'UserDefinedMasterItem';
  /** マスタコード */
  code: Scalars['String']['output'];
  /** 名称 */
  name: Scalars['String']['output'];
};

/** Autogenerated input type of UserDefinedMasterItemBulkDestroy */
export type UserDefinedMasterItemBulkDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 汎用マスタのID */
  userDefinedMasterId: Scalars['String']['input'];
  /** マスタデータのID配列 */
  userDefinedMasterItemIds: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of UserDefinedMasterItemBulkDestroy. */
export type UserDefinedMasterItemBulkDestroyPayload = {
  __typename?: 'UserDefinedMasterItemBulkDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userDefinedMasterItemIds: Array<Scalars['String']['output']>;
};

/** The connection type for UserDefinedMasterItem. */
export type UserDefinedMasterItemConnection = {
  __typename?: 'UserDefinedMasterItemConnection';
  /** A list of edges. */
  edges: Array<UserDefinedMasterItemEdge>;
  /** A list of nodes. */
  nodes: Array<UserDefinedMasterItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserDefinedMasterItemEdge = {
  __typename?: 'UserDefinedMasterItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserDefinedMasterItem;
};

/** 汎用マスタの利用状況 */
export type UserDefinedMasterUsageSituation = {
  __typename?: 'UserDefinedMasterUsageSituation';
  /** 明細カスタム項目で利用されているか */
  isExTransactionCustomField: Scalars['Boolean']['output'];
};

export type WorkflowNotificationSetting = {
  __typename?: 'WorkflowNotificationSetting';
  /** ID */
  id: Scalars['ID']['output'];
  /** 通知する/通知しない */
  isActive: Scalars['Boolean']['output'];
  /** 最終承認完了のみ通知する */
  onlyFinalApproval: Scalars['Boolean']['output'];
};

export type WorkflowNotificationSettingInput = {
  /** 通知フラグ */
  isActive: Scalars['Boolean']['input'];
  /** 最終承認完了のみ通知するか */
  onlyFinalApproval?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoginQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginQuery = { __typename?: 'Query', activeLanguage: Language, currentOffice?: { __typename?: 'Office', id: string, identificationCode: string, isActive: boolean, officeName: string, isMoreThanTeamPlan: boolean, createdAt: string } | null, currentOfficeMember?: { __typename?: 'OfficeMember', id: string, idType: OfficeMemberIdTypeEnum, identificationCode: string, name: string, number: string } | null, currentUser: { __typename?: 'User', id: string, identificationCode: string } };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const LoginDocument = new TypedDocumentString(`
    query Login {
  currentOffice {
    id
    identificationCode
    isActive
    officeName
    isMoreThanTeamPlan
    createdAt
  }
  currentOfficeMember {
    id
    idType
    identificationCode
    name
    number
  }
  currentUser {
    id
    identificationCode
  }
  activeLanguage
}
    `) as unknown as TypedDocumentString<LoginQuery, LoginQueryVariables>;

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLoginQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentOffice, currentOfficeMember, currentUser, activeLanguage }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockLoginQuery = (resolver: GraphQLResponseResolver<LoginQuery, LoginQueryVariables>, options?: RequestHandlerOptions) =>
  graphql.query<LoginQuery, LoginQueryVariables>(
    'Login',
    resolver,
    options
  )
