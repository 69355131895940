import { faker } from '@faker-js/faker/locale/ja';
import { HttpResponse, delay, http } from 'msw';
faker.seed(1);

const _getGetApCounterparties = Array.from({ length: 11 }, (_, key) => ({
  [key]: Array.from({ length: 25 }, () => {
    const name = faker.person.fullName();
    const id = faker.string.uuid();
    return {
      code: faker.string.uuid(),
      name,
      id,
    };
  }),
})).reduce((obj, item) => ({ ...obj, ...item }), {});
const getCreateApCounterparties = (page: number) =>
  _getGetApCounterparties[page] ?? [];

const getGetApCounterpartiesMock = (page: number, query: string) => ({
  next: page <= 2 ? `/api/js/ap_counterparties?page=${page + 1}` : undefined,
  ap_counterparties: getCreateApCounterparties(page - 1).filter((q) =>
    !query ? true : q.name.indexOf(query) !== -1
  ),
  prev: page > 1 ? `/api/js/ap_counterparties?page=${page - 1}` : undefined,
});

export const getCreateApCounterpartyMock = () => ({
  code: faker.string.uuid(),
  name: faker.person.fullName(),
  id: faker.string.uuid(),
});

export const handlers = [
  http.get('*/api/js/ap_counterparties', async (req) => {
    await delay(1000);
    const url = new URL(req.request.url, location.href);
    const page = url.searchParams.has('page')
      ? parseInt(url.searchParams.get('page') ?? '1')
      : 1;
    const query = url.searchParams.get('query') ?? '';
    return new HttpResponse(
      JSON.stringify(getGetApCounterpartiesMock(page, query)),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
  http.post('*/api/js/ap_counterparties', async () => {
    await delay(1000);
    return new HttpResponse(JSON.stringify(getCreateApCounterpartyMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
];
