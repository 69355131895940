import { ListViewBody } from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  expiredAt: string | undefined;
};

export const ExpiredAt: FC<Props> = memo(({ expiredAt }) => {
  if (!expiredAt) {
    return null;
  }

  return <ListViewBody key='right'>{expiredAt}</ListViewBody>;
});
ExpiredAt.displayName = 'ExpiredAt';
