import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormCheckbox,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';

export type TEnableOutputPaymentRecordProps = {
  control: Control<CounterpartyForm>;
};

export const EnableOutputPaymentRecord: FC<TEnableOutputPaymentRecordProps> =
  memo(({ control }) => {
    const { t } = useTranslation();

    return (
      <>
        <>
          <Left>
            <Label
              text={t('counterparty_enable_output_payment_record')}
              htmlFor='is_enable_output_payment_record'
            />
          </Left>
          <Right>
            <FormCheckbox<CounterpartyForm>
              control={control}
              name='is_enable_output_payment_record'
              inputId='is_enable_output_payment_record'
              testId='is_enable_output_payment_record'
              label={t('counterparty_is_enable_output_payment_record')}
            />
          </Right>
        </>
      </>
    );
  });
EnableOutputPaymentRecord.displayName = 'EnableOutputPaymentRecord';
