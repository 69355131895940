import { InvalidError } from '@/utils/errors';
import { useEffect } from 'react';
import { InvoiceInfo } from './type';

type ValidationInvoice = Record<string, unknown> & InvoiceInfo;

const includeOtherKeyInvoice = (obj: ValidationInvoice): boolean => {
  const validKeys: (keyof InvoiceInfo)[] = ['invoiceId', 'fileName'];
  return Object.keys(obj).some(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (key) => !validKeys.includes(key as keyof InvoiceInfo)
  );
};

export const isRelatedInvoice = (invoice: InvoiceInfo | undefined) => {
  return invoice && invoice.invoiceId && invoice.fileName;
};

export const useRelationInvoiceValidation = (
  invoice: ValidationInvoice | undefined
) => {
  useEffect(() => {
    if (invoice) {
      if (includeOtherKeyInvoice(invoice)) {
        throw new InvalidError('想定外のプロパティが設定されています');
      }
      if (!invoice.invoiceId && invoice.fileName) {
        throw new InvalidError('invoice.invoiceIdが未設定です');
      }
      if (invoice.invoiceId && !invoice.fileName) {
        throw new InvalidError('invoice.fileNameが未設定です');
      }
    }
  });
};
