import { useCallback, useState } from 'react';

export const useDragAndDrop = () => {
  const [isDrag, setIsDrag] = useState(false);
  const onDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement | HTMLButtonElement>) => {
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDrag(true);
      }
    },
    []
  );
  const onDragLeave = useCallback(() => {
    setIsDrag(false);
  }, []);
  const onDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
    },
    []
  );
  return {
    isDrag,
    setIsDrag,
    onDragEnter,
    onDragLeave,
    onDragOver,
  };
};
