import { useLoginInfo } from '@/components/LoginInfo/provider';
import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { Address } from '@/features/ap_counterparties/components/Forms/Address';
import { AntisocialCheck } from '@/features/ap_counterparties/components/Forms/AntisocialCheck';
import { CorporateSiteUrl } from '@/features/ap_counterparties/components/Forms/CorporateSiteUrl';
import { EnableOutputPaymentRecord } from '@/features/ap_counterparties/components/Forms/EnableOutputPaymentRecord';
import { PhoneNumber } from '@/features/ap_counterparties/components/Forms/PhoneNumber';
import { RepresentativeName } from '@/features/ap_counterparties/components/Forms/RepresentativeName';
import { SubcontractType } from '@/features/ap_counterparties/components/Forms/SubcontractType';
import { useTranslation } from '@/i18n';
import { Bottom, ListView, Top } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, ReactNode, memo } from 'react';
import { Control } from 'react-hook-form';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  control: Control<CounterpartyForm>;
  message?: ReactNode;
};

export const DetailInformation: FC<Props> = memo(({ control, message }) => {
  const { t } = useTranslation();
  const loginInfo = useLoginInfo();
  const isMoreThanTeamPlan = loginInfo.currentOffice?.isMoreThanTeamPlan;

  return (
    <>
      {message}
      <div className={cx(styles['tab-container-title'])}>
        {t('counterparty_detailinfo_container_title')}
      </div>
      <ListView>
        <Top>
          <Address control={control} />
        </Top>
        <Top>
          <PhoneNumber control={control} />
        </Top>
        <Top>
          <CorporateSiteUrl control={control} />
        </Top>
        <Top>
          <RepresentativeName control={control} />
        </Top>
        <Top>
          <AntisocialCheck control={control} />
        </Top>
        {isMoreThanTeamPlan && (
          <Top>
            <SubcontractType control={control} />
          </Top>
        )}
        <Bottom>
          {isMoreThanTeamPlan ? (
            <EnableOutputPaymentRecord control={control} />
          ) : (
            <SubcontractType control={control} />
          )}
        </Bottom>
      </ListView>
    </>
  );
});
DetailInformation.displayName = 'DetailInformation';
