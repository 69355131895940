import { ApPayeeForm } from '@/features/ap_payees/components/AddNewPayeeDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  FormSelect,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from '../index.module.scss';

const cx = classnames.bind(styles);

export type TPaymentMonthProps = {
  control: Control<ApPayeeForm>;
};

export const PaymentMonth: FC<TPaymentMonthProps> = memo(({ control }) => {
  const { t } = useTranslation();

  const inputRules = useMemo(
    () => ({
      required: t('required'),
    }),
    [t]
  );
  const selectRules = useMemo(
    () => ({
      required: t('required'),
    }),
    [t]
  );

  const PaymentMonthSelectProps = useMemo(() => {
    const options = [];
    for (let i = 0; i <= 6; i++) {
      options.push({
        label: t(`payment_month_${i}`),
        value: i,
      });
    }
    return { options };
  }, [t]);

  return (
    <>
      <Left>
        <Label
          required
          text={t('payment_month_label')}
          htmlFor='payment_month'
        />
      </Left>
      <Right>
        <div className={cx(styles['form-text-row'])}>
          <div className={cx(styles['form-text-nowrap'])}>
            {t('payment_month_before')}
          </div>
          <FormSelect<ApPayeeForm>
            control={control}
            name='payment_month'
            inputId='payment_month'
            selectProps={PaymentMonthSelectProps}
            rules={selectRules}
          />
          <div>{t('payment_month_middle')}</div>
          <FormInput<ApPayeeForm>
            control={control}
            name='payment_due_date'
            inputId='payment_due_date'
            rules={inputRules}
          />
          <div>{t('payment_month_after')}</div>
        </div>
      </Right>
    </>
  );
});
PaymentMonth.displayName = 'PaymentMonth';
