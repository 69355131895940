import { SupplierUploadSetting } from '@/context/services/ap_payee/type';
import { RegisteredAccount } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/RegisteredAccount';
import { UnregisteredAccount } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount';
import styles from '@/features/ap_payees/components/PayeeDetailModal/payeeDetailModal.module.scss';
import { useTranslation } from '@/i18n';
import { Heading } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo, useMemo } from 'react';

const cx = classnames.bind(styles);

export type Props = {
  supplierUploadSetting: SupplierUploadSetting | undefined;
  isActive: boolean;
};

export const WebReceive: FC<Props> = memo(
  ({ supplierUploadSetting, isActive }) => {
    const { t } = useTranslation();

    const component = useMemo(() => {
      if (!supplierUploadSetting) {
        return null;
      }
      if (supplierUploadSetting.isAcceptedInvitation) {
        return (
          <RegisteredAccount
            supplierUploadSetting={supplierUploadSetting}
            isActive={isActive}
          />
        );
      } else {
        return (
          <UnregisteredAccount supplierUploadSetting={supplierUploadSetting} />
        );
      }
    }, [supplierUploadSetting, isActive]);

    if (!supplierUploadSetting) {
      return null;
    }

    return (
      <section>
        <Heading as='h3' className={cx(styles['section-title'])}>
          {t('ap_payee_web_receive')}
        </Heading>
        {component}
      </section>
    );
  }
);
WebReceive.displayName = 'WebReceive';
