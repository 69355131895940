import {
  AppendRuleOfListRequest,
  AppendRuleOfListResponse,
  RemoveRuleOfListRequest,
  RemoveRuleOfListResponse,
} from '@moneyforward/user-flex-feature-openapi';
import { HttpHandler, HttpResponse, PathParams, delay, http } from 'msw';

const flags: Record<string, boolean> = JSON.parse(
  sessionStorage.getItem('open-feature') ?? '{}'
);
const updateFeatureFlags = (flags: Record<string, boolean>) => {
  sessionStorage.setItem('open-feature', JSON.stringify(flags));
};

type ruleOfListParams = PathParams;

export const handlers: HttpHandler[] = [
  http.post('*/ofrep/v1/evaluate/flags', async () => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify({
        flags: Object.entries(flags).map(([key, value]) => ({
          key: key,
          reason: 'DEFAULT',
          value: value,
          variant: 'off',
        })),
      }),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
  http.post<ruleOfListParams, AppendRuleOfListRequest>(
    '*/user_flex_feature.v1.UserFlexFeatureService/AppendRuleOfList',
    async ({ request }) => {
      const text = await request.arrayBuffer();
      const textDecoder = new TextDecoder();
      const decode = textDecoder.decode(text);
      const body = AppendRuleOfListRequest.fromJsonString(decode);
      flags[body.key] = true;
      updateFeatureFlags(flags);
      return new HttpResponse(
        new AppendRuleOfListResponse({ result: true }).toJsonString(),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
  http.post<ruleOfListParams, RemoveRuleOfListRequest>(
    '*/user_flex_feature.v1.UserFlexFeatureService/RemoveRuleOfList',
    async ({ request }) => {
      const text = await request.arrayBuffer();
      const textDecoder = new TextDecoder();
      const decode = textDecoder.decode(text);
      const body = RemoveRuleOfListRequest.fromJsonString(decode);
      delete flags[body.key];
      updateFeatureFlags(flags);
      return new HttpResponse(
        new RemoveRuleOfListResponse({ result: true }).toJsonString(),
        {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
  ),
];
