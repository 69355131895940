export const removeHiddenElement = (ele: HTMLElement | undefined) => {
  if (!ele) return;
  if (ele.hasAttribute('hidden')) {
    ele.removeAttribute('hidden');
  }
  ele.classList.remove('hidden');
  if (ele.style.display === 'none') {
    ele.style.display = '';
  }
};
