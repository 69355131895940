import { TFlagStatus } from '@/components/OpenFeature/Dialog/type';
import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import { FC, memo, useMemo } from 'react';

export type Props = {
  status: TFlagStatus;
  onCancelClick: VoidFunction;
  onPrimaryClick: VoidFunction;
  isLoading?: boolean;
};

export const Footer: FC<Props> = memo(
  ({ status, onCancelClick, onPrimaryClick, isLoading }) => {
    const { t } = useTranslation();
    const message = useMemo(
      () =>
        status === 'old'
          ? t('old_to_new_flag_button')
          : t('new_to_old_flag_button'),
      [status, t]
    );
    return (
      <ButtonGroup direction='horizontal'>
        <ButtonV2
          size='sm'
          isSecondary
          onClick={onCancelClick}
          isDisabled={isLoading}
        >
          {t('cancel')}
        </ButtonV2>
        <ButtonV2
          size='sm'
          color='primary'
          onClick={onPrimaryClick}
          isLoading={isLoading}
        >
          {message}
        </ButtonV2>
      </ButtonGroup>
    );
  }
);
Footer.displayName = 'FeatureFlagConditionModalFooter';
