import { faker } from '@faker-js/faker/locale/ja';
import type { GetTpmInvoicesResponse } from 'ap-openapi';
import { toISODateString } from 'date-util';
import dayjs from 'dayjs';
import { HttpResponse, delay, http } from 'msw';

const getTpmInvoice = (number: string): GetTpmInvoicesResponse => {
  return {
    address:
      '東京都港区芝浦３丁目１番２１号ｍｓｂＴａｍａｃｈｉ田町ステーションタワーＳ２１階',
    kind: '法人',
    name: '株式会社マネーフォワード',
    number: `T${number}`,
    qualified_period: [
      {
        label: '登録',
        period: `${toISODateString(dayjs(faker.date.anytime()))}~`,
      },
    ],
    trade_name: '',
  };
};

export const handlers = [
  http.get('*/api/js/tpm_invoices', async (req) => {
    await delay(1000);
    const isSuccess = Math.floor(Math.random() * 10) % 2 === 0;
    const url = new URL(req.request.url, location.href);
    const number = url.searchParams.get('number') ?? '1234567891234';
    if (isSuccess) {
      return new HttpResponse(JSON.stringify(getTpmInvoice(number)), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      const body = {
        messages: ['入力された適格請求書発行事業者登録番号は存在しません'],
      };
      return new HttpResponse(JSON.stringify(body), {
        status: 400,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  }),
];
