import { SupplierUploadSetting } from '@/context/services/ap_payee/type';
import { ExpiredURL } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount/InvitationUrl/ExpiredUrl';
import { NotPublishedUrl } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount/InvitationUrl/NotPublishedUrl';
import { PublishedUrl } from '@/features/ap_payees/components/PayeeDetailModal/components/ModalContainer/ReceiveInvoice/components/WebReceive/components/UnregisteredAccount/InvitationUrl/PublishedUrl';
import { FC, memo, useMemo } from 'react';

export type Props = {
  supplierUploadSetting: SupplierUploadSetting;
};

export const InvitationUrl: FC<Props> = memo(({ supplierUploadSetting }) => {
  const invitationUrl = useMemo(() => {
    if (!supplierUploadSetting.isPublishedInvitationUrl) {
      return <NotPublishedUrl />;
    } else if (supplierUploadSetting.isExpiredInvitationUrl) {
      return <ExpiredURL />;
    } else {
      return (
        <PublishedUrl invitationUrl={supplierUploadSetting.invitationUrl} />
      );
    }
  }, [supplierUploadSetting]);

  return <>{invitationUrl}</>;
});
InvitationUrl.displayName = 'InvitationUrl';
