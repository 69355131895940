import { useModelsToOptions } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import {
  ApCounterpartyItem,
  useGetApCounterpartiesSuspenseInfinite,
} from 'ap-openapi';
import {
  KeyboardEvent,
  memo,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  query?: string;
  per?: number;
  statusValues?: (string | number)[];
  defaultOptions?: Options[];
  showLabel?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _Counterparties = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  statusValues,
  defaultOptions: _defaultOptions,
  showLabel,
  per = 25,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const label = useMemo(
    () =>
      showLabel
        ? {
            text: t('label_ap_counterparty'),
          }
        : undefined,
    [showLabel, t]
  );
  const selectRules = useMemo(
    () => ({
      required: t('required'),
    }),
    [t]
  );
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data, error, fetchNextPage, hasNextPage, isLoading } =
    useGetApCounterpartiesSuspenseInfinite(
      {
        query,
        per: per.toString(),
      },
      {
        query: {
          getNextPageParam: (page) => {
            const url = new URL(page.data.next ?? '/', location.href);
            if (!url.searchParams.has('page')) return undefined;
            return url.searchParams.get('page');
          },
        },
      }
    );
  if (error) throw error;
  const modelsToOptions = useModelsToOptions<ApCounterpartyItem>(
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      caption: 'code',
    }
  );

  const [options, setOption] = useState<SelectProps['options']>(
    modelsToOptions(
      data?.pages.flatMap((page) => page.data.ap_counterparties ?? []) ?? []
    )
  );
  useEffect(() => {
    if (!data) return;
    setOption(
      modelsToOptions(
        data.pages.flatMap((page) => page.data.ap_counterparties ?? []) ?? []
      )
    );
  }, [data, modelsToOptions]);

  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        setOption(
          modelsToOptions(
            data?.pages.flatMap((page) => page.data.ap_counterparties ?? []) ??
              []
          )
        );
      }
      callback();
    },
    [fetchNextPage, hasNextPage, modelsToOptions]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  const selectProps: SelectProps = useMemo(() => {
    const common = {
      ..._selectProps,
      options: options,
      mode: 'single',
      comboBox: true,
      statusTagValues: statusValues,
      onSearchDebounceWait: 600,
      fetchMore: fetchMore,
      onSearch: onSearch,
      loading: isLoading,
      onKeyPress: handleKeyPress,
      selectAll: undefined,
      selectAllLabel: undefined,
    };
    if (_selectProps?.mode === 'multiple') {
      return {
        ...common,
        mode: 'multiple',
      };
    } else {
      return {
        ...common,
        mode: 'single',
        selectAll: undefined,
        selectAllLabel: undefined,
      };
    }
  }, [_selectProps, fetchMore, isLoading, onSearch, options, statusValues]);

  return (
    <FormSelect<TFieldValues>
      selectProps={selectProps}
      label={label}
      {...rest}
      rules={selectRules}
    />
  );
};
_Counterparties.displayName = '_Counterparties';
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Counterparties = memo((props) => {
  return <_Counterparties {...props} />;
}) as typeof _Counterparties;
Counterparties.displayName = 'Counterparties';
