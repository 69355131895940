import { CounterpartyForm } from '@/features/ap_counterparties/components/AddNewCounterpartyDialog';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  Label,
  Left,
  Right,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';
import { Control } from 'react-hook-form';

export type TCorporateSiteUrlProps = {
  control: Control<CounterpartyForm>;
};

export const CorporateSiteUrl: FC<TCorporateSiteUrlProps> = memo(
  ({ control }) => {
    const { t } = useTranslation();
    return (
      <>
        <>
          <Left>
            <Label
              text={t('counterparty_corporate_site_url')}
              htmlFor='corporate_site_url'
            />
          </Left>
          <Right>
            <FormInput<CounterpartyForm>
              control={control}
              name='corporate_site_url'
              inputId='corporate_site_url'
            />
          </Right>
        </>
      </>
    );
  }
);
CorporateSiteUrl.displayName = 'CorporateSiteUrl';
