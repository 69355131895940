import { getOfficeMembers } from '@/libs/mocks/handlers/ap-payee';
import { faker } from '@faker-js/faker/locale/ja';
import { ApReportStatus } from 'ap-openapi';
import { HttpResponse, delay, http } from 'msw';

faker.seed(1);

const getCreateInvoiceReportMock = () => ({
  ap_report_type_id: faker.string.uuid(),
  id: faker.string.uuid(),
  link: '/received_invoices',
  office_member: getOfficeMembers(0),
  searchable_number: faker.string.uuid(),
  status: faker.helpers.arrayElement(Object.values(ApReportStatus)),
});

export const handlers = [
  http.post(
    '*/api/js/received_invoice/v1/received_invoices/:id/invoice_report',
    async () => {
      await delay(1000);
      const isSuccess = Math.random() % 2 === 0;
      if (isSuccess) {
        return new HttpResponse(JSON.stringify(getCreateInvoiceReportMock()), {
          status: 200,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        const body = {
          messages: ['支払依頼を作成出来ません'],
        };
        return new HttpResponse(JSON.stringify(body), {
          status: 403,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
    }
  ),
];
